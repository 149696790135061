
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import Row from "react-bootstrap/Row";
import { Link, useLocation } from 'react-router-dom';
import Col from "react-bootstrap/Col";
import { Container, Button } from "react-bootstrap";
import pc1 from "./../images/pc1.png";
import pc2 from "./../images/pc2.png";
import pc3 from "./../images/pc3.png";
import pc4 from "./../images/pc4.png";
import pc5 from "./../images/pc5.png";
import closed from "./../images/closed.png";
import pc6 from "./../images/pc6.png";
import emptycart from "./../images/delete.png";
import headingbg from "./../images/heading-bg.png";
import { base_url, image_url } from '../config';
import { SettingData } from '../provider/StateProvider';
import Card from 'react-bootstrap/Card';
import Footer from '../includes/Footer';
import FixedHeader from "../includes/FixedHeader";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import News from "./News";

const CartPage = ({ featuredmenu, cartItems, setCartItems }) => {
  //   const [cartItems, setCartItems] = useState([]);
  const [restaurant, setRestaurant] = useState({});
  const [restaurantStatus, setRestaurantStatus] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const storedCartItems = localStorage.getItem('cart');
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
  }, []);

  useEffect(() => {
    // Retrieve restaurant data from localStorage
    const storedRestaurant = JSON.parse(localStorage.getItem('restaurant'));

    // Set the restaurant data in the component state
    if (storedRestaurant) {
      setRestaurant(storedRestaurant);
      fetchRestaurantStatus(storedRestaurant);
    
    }else{
      navigate('/order-now');
    }
 
  
  }, []);

  async function fetchRestaurantStatus(storedRestaurant) {
    try {
      const response = await axios.get(`${base_url}api/get_restro_status/${storedRestaurant.restaurantid}`);
      // Response data mil gaya
      if(response.data.restroStatus==1){
        setRestaurantStatus(true)
      }else{
        setRestaurantStatus(false)
      }
      console.log('Restaurant status:', response.data.restroStatus);
    } catch (error) {
      // Error handling
      console.error('Error fetching restaurant status:', error);
    }
  }
  
  // Function ko call karein
  

  // const handleIncrement = (itemId) => {
  //   const updatedCartItems = cartItems.map(item => {
  //     if (item.id === itemId) {
  //       return { ...item, quantity: item.quantity + 1 };
  //     }
  //     return item;
  //   });
  //   setCartItems(updatedCartItems);
  //   localStorage.setItem('cart', JSON.stringify(updatedCartItems));
  // };

  // const handleDecrement = (itemId) => {
  //   const updatedCartItems = cartItems.map(item => {
  //     if (item.id === itemId && item.quantity > 1) {
  //       return { ...item, quantity: item.quantity - 1 };
  //     }
  //     return item;
  //   });
  //   setCartItems(updatedCartItems);
  //   localStorage.setItem('cart', JSON.stringify(updatedCartItems));
  // };

  // const handleRemove = (itemId) => {
  //   const updatedCartItems = cartItems.filter(item => item.id !== itemId);
  //   setCartItems(updatedCartItems);
  //   localStorage.setItem('cart', JSON.stringify(updatedCartItems));
  // };
  // const handleIncrement = (productId, variationId) => {
  //   const updatedCartItems = cartItems.map(item => {
  //     if (item.variation_id) {
  //       if (item.variation_id === variationId ) {
  //         return { ...item, quantity: item.quantity + 1 };
  //       }
  //     } else {
  //       if (item.id === productId) {
  //         return { ...item, quantity: item.quantity + 1 };
  //       }
  //     }
  //     return item;
  //   });
  //   setCartItems(updatedCartItems);
  //   localStorage.setItem('cart', JSON.stringify(updatedCartItems));
  // };
  
  // const handleDecrement = (productId, variationId) => {
  //   const updatedCartItems = cartItems.map(item => {
  //     if (item.variation_id) {
  //       if (item.variation_id === variationId) {
  //         return { ...item, quantity: Math.max(1, item.quantity - 1) };
  //       }
  //     } else {
  //       if (item.id === productId ) {
  //         return { ...item, quantity: Math.max(1, item.quantity - 1) };
  //       }
  //     }
  //     return item;
  //   });
  //   setCartItems(updatedCartItems);
  //   localStorage.setItem('cart', JSON.stringify(updatedCartItems));
  // };
  
  // const handleRemove = (productId, variationId) => {
  //   const updatedCartItems = cartItems.filter(item => {
  //     if (item.variation_id) {
  //       return !(item.variation_id === variationId );
  //     } else {
  //       return !(item.id === productId);
  //     }
  //   });
  //   setCartItems(updatedCartItems);
  //   localStorage.setItem('cart', JSON.stringify(updatedCartItems));
  // };






  const handleIncrement = (productId, variationId, addons) => {
    const updatedCartItems = cartItems.map(item => {
      if (item.variation_id) {
        if (item.variation_id === variationId && JSON.stringify(item.addons) === JSON.stringify(addons)) {
          return { ...item, quantity: item.quantity + 1 };
        }
      } else {
        if (item.id === productId && JSON.stringify(item.addons) === JSON.stringify(addons)) {
          return { ...item, quantity: item.quantity + 1 };
        }
      }
      return item;
    });
    setCartItems(updatedCartItems);
    localStorage.setItem('cart', JSON.stringify(updatedCartItems));
  };
  
  const handleDecrement = (productId, variationId, addons) => {
    const updatedCartItems = cartItems.map(item => {
      if (item.variation_id) {
        if (item.variation_id === variationId && JSON.stringify(item.addons) === JSON.stringify(addons)) {
          return { ...item, quantity: Math.max(1, item.quantity - 1) };
        }
      } else {
        if (item.id === productId && JSON.stringify(item.addons) === JSON.stringify(addons)) {
          return { ...item, quantity: Math.max(1, item.quantity - 1) };
        }
      }
      return item;
    });
    setCartItems(updatedCartItems);
    localStorage.setItem('cart', JSON.stringify(updatedCartItems));
  };
  
  // const handleRemove = (productId, variationId, addons) => {
  //   const updatedCartItems = cartItems.filter(item => {
  //     if (item.variation_id) {
  //       console.log('inner variation')
  //       return !(item.variation_id == variationId && JSON.stringify(item.addons) == JSON.stringify(addons));
  //     } else {
  //       console.log('inner item')
  //       return !(item.id == productId && JSON.stringify(item.addons) == JSON.stringify(addons));
  //     }
  //   });
  //   setCartItems(updatedCartItems);
  //   localStorage.setItem('cart', JSON.stringify(updatedCartItems));
  // };
 
  const handleRemove = (productId, variationId, addons) => {
    const updatedCartItems = cartItems.filter(item => {
      if (item.variation_id !== undefined) {
        // If the item has a variation ID
        console.log('inner variation')
        return !(item.id == productId && item.variation_id == variationId && JSON.stringify(item.addons) == JSON.stringify(addons));
      } else {
        // If the item does not have a variation ID
        console.log('inner item')
        return !(item.id == productId && JSON.stringify(item.addons) == JSON.stringify(addons));
      }
    });
    setCartItems(updatedCartItems);
    console.log(updatedCartItems,'updated cart')
    localStorage.setItem('cart', JSON.stringify(updatedCartItems));
  };






  return (<>




    <FixedHeader featuredmenu={featuredmenu} />
    <Row className=" mx-0">
      <Col sm={12} lg={10} className="offset-lg-2 px-0" style={{backgroundColor:'#f2f2f2'}}>
        <Container>
          {/* <h2 className="text-center my-5 fw-bold display-5">Cart</h2> */}

          {cartItems.length === 0 && restaurantStatus == true ? (
           <div class="jumbotron text-center">
            <img src={emptycart} alt="" className="w-sm-100"/>
           <h1 class="display-4">Your cart is empty</h1>
           <p class="lead">Explore our amazing food and add them to your cart!</p>
         </div>
          ) : restaurantStatus == false ? (
            <div className="jumbotron text-center mt-3">
              <img src={closed} alt="" className="w-sm-100"/>
              <h1 className="display-4">Sorry, we are closed at the moment. We will open at 11:00 AM.</h1>
             
            </div>
          ) : (<>

            {/* <h2 className="text-center my-3 fw-bold display-5">Cart</h2> */}
            <h2 class="display-5 fw-bold color-gray text-center margin-cart">Cart</h2>
            {cartItems.map(item => (



              <Card key={item.id} className="p-3 my-2">
                <Row className="g-0 m-0 p-0">
                  <Col xs={4} md={1} className="m-auto">

                    <Card.Img src={item.item_image_url} alt="Card image" />
                  </Col>

                  <Col xs={8} md={11} className="m-auto">
                    <Card.Body>
                      <div className="d-md-flex justify-content-between">
                        <div className="">
                          <Card.Title>{item.name} ,{item.variation_name}</Card.Title>
                          <p className=" m-0 p-0">  ₹ {item.price} x {item.quantity}  </p>
                          {item.addons.length != 0 ? (
                          <p>  Addons: {item.addons.map(addon => addon.addonitem_name).join(', ')} </p>
                          ):''}
                        </div>
                        <div className="">
                        <p className=" fw-bold fs-5">
                            ₹{item.price * item.quantity}
                          </p>
                          <div className="quantity">
                          <button className="quantity__minus" onClick={() => handleDecrement(item.id, item.variation_id, item.addons)}>-</button>
                          <span className="quantity__input">{item.quantity}</span>
                          <button variant="" className="quantity__plus" onClick={() => handleIncrement(item.id, item.variation_id, item.addons)}>+</button>
                          <Button variant="danger" className="ms-1" size="sm" onClick={() => handleRemove(item.id, item.variation_id, item.addons)}><i class="fa-solid fa-trash"></i></Button>
                          </div>
                         
                          
                          
                        </div>
                      </div>



                    </Card.Body>
                  </Col>
                </Row>
              </Card>

            ))}
            <p className="fw-bold h5">Total Price: ₹ {cartItems.reduce((acc, item) => acc + (item.price * item.quantity), 0)}</p>

            <Link to={'/checkout'} className="btn btn-danger mb-2">Process to Checkout</Link>
            {/* <Card className="my-3">
              <h2 className="text-center my-3 fw-bold display-5">Cart</h2>
              <Card.Body>
                {cartItems.map(item => (
                  <div key={item.id} className="mb-3">
                    
                    <p>{item.name} ,{item.variation_name}  ₹ {item.price}*{item.quantity} = ₹{item.price * item.quantity} </p>
                    <Button variant="outline-danger" className="mx-2" onClick={() => handleDecrement(item.id)}>-</Button>
                    <span>{item.quantity}</span>
                    <Button variant="outline-success" className="mx-2" onClick={() => handleIncrement(item.id)}>+</Button>
                    <Button variant="danger" onClick={() => handleRemove(item.id)}><i class="fa-solid fa-trash"></i></Button>
                  </div>
                ))}
                <p className="fw-bold">Total Price: ₹ {cartItems.reduce((acc, item) => acc + (item.price * item.quantity), 0)}</p>

                <Link to={'/checkout'} className="btn btn-danger">Process to Checkout</Link>
              </Card.Body>
            </Card> */}
          </>

          )}


        </Container>
        <div className="margin-bottom"></div>
        <Footer />
      </Col>

    </Row>

  </>);
}

export default CartPage;