

import React,{useEffect,useState,useContext} from "react";
import { useParams } from "react-router";
import Row from "react-bootstrap/Row";
import { Link,useLocation } from 'react-router-dom';
import Col from "react-bootstrap/Col";
import { Container,Button } from "react-bootstrap";
import pc1 from "./../images/pc1.png";
import pc2 from "./../images/pc2.png";
import pc3 from "./../images/pc3.png";
import pc4 from "./../images/pc4.png";
import pc5 from "./../images/pc5.png";
import pc6 from "./../images/pc6.png";
import headingbg from "./../images/heading-bg.png";
import {base_url,image_url} from '../config';
import { SettingData } from '../provider/StateProvider';
import Card from 'react-bootstrap/Card';
import Footer from '../includes/Footer';
import FixedHeader from "../includes/FixedHeader";
import { Modal } from "react-bootstrap";
import axios from 'axios';
import News from "./News";
import { useNavigate } from 'react-router-dom';
const PetpoojaCategoryProduct = ({featuredmenu, cartItems, setCartItems}) => {
    const [selectedItem, setSelectedItem] = useState('');
    const [product, setproduct] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [hasVariations, sethasVariations] = useState(false);
    const [hasAddons, sethasAddons] = useState(false);
    const [Addons, setAddons] = useState([]);
    const [attribute, setAttribute] = useState({});
    const [selectedVariation, setSelectedVariation] = useState('');
    const [addedToCart, setAddedToCart] = useState(false);
    const [quantity, setqunatity] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [selectedAddonsHook, setselectedAddons] = useState([]);
    const [showModalIncrement, setShowModalIncrement] = useState(false);
    const [handleincrementproduct, sethandleincrementproduct] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const handleClose = () => setShowModalIncrement(false);
    const handleShow = (data) =>{
      sethandleincrementproduct(data);
      setShowModalIncrement(true); 
    } 
    const { id } = useParams();
    useEffect(() => {
      // Fetch cart items from local storage on component mount
      const storedRestaurant = JSON.parse(localStorage.getItem('restaurant'));
  
      // Set the restaurant data in the component state
      if (!storedRestaurant) {
        localStorage.removeItem('cart');
        localStorage.removeItem('order_type');
        setCartItems([])
        navigate('/order-now');
      }
    }, []);
    // const { state } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
      // Fetch cart items from local storage on component mount
      const storedCart = localStorage.getItem('cart');
      if (storedCart) {
        setCartItems(JSON.parse(storedCart));
        const inCart = cartItems.some(item => item.id === product.id);
        setAddedToCart(inCart);
      }
    }, [product]);
    useEffect(() => {
       
     
        singlecat();
      
        


      }, [id]);

      const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
      };

      const singlecat = async () => {
        try {
          // setfeaturedmenu([...response.data.category]);
          const response = await axios.get(base_url + 'api/patpooja-category-products/'+id);
          setproduct([...response.data.products]);
          setSelectedItem(response.data.category_name);
         
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      const getColor = (attribute) => {
        switch (attribute) {
          case 'veg':
            return 'text-success'; // Green color for veg
          case 'non-veg':
            return 'text-danger'; // Red color for non-veg
          case 'egg':
            return 'orange'; // Orange color for egg
          default:
            return 'black'; // Default color
        }
      };

      // new
      const addToCart = () => {
        const selectedAddons = Addons.filter(addon => addon.isSelected);
        if (hasVariations) {
          if (selectedVariation) {
    
            const existingItemIndex = cartItems.findIndex(
              (item) => (
                item.variationid == selectedVariation &&
                JSON.stringify(item.addons) == JSON.stringify(selectedAddons)
    
              )
            );
            if (existingItemIndex !== -1) {
              const updatedCart = [...cartItems];
              updatedCart[existingItemIndex].quantity += 1;
              setCartItems(updatedCart);
            } else {
              const selectedVariationObj = selectedProduct.variation.find(
                (variation) => variation.variationid === selectedVariation
              );
    
              setCartItems([
                ...cartItems,
                {
                  id: selectedProduct.id,
                  item_image_url: selectedProduct.item_image_url,
                  name: selectedProduct.itemname,
                  itemid: selectedProduct.itemid,
                  variationid: selectedVariationObj.variationid,
                  variation_id: selectedVariationObj.id,
                  variation_name: selectedVariationObj.name,
                  price: parseInt(selectedVariationObj.price) + selectedAddons.reduce((acc, addon) => acc + parseInt(addon.addonitem_price), 0),
                  quantity: quantity,
                  addons: selectedAddons
                },
              ]);
            }
            setSelectedVariation("");
          } else {
            alert("Please select a variation");
          }
        } else {
          const existingItemIndex = cartItems.findIndex(
            (item) => (
              item.id === selectedProduct.id &&
              JSON.stringify(item.addons) === JSON.stringify(selectedAddons)
            )
          );
    
          if (existingItemIndex !== -1) {
            const updatedCartItems = [...cartItems];
            updatedCartItems[existingItemIndex].quantity += 1;
            setCartItems(updatedCartItems);
          } else {
    
            setCartItems([
              ...cartItems,
              {
                id: selectedProduct.id,
                item_image_url: selectedProduct.item_image_url,
                itemid: selectedProduct.itemid,
                name: selectedProduct.itemname,
                price: parseInt(selectedProduct.price) + selectedAddons.reduce((acc, addon) => acc + parseInt(addon.addonitem_price), 0),
                quantity: quantity,
                addons: selectedAddons
              },
            ]);
          }
        }
        setqunatity(1);
      };
      const handleCloseModal = () => {
        setShowModal(false);
      };
      const showmodel = () => {
        setShowModal(true);
      };
      useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cartItems));
        setShowModal(false);
        const inCart = cartItems.some(item => item.id === product.id);
        setAddedToCart(inCart);
      }, [cartItems]);
      
     
        // Fetch product details
       
        // Retrieve cart items from local storage on component mount
        const fetchProduct = async (itemid) => {
        sethasVariations(false)
        sethasAddons(false)
        setAddons([])
        setAttribute({})
        setSelectedVariation('')
        
        
         
    



          try {
            const response = await axios.get(base_url + 'api/petpoojasingleproduct/' + itemid);
            console.log(response)
            if (response.data.product == null) {
              navigate('/order-now');
            }
            setSelectedProduct(response.data.product);
            setAttribute(response.data.product.attribute)
            // console.log(response.data.product.attribute.attribute);
            if (response.data.product.variation.length > 0) {
              sethasVariations(true);
              setSelectedVariation(response.data.product.variation[0].variationid)
      
            }
            // if (response.data.product.itemallowaddon==1 && response.data.product.addon.length>0) {
      
            //   // console.log(response.data.product.addon[0].addon_group_id)
            //   const responseaddon = await axios.get(
            //     base_url + "api/getAddonByGroup_id/" + response.data.product.addon[0].addon_group_id
            //   );
            //   setAddons(responseaddon.data.addons);
            //   sethasAddons(true);
      
            // }
            if (response.data.product.itemallowaddon == 1 && response.data.product.itemallowvariation == 0) {
              const addonGroupIds = response.data.product.addon.map(group => group.addon_group_id);
              console.log(addonGroupIds);
              // console.log(response.data.product)
              const responseaddon = await axios.post(base_url + "api/getAddonByGroup_id", {
                addonGroupIds,
              }
              );
              setAddons(responseaddon.data.addons);
              sethasAddons(true);
      
            } else if (response.data.product.itemallowaddon == 1 && response.data.product.itemallowvariation == 1) {
              // if addon apply on item
              if (response.data.product.itemaddonbasedon == 0) {
      
                const addonGroupIds = response.data.product.variation[0].addon.map(group => group.addon_group_id);
                console.log(addonGroupIds);
                // console.log(response.data.product)
                const responseaddon = await axios.post(base_url + "api/getAddonByGroup_id", {
                  addonGroupIds,
                }
                );
                setAddons(responseaddon.data.addons);
                sethasAddons(true);
      
              }
      
              console.log(response.data.product)
            }
      
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    const HandleAddToCart=(item)=>{
      fetchProduct(item.itemid);
      // setTimeout(() => {
      //   console.log(selectedProduct)
      // }, 4000);
    }
    useEffect(() => {
      if (Object.keys(selectedProduct).length > 0) {
     if(selectedProduct.itemallowaddon==1 ||selectedProduct.itemallowvariation){
      showmodel()
     }else{
      addToCart()
     }
    }
    }, [selectedProduct]);

    const handleAddonSelection = (addonId) => {
      const updatedAddons = Addons.map(addon => {
        if (addon.addonitemid == addonId) {
          return { ...addon, isSelected: !addon.isSelected };
        }
        return addon;
      });
      setAddons(updatedAddons);
      setselectedAddons(prevProduct => ({ ...prevProduct, addons: updatedAddons }));
      setTimeout(() => {
        // console.log("selected",selectedAddons);
        console.log("addon hook selected", Addons);
      }, 4000)
  
    };
    const handleIncrement = (productId, variationId, addons) => {
      const updatedCartItems = cartItems.map(item => {
        // if (item.variation_id || addons.length>0) {
        //   HandleAddToCart(data)
        //   return;
        // }
        if (item.variation_id) {
          if (item.variation_id === variationId && JSON.stringify(item.addons) === JSON.stringify(addons)) {
            return { ...item, quantity: item.quantity + 1 };
          }
        } else {
          if (item.id === productId && JSON.stringify(item.addons) === JSON.stringify(addons)) {
            return { ...item, quantity: item.quantity + 1 };
          }
        }
        return item;
      });
      setCartItems(updatedCartItems);
      localStorage.setItem('cart', JSON.stringify(updatedCartItems));
    };
    
    // const handleDecrement = (productId, variationId, addons) => {
    //   const updatedCartItems = cartItems.map(item => {
    //     if (item.variation_id) {
    //       if (item.variation_id === variationId && JSON.stringify(item.addons) === JSON.stringify(addons)) {
    //         return { ...item, quantity: Math.max(1, item.quantity - 1) };
    //       }
    //     } else {
    //       if (item.id === productId && JSON.stringify(item.addons) === JSON.stringify(addons)) {
    //         return { ...item, quantity: Math.max(1, item.quantity - 1) };
    //       }
    //     }
    //     return item;
    //   });
    //   setCartItems(updatedCartItems);
    //   localStorage.setItem('cart', JSON.stringify(updatedCartItems));
    // };
    const handleDecrement = (productId, variationId, addons) => {
      const updatedCartItems = cartItems.map(item => {
        if (item.variation_id) {
          if (item.variation_id === variationId && JSON.stringify(item.addons) === JSON.stringify(addons)) {
            return { ...item, quantity: Math.max(0, item.quantity - 1) };
          }
        } else {
          if (item.id === productId && JSON.stringify(item.addons) === JSON.stringify(addons)) {
            return { ...item, quantity: Math.max(0, item.quantity - 1) };
          }
        }
        return item;
      }).filter(item => item.quantity > 0); // Remove items with quantity 0
    
      setCartItems(updatedCartItems);
      localStorage.setItem('cart', JSON.stringify(updatedCartItems));
    };
      // end new 
  return (<>
  <FixedHeader featuredmenu={featuredmenu} 
//   banner={product}
  />
   <Row className=" mx-0">
   <Col sm={12} lg={10} className="offset-lg-2 px-0">
   <Container>
    <h2 className="text-center my-5 fw-bold display-5">{selectedItem}</h2>
    <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              className="form-control mb-3"
              placeholder="Search food..."
            />

    <Row>

    {
                    product
                    .filter(item => item.itemname.toLowerCase().includes(searchQuery.toLowerCase()))
                    .map((data,index)=>{
                        return (<>
                          
                      <Col xs={6} sm={6} lg={4} className="mb-3">
                        

                          <Card className='border-0 hover-card'>
                          <Link className="nav-link" to={'/item/'+data.itemid}>
                          <Card.Img variant="top" src={data.item_image_url} className='w-100' />
                          </Link>
                          <Card.Body className="p-1">
                            <Card.Title className='text-center fw-bold my-3'>
                                <h6>{data.itemname}</h6>
                            </Card.Title>
                            <Card.Title className='text-center fw-bold'>
                            <h6 className={"fw-bold " + getColor(data.attribute.attribute)} >{data.attribute.attribute}</h6>
                            </Card.Title>
                            {data.itemallowvariation==0 ?
                         <h6 className="text-center">Price: ₹ {data.price}</h6>
                        : <h6 className="text-center">Price: ₹ {data.variation[0].price}</h6>}
                            {/* <button  onClick={() => HandleAddToCart(data)} >Add to cart</button> */}
                            {cartItems.find(item => item.itemid === data.itemid) ? (
                              // If the product is in the cart, show increment and decrement buttons
                              <div className="text-center quantity">
                                <button className="quantity__minus" onClick={() =>
                                  {
                                    if (data.itemallowvariation==1 || data.itemallowaddon==1) {
                                      // HandleAddToCart(data); 
                                      handleShow(data)
                                      
                                    } else {
                                      handleDecrement(cartItems.find(item => item.itemid === data.itemid).id, cartItems.find(item => item.itemid === data.itemid).variation_id, cartItems.find(item => item.itemid === data.itemid).addons)
                                    }
                                  }
                                  
                                  }> - </button>
                                {/* <span> {cartItems.find(item => item.itemid === data.itemid).quantity} </span>  */}
                                <span className="quantity__input">{cartItems.filter(item => item.itemid === data.itemid).reduce((sum, item) => sum + item.quantity, 0)}</span>
                                {/* <button onClick={() => handleIncrement(cartItems.find(item => item.itemid === data.itemid).id, cartItems.find(item => item.itemid === data.itemid).variation_id, cartItems.find(item => item.itemid === data.itemid).addons)}> + </button> */}
                                <button className="quantity__plus" onClick={() => {
                           // Check if variations or addons are present
                                    if (data.itemallowvariation==1 || data.itemallowaddon==1) {
                                      // HandleAddToCart(data); 
                                      handleShow(data)
                                      
                                    } else {
                                      handleIncrement(cartItems.find(item => item.itemid === data.itemid).id, cartItems.find(item => item.itemid === data.itemid).variation_id, cartItems.find(item => item.itemid === data.itemid).addons);
                                    }
                                  }}> + </button>
                              </div>
                            ) : (
                              // If the product is not in the cart, show the "Add to Cart" button
                              <div className="text-center">
                               <Button variant="outline-danger" size="sm" onClick={() => HandleAddToCart(data)}> Add to Cart </Button>
                              </div>
                            )}
            
                          </Card.Body>
                        </Card>
                       
                        </Col>
                    
                          {/* //   <Col xs={6} sm={6} lg={4}>
                          //   <Link><img src={image_url+data.image} alt="" className="w-100" /></Link>
                          // </Col> */}
                          </> )
                    })
                    
                }
    </Row>
   
  </Container>
  
  <Footer/>
   </Col>

   </Row>
   <Modal show={showModal} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>{selectedProduct.itemname}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {hasVariations ? (
          <div >
            <h5 className="">Select Variations</h5>
            <div>
              {selectedProduct.variation.map(variation => (<>
                <div className="d-flex justify-content-between my-1">
                  <div>
                    <label key={variation.id} className="fw-medium">
                      <input
                        type="radio"
                        className="form-check-input mx-2"
                        name="variation"
                        value={variation.variationid}
                        checked={selectedVariation === variation.variationid}
                        onChange={() => setSelectedVariation(variation.variationid)}
                      />
                      {variation.name}
                    </label>
                  </div>
                  <div>
                    <span className="text-center text-danger">₹ {variation.price}</span>
                  </div>
                </div>

              </>))}
            </div>
          </div>
        ) : (
          <div>

          </div>
        )}
        {hasAddons && Addons.length > 0 ? (
          <div>
            <hr></hr>
            <h5 className="">Addons</h5>

            <div>
              {Addons.map((addon) => (
                <>
                  <div className="d-flex justify-content-between my-1">
                    <div>
                      <label key={addon.id} className="fw-medium">
                        <input
                          type="checkbox"
                          className="form-check-input mx-2"
                          name="variation"
                          value={addon.addonitemid}
                          checked={addon.isSelected}
                          onChange={() => handleAddonSelection(addon.addonitemid)}
                        /> 
                        {addon.addonitem_name}
                      </label>
                    </div>
                    <div>
                      <span className="text-center text-danger">
                        ₹ {addon.addonitem_price}
                      </span>
                    </div>
                  </div>
                </>
              ))}
            </div>

          </div>
        ) : (
          <div></div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button> */}
               <div className="quantity">
                                <button className="quantity__minus center-text" onClick={() => {
                                  if (quantity > 1) {
                                    setqunatity(prevCount => prevCount - 1)
                                }
                                  
                                }}> - </button>
                                {/* <span> {cartItems.find(item => item.itemid === data.itemid).quantity} </span>  */}
                                <span className="quantity__input">{quantity}</span>
                                {/* <button onClick={() => handleIncrement(cartItems.find(item => item.itemid === data.itemid).id, cartItems.find(item => item.itemid === data.itemid).variation_id, cartItems.find(item => item.itemid === data.itemid).addons)}> + </button> */}
                                <button className="quantity__plus center-text" onClick={() => {
                              setqunatity(prevCount => prevCount + 1)
                                  
                                  }}> + </button>
                              </div>
        <Button variant="outline-danger" size="sm" className="" onClick={addToCart}>Add to Cart</Button>
      </Modal.Footer>
    </Modal>

    {/* increment decrement model */}
    <Modal show={showModalIncrement} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{handleincrementproduct.itemname}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {cartItems
  .filter(item => item.id === handleincrementproduct.id)
  .map(item => (
    <Card key={item.id} className="p-3 my-2">
      <Row className="g-0 m-0 p-0">
        <Col xs={4} md={1} className="m-auto">
          <Card.Img src={item.item_image_url} alt="Card image" />
        </Col>
        <Col xs={8} md={11} className="m-auto">
          <Card.Body>
            <div className="d-md-flex justify-content-between">
              <div className="">
                <Card.Title>{item.name} ,{item.variation_name}</Card.Title>
                <p> ₹ {item.price} x {item.quantity} </p>
                {item.addons.length !== 0 ? (
                  <p> Addons: {item.addons.map(addon => addon.addonitem_name).join(', ')} </p>
                ) : ''}
              </div>
              <div className="">
                <p className=" fw-bold fs-5">
                  ₹{item.price * item.quantity}
                </p>
                <div className="quantity">
                <button variant="" className="quantity__minus center-text" onClick={() => handleDecrement(item.id, item.variation_id, item.addons)}>-</button>
                <span className="quantity__input">{item.quantity}</span>
                <button variant="" className="quantity__plus center-text" onClick={() => handleIncrement(item.id, item.variation_id, item.addons)}>+</button>
                </div>
              
               
              </div>
            </div>
          
          </Card.Body>
        </Col>
      </Row>
    </Card>
  ))}
   <div className="m-auto">
   <button className="nav-link fw-bold text-danger text-center m-auto" onClick={()=>{
              HandleAddToCart(handleincrementproduct)
              setShowModalIncrement(false)
            }}>+ Add new customisation
            </button>

   </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
  </> 
  )
}

export default PetpoojaCategoryProduct
