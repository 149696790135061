import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from "./../images/logo.png";
import ilssidelogo from "./../images/ilssidelogo.png";
import bannerimg from "./../images/banner.png";
import coffee from "./../images/coffee.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeaderMenu from "../component/HeaderMenu";
import { base_url, image_url } from '../config';
import { Link } from 'react-router-dom';
import { SettingData } from '../provider/StateProvider';
import axios from 'axios';
import facebook from "./../images/facebook.png";
import instagram from "./../images/instagram.png";
import Carousel from 'react-bootstrap/Carousel';

function FixedHeader({featuredmenu,banner}) {
  const { setting } = useContext(SettingData);
  const isArray = Array.isArray(banner);


  return (
    <>
    

      {/* marge hero and side menu for desktop */}
      <Row className="mx-0">
        <Col sm={12} lg={2} className="bg-danger pb-4 d-none d-lg-block position-fixed h-100">
          
            <HeaderMenu/>
            
          
        </Col>


        <Col sm={12} lg={10} className="px-0 position-relative offset-lg-2">
          {/* <Navbar bg="dark" data-bs-theme="dark" expand="lg" className='py-0 navbar-expand-lg-fixed'>
            <Container fluid className="py-mobile-2 bg-black">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
            

<Navbar.Brand href="/" className="d-lg-none m-0 position-relative">
              <Link to={'/'}> 
              <img className="position-absolute translate-39"
                  src={image_url + setting.menu_logo}
                  alt="Logo"
                  style={{ width: "70px"}}
                />
                </Link>
              </Navbar.Brand>
              <Navbar.Brand className="d-lg-none m-0">
              {setting.facebook_link!=null? 
            <a className="me-2" href={setting.facebook_link} target='blank'>
                  
                    <img className="fb" style={{width:'1.5rem'}} src={facebook}  alt="" />
                    
                    </a>:''}

          
                   {setting.instagram_link!=null? 
            <a className="" href={setting.instagram_link} target='blank'>
                  
                    <img className="fb" style={{width:'1.5rem'}} src={instagram}  alt="" />
                    </a>:''}
              </Navbar.Brand>
            </Container>
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-left"
            >
              <Nav className="me-auto d-lg-none">
              
                <HeaderMenu />
                
              </Nav>
             
             

        


             <Nav className="me-auto">      
               {
                 featuredmenu.map((data,index)=>{
                    return (
                    
                        // <Link className="nav-link py-2 px-2"  to={{ pathname: '/food/'+data.slug, state: { name: data.name }}}> <img src={image_url + data.icon} style={{width:"30px",height:"30px"}} alt="" />  {data.name}</Link>
                        <Link className="nav-link py-2 px-2"  to={{ pathname: '/order-now/'}}> <img src={image_url + data.icon} style={{width:"30px",height:"30px"}} alt="" />  {data.name}</Link>
                       
                 )})
               }
              
              </Nav>

               
             
           
            </Navbar.Collapse>
          
          </Navbar> */}
          <Navbar bg="dark" data-bs-theme="dark" expand="lg" className='py-0'>
            <Container fluid className="py-mobile-2 bg-black">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              {/* <Navbar.Brand href="/" className="d-lg-none m-0">
              <Link to={'/'}>   <img
                  src={image_url + setting.main_logo}
                  alt="Logo"
                  style={{ width: "100px" }}
                /></Link>
              </Navbar.Brand>
              <Navbar.Brand href="/" className="d-lg-none m-0">
              <Link to={'/'}> <img
                  src={image_url + setting.menu_logo}
                  alt="Logo"
                  style={{ width: "50px" }}
                />
                </Link>
              </Navbar.Brand> */}
             <Navbar.Brand href="/" className="d-lg-none m-0 position-relative">
              <Link to={'/'}> 
              <img className="position-absolute translate-39"
                  src={image_url + setting.menu_logo}
                  alt="Logo"
                  style={{ width: "70px"}}
                />
                </Link>
              </Navbar.Brand>
              <Navbar.Brand className="d-lg-none m-0">
              {setting.facebook_link!=null? 
            <a className="me-2" href={setting.facebook_link} target='blank'>
                    {/* <i class="fa-brands fa-facebook fb text-danger bg-white"></i> */}
                    <img className="fb" style={{width:'1.5rem'}} src={facebook}  alt="" />
                    
                    </a>:''}

          
                   {setting.instagram_link!=null? 
            <a className="" href={setting.instagram_link} target='blank'>
                    {/* <i class="fa-brands fa-square-instagram text-danger"></i> */}
                    <img className="fb" style={{width:'1.5rem'}} src={instagram}  alt="" />
                    </a>:''}
              </Navbar.Brand>
            </Container>
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-left"
            >
              <Nav className="me-auto d-lg-none">
                {/* <Nav.Link href="#home">Home</Nav.Link> */}
                <HeaderMenu />

              </Nav>
              {/* <Nav className='d-none d-lg-block'>
            <img src={logo} alt="Logo" style={{ marginRight: '15px', width:'400px' }} />
          </Nav> */}
            </Navbar.Collapse>
            <Navbar.Brand href="#home" className="d-none d-lg-block">
            <Link to={'/'}>
              <img src={image_url + setting.main_logo} alt="Logo" style={{ width: "450px" }} />
              </Link>
            </Navbar.Brand>
            {/* </Container> */}
          </Navbar>

            {/* <div className="food-menu">
                <table>
                    <tbody>
<tr>


                   
               {
                 featuredmenu.map((data,index)=>{
                    return (
                        <td><Link className="nav-link py-2 px-3 btn-animate" style={{whiteSpace:'nowrap'}} to={{ pathname: '/food/'+data.slug, state: { name: data.name }}}> <span dangerouslySetInnerHTML={{ __html: data.icon }}></span> {data.name}</Link></td>
                
                 )})
               }
               </tr>
                 </tbody>
                </table>
              </div> */}
        
         

          {/* <div className="top-social d-none d-lg-block">
          {setting.facebook_link!=null? 
            <a className="px-2" href={setting.facebook_link} target='blank'>
                    <i class="fa-brands fa-facebook text-danger"></i>
                    </a>:''}
                   
          
                   {setting.instagram_link!=null? 
            <a className="px-2" href={setting.instagram_link} target='blank'>
                    <i class="fa-brands fa-square-instagram text-danger"></i>
                    </a>:''}
                   
           
          </div> */}
          {isArray?
          <Carousel indicators={false}>
            {isArray?banner.map((data, index) => {
              if (data.banner !== null) {
              return (

                <Carousel.Item interval={1000} >
                  <img src={image_url + data.banner} alt="" className="w-100 banner" />


                </Carousel.Item>

              )
              }
            })
          :''
          }

          </Carousel>
:''}
        </Col>
      </Row>
    </>
  );
}

export default FixedHeader;
