
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import Row from "react-bootstrap/Row";
import { Link, useLocation } from 'react-router-dom';
import Col from "react-bootstrap/Col";
import { Container, Button } from "react-bootstrap";
import pc1 from "./../images/pc1.png";
import pc2 from "./../images/pc2.png";
import pc3 from "./../images/pc3.png";
import pc4 from "./../images/pc4.png";
import pc5 from "./../images/pc5.png";
import pc6 from "./../images/pc6.png";
// import send from "./../images/send.png";
import directions from "./../images/directions.png";
import headingbg from "./../images/heading-bg.png";
import { base_url, image_url } from '../config';
import { SettingData } from '../provider/StateProvider';
import Card from 'react-bootstrap/Card';
import Footer from '../includes/Footer';
import FixedHeader from "../includes/FixedHeader";

import axios from 'axios';
import News from "./News";

const SingleRestaurent = ({ featuredmenu }) => {

  const [restro, setrestro] = useState({});
  const [banner, setbanner] = useState([]);

  const { slug } = useParams();
  // const { state } = useLocation();


  useEffect(() => {


    restrofatch();




  }, []);



  const restrofatch = async () => {
    try {


      const response = await axios.get(base_url + 'api/restro/' + slug);


      setrestro(response.data.restro);
      //  console.log(response.data.restro.banner)
      const arrayOfObjects = response.data.restro.banner.map((value, index) => ({
        banner: value
      }));
      setbanner(arrayOfObjects)
      console.log(banner);
    } catch (error) {
      console.error('Error fetching data:', error);

    }
  };


  return (<>
    <FixedHeader featuredmenu={featuredmenu} banner={banner} />
    <Row className=" mx-0">
      <Col sm={12} lg={10} className="offset-lg-2 px-0">
        <Container>
         
          <Card className='border-0'>
            {/* <Card.Img variant="top" src={image_url+data.image} className='w-100' /> */}
            <Card.Body className="p-1">

              <div className="w-c mb-4">
                <h2 className='text-center display-5 mt-4 fw-bold m-0'>{restro.name}</h2>
                {restro.is_comming_soon==0?
                <div class="eight">
                  <h1 className="text-danger fw-bold">Timings 11am-10pm</h1>
                </div>:''}
              </div>
             
              <Card.Title className='text-center fw-bold my-3'>
                <h6>{restro.address}</h6>
              </Card.Title>
              {restro.is_comming_soon==0?
              <Card.Title className='text-center fw-bold'>
                <h6><a className="nav-link text-danger" target="_blank" href={restro.map_link}> <img src={directions} style={{ width: '20px' }} /> Get Directions</a></h6>
              </Card.Title>:
              <Card.Title className='fw-bold text-center'>
                            <h6>Celebrating Our Restaurant Soon!</h6>
                            </Card.Title>}


            </Card.Body>
          </Card>


        </Container>

        <Footer />
      </Col>

    </Row>

  </>
  )
}

export default SingleRestaurent
