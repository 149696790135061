import React,{useEffect,useState,useContext} from "react";
import Row from "react-bootstrap/Row";
import { Link } from 'react-router-dom';
import Col from "react-bootstrap/Col";
import { Container,Button } from "react-bootstrap";
import pc1 from "./../images/pc1.png";
import pc2 from "./../images/pc2.png";
import pc3 from "./../images/pc3.png";
import pc4 from "./../images/pc4.png";
import pc5 from "./../images/pc5.png";
import pc6 from "./../images/pc6.png";
import explore from "./../images/explore.png";
import { useLocation } from 'react-router-dom';
import { SettingData } from '../provider/StateProvider';
import Card from 'react-bootstrap/Card';
import News from "./News";
import {base_url,image_url} from '../config';
import axios from 'axios';
import { useParams } from 'react-router-dom';
const Menu = ({featuredmenu}) => {
  
  const [petpoojacat, setpetpoojacat] = useState([]);
  const location = useLocation();
  const [restaurant, setRestaurant] = useState({});
  const { restaurantid } = useParams();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const { handleShowMainModel } = useContext(SettingData);

  useEffect(() => {


    
    setTimeout(() => {
      // console.log(petpoojacat,'ddddddddddd')
    }, 3000);
  }, []);

  // useEffect(() => {
  //   fetchData();
  // }, [restaurant]);
 
  const getSingleRestroPetPoojaProduct = async (restroid) => {
    try {

      const response = await axios.get(base_url + `api/getrestroPetPoojaCategoty/${restroid}`);

      setpetpoojacat([...response.data.category]);
     
    } catch (error) {
      console.error('Error fetching data:', error);

    }
  };


  useEffect(() => {
    // Retrieve restaurant data from localStorage
    const storedRestaurant = JSON.parse(localStorage.getItem('restaurant'));

    // Set the restaurant data in the component state
    if (storedRestaurant) {
      setRestaurant(storedRestaurant);
      getSingleRestroPetPoojaProduct(storedRestaurant.restaurantid)
    
    }else{

    }

  
  }, []);


  return (
    <>
   
      {/* Menu Heading */}
      <div className="menu-main-div">
        <Container>
              <div className="text-center my-4">
                <img src={explore} alt="" className="explore"/>
                {/* <h2 className="curved-text">E X P L O R E   O U R</h2> */}

        

                <h2 className=" menu-heading">MENU</h2>
              </div>
              { petpoojacat.length>0 && (
              <div className="px-2">
                  <div className="card mb-3" >
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <p className="p-0 m-0">

                          <span className="h6 fw-bold">Restaurant Name : </span>
                          {/* {address} */}
                          {restaurant.restaurantname}
                          </p>
                        <button className="nav-link text-danger fw-bold" onClick={handleShowMainModel}>Change</button>
                      </div>


                    </div>
                  </div>
                  </div>
              )}
              {/* <Row className="menu-order-col"> */}
              <Row className="">
                { petpoojacat.length==0 && (
                    featuredmenu.map((data,index)=>{
                      // petpoojacat.map((data,index)=>{
                        return (<>
                      <Col xs={6} sm={6} lg={4} className="g-3">
                        {/* <Link className="nav-link" to={{ pathname: '/food/'+data.slug, state: { name: data.name }}}> */}
                        {/* <Link className="nav-link" to={{ pathname: '/order-now/'}}> */}
                          <Card className='border-0 hover-card bg-transparent'>
                          <Card.Img variant="top" src={image_url+data.image} className='w-100 rounded-35' />
                          <Card.Title className='text-center fw-bold fs-5 my-1'> <span></span> {data.name}</Card.Title>
                        </Card>
                        {/* </Link> */}
                        </Col>
                        {/* old with no space  */}
                      {/* <Col xs={6} sm={6} lg={4} className="p-0 border-black ">
                        <Link className="nav-link" to={{ pathname: '/food/'+data.slug, state: { name: data.name }}}>
                          <Card className='border-0 hover-card bg-transparent'>
                          <Card.Img variant="top" src={image_url+data.image} className='w-100' />
                       
                        </Card>
                        </Link>
                        </Col> */}
                       {/* <Col xs={6} sm={6} lg={4} className="p-0 border-black ">
                         <Link className="nav-link" to={{ pathname: '/order-now/product/'+data.categoryid}}>
                           <Card className='border-0 hover-card bg-transparent'>
                           <Card.Img variant="top" src={image_url+data.image} className='w-100' />
                           <Card.Body className="p-1">
                             <Card.Title className='text-center fw-bold fs-4 my-1'> <span dangerouslySetInnerHTML={{ __html: data.icon }}></span> {data.name}</Card.Title>
                          
            
                           </Card.Body>
                         </Card>
                         </Link>
                         </Col> */}
                      
                      </> )
                    }))
                }
              
{/* petpooja category */}


{
                    petpoojacat.map((data,index)=>{
                      // petpoojacat.map((data,index)=>{
                        return (<>
                      <Col xs={6} sm={6} lg={4} className="g-3">
                      <Link className="nav-link" to={{ pathname: '/order-now/product/'+data.categoryid}}>
                  <Card className='border-0 hover-card bg-transparent'>
                 
                  <Card.Img variant="top" src={data.category_image_url} className='w-100 rounded-35' />
                    <Card.Title className='text-center fw-bold fs-5 my-1'> <span></span> {data.categoryname}</Card.Title>
             
                </Card>
                </Link>
                        </Col>
                   
                      
                      </> )
                    })
                }



{/* petpooja category end */}
              
          
              </Row>
              <div className="text-center my-4">
                {location.pathname=="/"?
                // <Link to={"/all-menu"}
                <Link to={"/menu"}
                className=" fw-bold fs-4 btn more_menu_button btn-animate text-white"
                variant=""
                size="lg"
              >
                View Full Menu
              </Link>
                :""}
            
          </div>
        </Container>
      </div>
     
     
    </>
  );
};

export default Menu;
