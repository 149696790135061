

import React,{useEffect,useState,useContext} from "react";
import { useParams } from "react-router";
import Row from "react-bootstrap/Row";
import { Link,useLocation } from 'react-router-dom';
import Col from "react-bootstrap/Col";
import { Container,Button } from "react-bootstrap";
import pc1 from "./../images/pc1.png";
import pc2 from "./../images/pc2.png";
import pc3 from "./../images/pc3.png";
import pc4 from "./../images/pc4.png";
import pc5 from "./../images/pc5.png";
import pc6 from "./../images/pc6.png";
import headingbg from "./../images/heading-bg.png";
import {base_url,image_url} from '../config';
import { SettingData } from '../provider/StateProvider';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import call from "./../images/call.png";
import Footer from '../includes/Footer';
import FixedHeader from "../includes/FixedHeader";
import Swal from "sweetalert2";
const ContactUs = ({ featuredmenu}) => {
    const { setting } = useContext(SettingData);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
      });
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
          const response = await fetch(`${base_url}api/contact-us`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
          });
    
          if (response.ok) {
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Your message has been sent successfully.'
            });
            setFormData({
              name: '',
              email: '',
              message: ''
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong. Please try again later.'
            });
          }
        } catch (error) {
          console.error('Error:', error);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong. Please try again later.'
          });
        }
      };

  return (<>
   <FixedHeader featuredmenu={featuredmenu}/>
   <Row className=" mx-0">
   <Col sm={12} lg={10} className="offset-lg-2 px-0">
   <Container className="pt-5">
 
<div className='pt-4'>
      <div className="w-c mb-4">
     
      <h1 className='text-center display-5 fw-bold m-0'>Contact Us</h1>

      {/* <div class="eight">
       <h1 className="text-danger fw-bold">Timings 11am-10pm</h1>
      </div> */}
      </div>
      </div>
  
     
      <section>
    
 
    
    <div className="container">
    <Row>
      <Col xs={12} lg={6} className="d-flex align-items-center">
        
        <div className="contact-info">
          <div className="contact-info-item">
            <div className="contact-info-icon">
              <i className="fas fa-home text-danger"></i>
            </div>
            
            <div className="contact-info-content">
              <h4 className="text-danger">Address</h4>
              <p>{setting.address}</p>
            </div>
          </div>
          
          <div className="contact-info-item">
            <div className="contact-info-icon">
              <i className="fas fa-phone text-danger"></i>
            </div>
            
            <div className="contact-info-content">
              <h4 className="text-danger">Phone</h4>
              <p>{setting.mobile_no}</p>
            </div>
          </div>
          
          {/* <div className="contact-info-item">
            <div className="contact-info-icon">
              <i className="fas fa-envelope"></i>
            </div>
            
           <div className="contact-info-content">
              <h4>Email</h4>
             <p>ntamerrwael@mfano.ga</p>
            </div>
          </div> */}
        </div>
        </Col>
        <Col xs={12} lg={6}>

        
        <div className="contact-form">
          <form onSubmit={handleSubmit} id="contact-form">
            <h2>Send Message</h2>
            <div className="input-box">
              <input type="text" 
               id="name"
               name="name"
               value={formData.name}
               onChange={handleInputChange}
               required/>
              <span>Full Name</span>
            </div>
            
            <div className="input-box">
              <input type="email" 
                
                 className="form-control"
                 id="email"
                 name="email"
                 value={formData.email}
                 onChange={handleInputChange}
                 required/>
              <span>Email</span>
            </div>
            
            <div className="input-box">
              <textarea 
               id="message"
               name="message"
               value={formData.message}
               onChange={handleInputChange}
               rows="5"
               required
              ></textarea>
              <span>Type your Message...</span>
            </div>
            
            <div className="input-box">
            <button type="submit"  className="bg-danger">Send Message</button>
              {/* <input type="submit" value="Send" name=""/> */}
            </div>
          </form>
        </div>
        </Col>
        </Row>
    </div>
  </section>
  <div className="margin-bottom-100"></div>
    </Container>
    <Footer/>
   </Col>

   </Row>
  </>
  
  );
}

export default ContactUs;