import React from 'react'
import ilssidelogo from "./../images/ilssidelogo.png";
import dine from "./../images/dine.png";
import banner from "./../images/banner.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from 'react-bootstrap/Card';

import { Link,useLocation } from 'react-router-dom';

import { Container,Button } from "react-bootstrap";
import DineSlider from './DineSlider';
import directions from "./../images/directions.png";
import {base_url,image_url} from '../config';
const Dine = ({featuredine}) => {
  return (<>
  
    <div className='pt-4'>
      
     
        <div className="w-c mb-4">
      <h2 className='text-center display-5 fw-bold m-0'>DINE WITH US</h2>
      
      <div class="eight">
       <h1 className="text-danger fw-bold">Timings 11am-10pm</h1>
      </div>
      </div>
       
        {/* <DineSlider featuredine={featuredine}/> */}
        {/* <Container>
        <Row>
        {
                    featuredine.map((data,index)=>{
                        return (
                          <Col xs={6} sm={6} md={4} lg={3}>
                          <Card className='border-0'>
                            <Card.Img variant="top" src={image_url+data.thumbnail} className='rounded-4' />
                            <Card.Body>
                              <Card.Title className='text-center fw-bold fs-3 mb-0'><i class="fa-solid fa-location-dot text-danger"></i> {data.name}</Card.Title>
                              <Card.Text className='p-0 m-0 text-center'>
                              {data.address}
                              </Card.Text>
                              
              
                            </Card.Body>
                          </Card>
                        </Col >
                           
                        )
                    })
                }


                
         
      

        </Row>
      </Container> */}
        <Container>
        <Row>
        {
                    featuredine.map((data,index)=>{
                        return (
                          <Col xs={12} sm={12} md={6} lg={3}>
                          <Card className='border-0'>
                              <Link to={'/restaurents/'+data.slug}>
                              
                            <Card.Img variant="top" src={image_url+data.thumbnail} className={` card-img-restro rounded-4 ${data.is_comming_soon == 1 ? 'bw-image' : ''}`} />
                            </Link>
                            <Card.Body>
                            <Link to={'/restaurents/'+data.slug} className="nav-link">
                              <Card.Title className='fw-bold  mb-0'>
                                {/* <i class="fa-solid fa-location-dot text-danger"></i> */}
                                 {data.name}</Card.Title>
                              </Link>
                              <Card.Text className='p-0 m-0 height-97'>
                              {data.address}
                              </Card.Text>
                              {data.is_comming_soon==0?
                              <Card.Title className='fw-bold'>
                                  <h6><a className="nav-link text-danger" target="_blank" href={data.map_link}><img src={directions} style={{width:'20px'}}/> Get Directions</a></h6>
                              </Card.Title>
                            :<Card.Title className='fw-bold'>
                            <h6>Celebrating Our Restaurant Soon!</h6>
                            </Card.Title>}
              
                            </Card.Body>
                          </Card>
                        </Col >
                           
                        )
                    })
                }


                
         
      

        </Row>
      </Container>
    </div>
  </>

  )
}

export default Dine



