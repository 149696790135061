import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import Row from "react-bootstrap/Row";
import { Link, useLocation } from "react-router-dom";
import Col from "react-bootstrap/Col";
import { Container, Button } from "react-bootstrap";

import directions from "./../images/directions.png";
import headingbg from "./../images/heading-bg.png";
import { base_url, image_url } from "../config";
import { SettingData } from "../provider/StateProvider";
import Card from "react-bootstrap/Card";
import Footer from "../includes/Footer";
import FixedHeader from "../includes/FixedHeader";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function TrackOrder({ featuredmenu }) {
  const [formData, setFormData] = useState({
    orderId: "",
    contactInfo: "",
  });
  const [orderInfo, setOrderInfo] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //   const handleSubmit = async (e) => {
  //     e.preventDefault();
  //     try {
  //       const response = await axios.post(base_url+'api/track-order', formData);
  //       setOrderInfo(response.data.order);
  //       setErrorMessage('');
  //     } catch (error) {
  //       setOrderInfo(null);
  //       setErrorMessage('Order not found. Please check your input.');
  //     }
  //   }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(base_url + "api/track-order", formData);
      if (response.data.order === null) {
        setOrderInfo(null);
        setErrorMessage("Order not found. Please check your details.");
      } else {
        setOrderInfo(response.data.order);
        setErrorMessage("");
      }
    } catch (error) {
      setOrderInfo(null);
      setErrorMessage("Order not found. Please check your details.");
    }
  };
  const handleDeleteClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to cancel this order?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Make an API request to cancel the order

          const response = await axios.post(base_url + "api/cancel-order", {
            orderId: orderInfo.orderID,
          });
          setTimeout(() => {
            console.log(response.data);
          }, 3000);
          if (response.data.success) {
            // Handle success message
            Swal.fire(
              "Cancelled!",
              "Your order has been cancelled.",
              "success"
            );

            setTimeout(() => {
              navigate("/");
            }, 3000);
          } else {
            // Handle error message
            Swal.fire("Error!", "Failed to cancel the order.", "error");
          }
        } catch (error) {
          // Handle error message
          Swal.fire("Error!", "Failed to cancel the order.", "error");
        }
      }
    });
  };
  return (
    <>
      <FixedHeader featuredmenu={featuredmenu} />
      <Row className=" mx-0">
        <Col sm={12} lg={10} className="offset-lg-2 px-0">
          <Container>
            <div className="container mt-5">
              <div class="card text-center mb-3">
                <div class="card-header">
                  <h2 className="text-center">Track Your Order</h2>
                </div>
                <form onSubmit={handleSubmit}>
                  <div class="card-body">
                    <div className="form-group my-2">
                      <label htmlFor="orderId">Order ID</label>
                      <input
                        type="text"
                        className="form-control"
                        id="orderId"
                        name="orderId"
                        value={formData.orderId}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group my-2">
                      <label htmlFor="contactInfo">
                        Contact Information (Mobile/Email)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="contactInfo"
                        name="contactInfo"
                        value={formData.contactInfo}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div class="card-footer text-muted">
                    <button type="submit" className="btn btn-primary mt-2">
                      Track Order
                    </button>
                  </div>
                </form>
              </div>

              {errorMessage && (
                <div className="alert alert-danger mt-3">{errorMessage}</div>
              )}
              {orderInfo && (
                <div className="mt-4">
                  <div class="my-5 justify-content-center">
                    <div class="card px-2 card-track">
                      <div class="card-header bg-white">
                        <div class="row justify-content-between">
                          <div class="col">
                            <p class="text-muted">
                              {" "}
                              Order ID{" "}
                              <span class="font-weight-bold text-dark">
                                #{orderInfo.orderID}
                              </span>
                            </p>
                            <p class="text-muted">
                              {" "}
                              Place On{" "}
                              <span class="font-weight-bold text-dark">
                                {new Date(orderInfo.created_at)
                                  .toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })
                                  .split("/")
                                  .join("-")}
                              </span>{" "}
                            </p>
                          </div>
                          <div class="flex-col my-auto">
                            <h6 class="ml-auto mr-3">View Details</h6>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        {orderInfo.items.map((item) => (
                          <>
                            <div class="media flex-column flex-sm-row">
                              <div class="media-body ">
                                <img
                                  class="align-self-center img-fluid"
                                  src={item.item_image_url}
                                  width="180 "
                                  height="180"
                                />
                                <h5 class="bold">{item.item_name}</h5>
                                <p class="text-muted p-0 m-0">
                                 
                                  Qty: {item.quantity}
                                </p>
                                <p class="p-0 m-0">
                                 
                                  <span class="">&#x20B9;</span> {item.price}
                                </p>
                                {/* <h4 class="mt-3 mb-4 bold"> <span class="mt-5">&#x20B9;</span> {item.price}</h4> */}

                                {/* <button type="button" class="btn  btn-outline-primary d-flex">Reached Hub, Delhi</button>     */}
                              </div>
                            </div>
                          </>
                        ))}
                        <h4 class="mt-3 mb-4 bold">
                         
                          Total <span class="mt-5">&#x20B9;</span>
                          {orderInfo.total}
                        </h4>
                        <p class="text-muted">
                          Status on:
                          <span class="Today">
                            {new Date(orderInfo.updated_at)
                              .toLocaleString("en-US", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: true, // 12-hour format with AM/PM
                              })
                              .replace(/\//g, "-")}
                          </span>
                        </p>
                        <h6 class="mt-3 mb-4 bold">
                         
                          Order Status: {orderInfo.order_status}
                        </h6>
                      </div>
                      <div class="row px-3">
                        <div class="col">
                          {/* <ul id="progressbar" >
                    <li class="step0 active " id="step1">PLACED</li>
                    <li class="step0 active text-center" id="step2">SHIPPED</li>
                    <li class="step0  text-muted text-right" id="step3">DELIVERED</li>
                </ul> */}
                        </div>
                      </div>
                      <div class="card-footer  bg-white px-sm-3 pt-sm-4 px-0">
                        <div class="row text-center  ">
                          {/* <div class="col my-auto  border-line "><h5 >Track</h5></div> */}
                          <div class="col  my-auto  border-line ">
                            {orderInfo &&
                              orderInfo.order_status === "pending approval" && (
                                <h5>
                                  <button
                                    className="btn btn-danger"
                                    onClick={handleDeleteClick}
                                  >
                                    Cancel
                                  </button>
                                </h5>
                              )}
                            {/* <h5><button className="btn btn-danger" onClick={handleDeleteClick}>Cancel</button></h5> */}
                          </div>
                          {/* <div class="col my-auto   border-line "><h5>Pre-pay</h5></div> */}
                          {/* <div class="col  my-auto mx-0 px-0 "><img class="img-fluid cursor-pointer" src="https://img.icons8.com/ios/50/000000/menu-2.png" width="30" height="30"/></div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Container>
          <div className="margin-bottom"></div>
          <Footer />
        </Col>
      </Row>
    </>
  );
}

export default TrackOrder;
