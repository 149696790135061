import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import Row from "react-bootstrap/Row";
import { Link, useLocation } from 'react-router-dom';
import Col from "react-bootstrap/Col";
import { Container, Button } from "react-bootstrap";
import pc1 from "./../images/pc1.png";
import pc2 from "./../images/pc2.png";
import pc3 from "./../images/pc3.png";
import pc4 from "./../images/pc4.png";
import pc5 from "./../images/pc5.png";
import pc6 from "./../images/pc6.png";
import headingbg from "./../images/heading-bg.png";
import { base_url, image_url } from '../config';
import { SettingData } from '../provider/StateProvider';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import { Modal } from "react-bootstrap";
import Footer from '../includes/Footer';
import FixedHeader from "../includes/FixedHeader";
import { useNavigate } from 'react-router-dom';

const PetPoojaSingleProduct = ({ featuredmenu, cartItems, setCartItems }) => {
  const [quantity, setQuantity] = useState(0);
  const [product, setProduct] = useState({});
  const [attribute, setAttribute] = useState({});
  const [hasVariations, sethasVariations] = useState(false);
  const [hasAddons, sethasAddons] = useState(false);
  const [Addons, setAddons] = useState([]);
  const { slug } = useParams();
  const [selectedVariation, setSelectedVariation] = useState('');
  const [cart, setCart] = useState([]);
  const [addedToCart, setAddedToCart] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedAddonsHook, setselectedAddons] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    // Fetch cart items from local storage on component mount
    const storedCart = localStorage.getItem('cart');
    if (storedCart) {
      setCartItems(JSON.parse(storedCart));
      const inCart = cartItems.some(item => item.id === product.id);
      setAddedToCart(inCart);
    }
  }, [product]);
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const showmodel = () => {
    setShowModal(true);
  };
  const addToCart = () => {
    const selectedAddons = Addons.filter(addon => addon.isSelected);
    if (hasVariations) {
      if (selectedVariation) {

        const existingItemIndex = cartItems.findIndex(
          (item) => (
            item.variationid == selectedVariation &&
            JSON.stringify(item.addons) == JSON.stringify(selectedAddons)

          )
        );
        if (existingItemIndex !== -1) {
          const updatedCart = [...cartItems];
          updatedCart[existingItemIndex].quantity += 1;
          setCartItems(updatedCart);
        } else {
          const selectedVariationObj = product.variation.find(
            (variation) => variation.variationid === selectedVariation
          );

          setCartItems([
            ...cartItems,
            {
              id: product.id,
              item_image_url: product.item_image_url,
              name: product.itemname,
              itemid: product.itemid,
              variationid: selectedVariationObj.variationid,
              variation_id: selectedVariationObj.id,
              variation_name: selectedVariationObj.name,
              price: parseInt(selectedVariationObj.price) + selectedAddons.reduce((acc, addon) => acc + parseInt(addon.addonitem_price), 0),
              quantity: 1,
              addons: selectedAddons
            },
          ]);
        }
        setSelectedVariation("");
      } else {
        alert("Please select a variation");
      }
    } else {
      const existingItemIndex = cartItems.findIndex(
        (item) => (
          item.id === product.id &&
          JSON.stringify(item.addons) === JSON.stringify(selectedAddons)
        )
      );

      if (existingItemIndex !== -1) {
        const updatedCartItems = [...cartItems];
        updatedCartItems[existingItemIndex].quantity += 1;
        setCartItems(updatedCartItems);
      } else {

        setCartItems([
          ...cartItems,
          {
            id: product.id,
            item_image_url: product.item_image_url,
            itemid: product.itemid,
            name: product.itemname,
            price: parseInt(product.price) + selectedAddons.reduce((acc, addon) => acc + parseInt(addon.addonitem_price), 0),
            quantity: 1,
            addons: selectedAddons
          },
        ]);
      }
    }
  };

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cartItems));
    setShowModal(false);
    const inCart = cartItems.some(item => item.id === product.id);
    setAddedToCart(inCart);
  }, [cartItems]);



  // const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    // Fetch product details
    fetchProduct();
    // Retrieve cart items from local storage on component mount

  }, []);


  // const fetchVariationAddon = async () => {

  //   // if addon apply on variations of item
  //   if (product.itemaddonbasedon == 1 && product.itemallowaddon == 1 && product.itemallowvariation == 1) {
  //     const selectedVariationObj = product.variation.find(
  //       (variation) => variation.variationid === selectedVariation
  //     );
   
  //       if(selectedVariationObj.variationallowaddon==1){
  //         const addonGroupIds = selectedVariationObj.addon.map(group => group.addon_group_id);
  //         console.log(addonGroupIds);
  //         // console.log(response.data.product)
  //         const responseaddon = await axios.post(base_url + "api/getAddonByGroup_id", {
  //           addonGroupIds,
  //         }
  //         );
  //         console.log(responseaddon.data.addons, 'addonsssss')
  //         setAddons(responseaddon.data.addons);
  //         sethasAddons(true);
  //       }else{
  //         setAddons([]);
  //         sethasAddons(false);
  //       }
   

  //   }
  // }
  const fetchVariationAddon = async () => {
    // Check if addon applies to variations of the item
    if (product.itemaddonbasedon === 1 && product.itemallowaddon === 1 && product.itemallowvariation === 1) {
      // Find the selected variation object
      const selectedVariationObj = product.variation.find(variation => variation.variationid === selectedVariation);
  
      // Check if the selected variation allows addons
      if (selectedVariationObj && selectedVariationObj.variationallowaddon === 1) {
        try {
          // Extract addonGroupIds from the selected variation
          const addonGroupIds = selectedVariationObj.addon.map(addon => addon.addon_group_id);
  
          // Fetch addons based on addonGroupIds
          const responseaddon = await axios.post(base_url + "api/getAddonByGroup_id", { addonGroupIds });
  
          // Update state with fetched addons
          setAddons(responseaddon.data.addons);
          sethasAddons(true);
        } catch (error) {
          // Handle errors if any
          console.error('Error fetching addons:', error);
          setAddons([]);
          sethasAddons(false);
        }
      } else {
        // If the selected variation does not allow addons
        setAddons([]);
        sethasAddons(false);
      }
    }
  }
  

  useEffect(() => {

    fetchVariationAddon();


  }, [selectedVariation]);


  const fetchProduct = async () => {
    try {
      const response = await axios.get(base_url + 'api/petpoojasingleproduct/' + slug);
      console.log(response)
      if (response.data.product == null) {
        navigate('/order-now');
      }
      setProduct(response.data.product);
      setAttribute(response.data.product.attribute)
      // console.log(response.data.product.attribute.attribute);
      if (response.data.product.variation.length > 0) {
        sethasVariations(true);
        setSelectedVariation(response.data.product.variation[0].variationid)

      }
      // if (response.data.product.itemallowaddon==1 && response.data.product.addon.length>0) {

      //   // console.log(response.data.product.addon[0].addon_group_id)
      //   const responseaddon = await axios.get(
      //     base_url + "api/getAddonByGroup_id/" + response.data.product.addon[0].addon_group_id
      //   );
      //   setAddons(responseaddon.data.addons);
      //   sethasAddons(true);

      // }
      if (response.data.product.itemallowaddon == 1 && response.data.product.itemallowvariation == 0) {
        const addonGroupIds = response.data.product.addon.map(group => group.addon_group_id);
        console.log(addonGroupIds);
        // console.log(response.data.product)
        const responseaddon = await axios.post(base_url + "api/getAddonByGroup_id", {
          addonGroupIds,
        }
        );
        setAddons(responseaddon.data.addons);
        sethasAddons(true);

      } else if (response.data.product.itemallowaddon == 1 && response.data.product.itemallowvariation == 1) {
        // if addon apply on item
        if (response.data.product.itemaddonbasedon == 0) {

          const addonGroupIds = response.data.product.variation[0].addon.map(group => group.addon_group_id);
          console.log(addonGroupIds);
          // console.log(response.data.product)
          const responseaddon = await axios.post(base_url + "api/getAddonByGroup_id", {
            addonGroupIds,
          }
          );
          setAddons(responseaddon.data.addons);
          sethasAddons(true);

        }

        console.log(response.data.product)
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  // Addon group IDs ko extract kare

  // const addToCart = () => {
  //   if (hasVariations) {
  //     if (selectedVariation) {
  //       const existingItemIndex = cartItems.findIndex(item => item.variationid === selectedVariation);
  //       if (existingItemIndex !== -1) {
  //         const updatedCart = [...cartItems];
  //         updatedCart[existingItemIndex].quantity += 1;
  //         setCartItems(updatedCart);
  //       } else {
  //         const selectedVariationObj = product.variation.find(variation => variation.variationid === selectedVariation);
  //         setCartItems([...cartItems, { id: product.id, item_image_url: product.item_image_url, name: product.itemname, itemid: product.itemid, variationid: selectedVariationObj.variationid, variation_id: selectedVariationObj.id, variation_name: selectedVariationObj.name, price: selectedVariationObj.price, quantity: 1 }]);
  //       }
  //       setSelectedVariation('');
  //     } else {
  //       alert('Please select a variation');
  //     }
  //   } else {
  //     const existingItemIndex = cartItems.findIndex(item => item.id === product.id);

  //     if (existingItemIndex !== -1) {
  //       const updatedCartItems = [...cartItems];
  //       updatedCartItems[existingItemIndex].quantity += 1;
  //       setCartItems(updatedCartItems);
  //     } else {

  //       setCartItems([...cartItems, { id: product.id, item_image_url: product.item_image_url, itemid: product.itemid, name: product.itemname, price: product.price, quantity: 1 }]);
  //     }

  //   }
  // };
  const handleAddonSelection = (addonId) => {
    const updatedAddons = Addons.map(addon => {
      if (addon.addonitemid == addonId) {
        return { ...addon, isSelected: !addon.isSelected };
      }
      return addon;
    });
    setAddons(updatedAddons);
    setselectedAddons(prevProduct => ({ ...prevProduct, addons: updatedAddons }));
    setTimeout(() => {
      // console.log("selected",selectedAddons);
      console.log("addon hook selected", Addons);
    }, 4000)

  };
  const removeFromCart = (productId) => {
    const updatedCartItems = cartItems.filter(item => item.id !== productId);
    setCartItems(updatedCartItems);
  };

  const handleIncrement = () => {

    // addToCart();
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 0) {
      setQuantity(prevQuantity => prevQuantity - 1);
      const existingItemIndex = cartItems.findIndex(item => item.id === product.id);
      if (existingItemIndex !== -1) {
        const updatedCartItems = [...cartItems];
        updatedCartItems[existingItemIndex].quantity -= 1;
        setCartItems(updatedCartItems);
      }
    }
  };

  setTimeout(() => {

    // product.variation.map((data,index)=>{
    //     console.log(data)
    // })
    // console.log(product.variation)
  }, 5000)

  const getColor = (attribute) => {
    switch (attribute) {
      case 'veg':
        return 'text-success'; // Green color for veg
      case 'non-veg':
        return 'text-danger'; // Red color for non-veg
      case 'egg':
        return 'orange'; // Orange color for egg
      default:
        return 'black'; // Default color
    }
  };
  return (<>
    <FixedHeader featuredmenu={featuredmenu}
    //   banner={featuredmenu}
    />
    <Row className=" mx-0">
      <Col sm={12} lg={10} className="offset-lg-2 px-0">
        <div className="single-page-padding">


          <Row className="g-0">

            <Col xs={12} lg={6}>
              <Card.Img variant="top" src={product.item_image_url} className='w-100' />
            </Col>
            <Col xs={12} lg={6}>
              {/* <Card className='bg-transparent'> */}

              <Card.Body className="p-1">
                <Card.Title className='text-center fw-bold fs-4 my-1'> {product.itemname}</Card.Title>
                {/* <h5 className="mt-5">{product.itemname}</h5> */}
                {/* <h6 className="text-center">{product.attribute.attribute == 'veg' ? <span className="fw-bold text-success">veg</span> :""} {product.attribute.attribute == 'non-veg' ? <span className="fw-bold text-danger">non-veg</span>:""}</h6> */}

                <h6 className={"fw-bold text-center " + getColor(attribute.attribute)} >{attribute.attribute}</h6>
                {/* <p className="my-5">{product.itemdescription}</p> */}
                
                {!hasVariations ?
                  <h6 className="text-center">Price: ₹ {product.price}</h6>
                  : <h6 className="text-center">Price: ₹ {product.variation[0].price}</h6>}
                {/* {quantity > 0 ? (
              <div className="my-5 text-center">
                <Button variant="outline-danger" className="me-2" onClick={handleDecrement}>-</Button>
                <span>{quantity}</span>
                <Button variant="outline-danger" className="ms-2" onClick={handleIncrement}>+</Button>
              </div>
            ) : (
              <div className="text-center">
                <Button variant="outline-danger" className="my-5" onClick={handleIncrement}>Add to Cart</Button>
              </div>
            )} */}
                {/* {hasVariations ? (
                  <div className="text-center">
                    <h2>Select Variations</h2>
                    <div>
                      {product.variation.map(variation => (
                        <label key={variation.id} className="fw-bold">
                          <input
                            type="radio"
                            name="variation"
                            value={variation.variationid}
                            checked={selectedVariation === variation.variationid}
                            onChange={() => setSelectedVariation(variation.variationid)}
                          />
                          {variation.name} <span className="text-center text-danger">(₹ {variation.price}) </span>,
                        </label>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div>
                   
                  </div>
                )} */}

                <div className="text-center">

                  {hasVariations || hasAddons ?
                    <Button variant="outline-danger" className="my-5" onClick={showmodel}>{addedToCart ? "Added" : "Add to Cart"}</Button> :
                    <Button variant="outline-danger" className="my-5" onClick={addToCart}>{addedToCart ? "Added" : "Add to Cart"}</Button>
                  }
                </div>
                  {product.itemdescription!=null?
                  <>
                    <h3 className="text-center text-danger">Description</h3>
                                <p className="mt-1 text-center">{product.itemdescription}</p>
                  </>:''
                }
                
              </Card.Body>
              {/* </Card> */}
            </Col>
          </Row>
        </div>



        <Footer />
      </Col>

    </Row>

    <Modal show={showModal} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>{product.itemname}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {hasVariations ? (
          <div >
            <h5 className="">Select Variations</h5>
            <div>
              {product.variation.map(variation => (<>
                <div className="d-flex justify-content-between my-1">
                  <div>
                    <label key={variation.id} className="fw-medium">
                      <input
                        type="radio"
                        className="form-check-input mx-2"
                        name="variation"
                        value={variation.variationid}
                        checked={selectedVariation === variation.variationid}
                        onChange={() => setSelectedVariation(variation.variationid)}
                      />
                      {variation.name}
                    </label>
                  </div>
                  <div>
                    <span className="text-center text-danger">₹ {variation.price}</span>
                  </div>
                </div>

              </>))}
            </div>
          </div>
        ) : (
          <div>

          </div>
        )}
        {hasAddons && Addons.length > 0 ? (
          <div>
            <hr></hr>
            <h5 className="">Addons</h5>

            <div>
              {Addons.map((addon) => (
                <>
                  <div className="d-flex justify-content-between my-1">
                    <div>
                      <label key={addon.id} className="fw-medium">
                        <input
                          type="checkbox"
                          className="form-check-input mx-2"
                          name="variation"
                          value={addon.addonitemid}
                          checked={addon.isSelected}
                          onChange={() => handleAddonSelection(addon.addonitemid)}
                        /> 
                        {addon.addonitem_name}
                      </label>
                    </div>
                    <div>
                      <span className="text-center text-danger">
                        ₹ {addon.addonitem_price}
                      </span>
                    </div>
                  </div>
                </>
              ))}
            </div>

          </div>
        ) : (
          <div></div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button> */}
        <Button variant="outline-danger" className="" onClick={addToCart}>Add to Cart</Button>
      </Modal.Footer>
    </Modal>



  </>

  );
}

export default PetPoojaSingleProduct;