import React,{useEffect,useState,useContext} from "react";
import logo from './logo.svg';
import './App.css';
import Header from './includes/Header';
import Home from './component/Home';
import CategoryProducts from './component/CategoryProducts';
import { Routes, Route,Link } from "react-router-dom";
import Footer from './includes/Footer';
import StateProvider from '../src/provider/StateProvider';
import axios from 'axios';
import Menu from "./../src/component/Menu";
import SingleProduct from "./../src/component/SingleProduct";
import Hero from "./../src/includes/Hero";
import FoodHeader from "./../src/includes/FoodHeader";
import {base_url,image_url} from './config';
import FixedHeader from "./../src/includes/FixedHeader";
import News from "./component/News";
import SingleRestaurent from "./component/SingleRestaurent";
import MenuPage from "./component/MenuPage";
import OrderNow from "./component/OrderNow";
import OrderNow2 from "./component/OrderNow2";
import OurLocation from "./component/OurLocation";
import AboutPage from "./component/AboutPage";
import WhatsNew from "./component/WhatsNew";
import SingleNews from "./component/SingleNews";
import Review from "./component/Review";
import PetpoojaCategoryProduct from "./component/PetpoojaCategoryProduct";
import PetPoojaSingleProduct from "./component/PetPoojaSingleProduct";
import CartPage from "./component/CartPage";
import CheckoutPage from "./component/CheckoutPage";
import OrderConfirmationPage from "./component/OrderConfirmationPage";
import QRMenuPage from "./component/QRMenuPage";
import RestraurentMenu from "./component/RestraurentMenu";
import TrackOrder from "./component/TrackOrder";
import TermsCondition from "./component/TermsCondition";
import PrivacyPolicy from "./component/PrivacyPolicy";
import ReturnRefundPolicy from "./component/ReturnRefundPolicy";
import ShippingPolicy from "./component/ShippingPolicy";
import ContactUs from "./component/ContactUs";
import { useLocation } from 'react-router-dom';
import { Modal,Card,Col,Row,Button} from "react-bootstrap";
import Loader from './component/Loader';
function App() {
  const [allmenu, setalldmenu] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [currentPath, setCurrentPath] = useState(location.pathname);
  useEffect(() => {
    // Update the currentPath state whenever the location changes
    setCurrentPath(location.pathname);
  }, [location.pathname]);
  useEffect(() => {
    fetchmenu();
  }, []);
  useEffect(() => {
    // Fetch cart items from local storage on component mount
    const storedRestaurant = JSON.parse(localStorage.getItem('restaurant'));

    // Set the restaurant data in the component state
    if (!storedRestaurant) {
      // localStorage.removeItem('cart');
      localStorage.removeItem('order_type');
      // setCartItems([])
    }
  }, []);
  useEffect(() => {
    // Fetch cart items from local storage on component mount
    const storedCart = localStorage.getItem('cart');
    if (storedCart) {
      if(JSON.parse(storedCart).length>0){
        setCartItems(JSON.parse(storedCart));
      }
      
    }
  }, []);


  const fetchmenu = async () => {
    try {
      const response = await axios.get(base_url + 'api/category');
      // const filteredData = response.data.category.filter(item => item.is_featured == 1);
      setalldmenu([...response.data.category]);
      // setfeaturedmenu([...response.data.category]);
     


    } catch (error) {
      console.error('Error fetching data:', error);

    }
  };
 

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
   <>
   <StateProvider cartItems={cartItems} setCartItems={setCartItems} Loader={Loader}>

{/* model start */}


{/* model end*/}





         <Routes>
         <Route path="/" exact element={<><Home /></>}/>
         {/* <Route path="/all-menu" exact element={<><Hero/><Menu featuredmenu={allmenu}/><News/></>}/> */}
         {/* <Route path="/all-menu" exact element={<><MenuPage featuredmenu={allmenu} cartItems={cartItems} setCartItems={setCartItems}/></>}/> */}
         {/* <Route path="/all-menu" exact element={<><QRMenuPage featuredmenu={allmenu} cartItems={cartItems} setCartItems={setCartItems}/></>}/> */}

         <Route path="/all-menu" exact element={<><RestraurentMenu featuredmenu={allmenu} cartItems={cartItems} setCartItems={setCartItems}/></>}/>
         <Route path="/food/:slug" exact element={<><CategoryProducts featuredmenu={allmenu}/></>}/>
         <Route path="/product/:slug" exact element={<><Hero/><SingleProduct cartItems={cartItems} setCartItems={setCartItems}/></>}/>
         <Route path="/item/:slug" exact element={<><PetPoojaSingleProduct featuredmenu={allmenu} cartItems={cartItems} setCartItems={setCartItems}/></>}/>
         <Route path="/restaurents/:slug" exact element={<><SingleRestaurent featuredmenu={allmenu}/></>}/>
         <Route path="/news/:slug" exact element={<><SingleNews featuredmenu={allmenu}/></>}/>
         <Route path="/restaurents" exact element={<><OurLocation featuredmenu={allmenu}/></>}/>
         <Route path="/about-us" exact element={<><AboutPage featuredmenu={allmenu}/></>}/>
         <Route path="/whats-new" exact element={<><WhatsNew featuredmenu={allmenu}/></>}/>
         <Route path="/reviews" exact element={<><Review featuredmenu={allmenu}/></>}/>
         {/* <Route path="/order-now" exact element={<><OrderNow featuredmenu={allmenu}/></>}/> */}
         <Route path="/order-now" exact element={<><RestraurentMenu featuredmenu={allmenu} cartItems={cartItems} setCartItems={setCartItems}/></>}/>
         <Route path="/cart" exact element={<><CartPage featuredmenu={allmenu} cartItems={cartItems} setCartItems={setCartItems}/></>}/>
         <Route path="/order-now/product/:id" exact element={<><PetpoojaCategoryProduct featuredmenu={allmenu} cartItems={cartItems} setCartItems={setCartItems}/></>}/>
         <Route path="/checkout" exact element={<><CheckoutPage featuredmenu={allmenu} cartItems={cartItems} setCartItems={setCartItems}/></>}/>
         <Route path="/order-confirmation" exact element={<><OrderConfirmationPage featuredmenu={allmenu} cartItems={cartItems} setCartItems={setCartItems}/></>}/>
         <Route path="/all-menu/:restaurantid" exact element={<><QRMenuPage featuredmenu={allmenu} cartItems={cartItems} setCartItems={setCartItems}/></>}/>
         {/* <Route path="/menu/:restaurantid/:order_type" exact element={<><RestraurentMenu featuredmenu={allmenu} cartItems={cartItems} setCartItems={setCartItems}/></>}/> */}
         <Route path="/menu" exact element={<><RestraurentMenu featuredmenu={allmenu} cartItems={cartItems} setCartItems={setCartItems}/></>}/>
         <Route path="/home-delivery" exact element={<><QRMenuPage featuredmenu={allmenu} cartItems={cartItems} setCartItems={setCartItems}/></>}/>
         <Route path="/track-order" exact element={<><TrackOrder featuredmenu={allmenu}/></>}/>
         {/* policy pages */}
         <Route path="/terms-condition" exact element={<><TermsCondition featuredmenu={allmenu}/></>}/>
         <Route path="/privacy-policy" exact element={<><PrivacyPolicy featuredmenu={allmenu}/></>}/>
         <Route path="/return-and-refund-policy" exact element={<><ReturnRefundPolicy featuredmenu={allmenu}/></>}/>
         <Route path="/shipping-policy" exact element={<><ShippingPolicy featuredmenu={allmenu}/></>}/>
         <Route path="/contact-us" exact element={<><ContactUs featuredmenu={allmenu}/></>}/>
       
         </Routes>
         {currentPath!='/cart' && currentPath!='order-confirmation' && cartItems.length>0 &&  (
       <div className="top-cart bg-danger">
       <Link className="nav-link text-white" to="/cart"><i class="fa-solid fa-cart-shopping"></i> <span className="qty-cart">{cartItems.length}</span> <span className="mx-3">₹{cartItems.reduce((acc, item) => acc + (item.price * item.quantity), 0)}</span></Link>
     </div>
      )}
     
     </StateProvider>
         </>
  );
}

export default App;
