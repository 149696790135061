
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import Row from "react-bootstrap/Row";
import { Link, useLocation } from 'react-router-dom';
import Col from "react-bootstrap/Col";
import { Container, Button } from "react-bootstrap";
import pc1 from "./../images/pc1.png";
import pc2 from "./../images/pc2.png";
import pc3 from "./../images/pc3.png";
import pc4 from "./../images/pc4.png";
import pc5 from "./../images/pc5.png";
import pc6 from "./../images/pc6.png";
import headingbg from "./../images/heading-bg.png";
import { base_url, image_url } from '../config';
import { SettingData } from '../provider/StateProvider';
import Card from 'react-bootstrap/Card';
import Footer from '../includes/Footer';
// import video from "./../images/home.mp4";
// import video1 from "./../images/home1.mp4";
import about1 from "./../images/about1.mp4";
import about2 from "./../images/about2.mp4";

import FixedHeader from "../includes/FixedHeader";

import axios from 'axios';
import News from "./News";
import Dine from "./Dine";

const AboutPage = ({ featuredmenu }) => {
    const {featuredine} = useContext(SettingData);
    // const [product, setproduct] = useState([]);



    // useEffect(() => {


    //     singlecat();




    //   }, []);


    //   const singlecat = async () => {
    //     try {

    //       const singleresponse = await axios.get(base_url + 'api/singlecategory/'+slug);
    //         setSelectedItem(singleresponse.data.singlecategory);

    //       const response = await axios.get(base_url + 'api/category-products/'+singleresponse.data.singlecategory.id);


    //       setproduct([...response.data.categoryproduct]);


    //     } catch (error) {
    //       console.error('Error fetching data:', error);

    //     }
    //   };


    return (<>
        <FixedHeader featuredmenu={featuredmenu} />
        <Row className=" mx-0">
            <Col sm={12} lg={10} className="offset-lg-2 px-0">
                <Container>
                    <h2 className="text-center my-5 fw-bold display-5">About Us</h2>

                    <Row>
                        <Col xs={12} sm={12} lg={6} className="mb-3">
                            <Card className='border-0 hover-card'>
                                {/* <Card.Img variant="top" src="" className='w-100' /> */}
                                <video className="w-100" autoPlay muted loop>
                                    <source src={about1} type="video/mp4" />

                                </video>

                            </Card>

                        </Col>
                        <Col xs={12} sm={12} lg={6} className="mb-3 d-flex align-items-center">
                            <Card className='border-0'>
                                <h4 className="px-2"> Welcome to ILS!</h4>
     
 
                                <p className="color-gray px-2">Born in the bustling heart of Indore, ILS is a modern take on the classic sandwich, serving up a global chorus of flavors at prices that sing to everyone.

We're not about fancy frills or following trends. We're about bringing the world's vibrant street food flavors to your plate, wrapped in perfectly toasted bread.

Indore, with its rich culinary heritage and love for all things delicious, is the perfect place to start our flavorful journey. And with four stores opening their doors across the city, we're ready to share our passion for perfect sandwiches with you.

So come on down, grab a bite, and let your taste buds travel the world, one sandwich at a time. At ILS, we're all about saying 'I love sandwiches!' and we mean it.</p>

                            </Card>

                        </Col>

                    </Row>
                    <Row>
                       
                        <Col xs={12} sm={12} lg={6} className="mb-3 d-flex align-items-center">
                            <Card className='border-0'>
                                <h4 className="px-2">Our Cuisine</h4>
                                <p className="color-gray px-2">
                                At ILS, we take pride in offering a diverse menu inspired by global cuisines, crafted with locally sourced ingredients and seasonal produce. From mouthwatering appetizers and flavorful entrees to decadent desserts, each dish is thoughtfully prepared to tantalize your taste buds and leave you craving for more. Whether you're a fan of Sandwiches or prefer classic comfort food with a twist, our menu has something for everyone.</p>

                            </Card>

                        </Col>
                        <Col xs={12} sm={12} lg={6} className="mb-3">
                            <Card className='border-0 hover-card'>
                                {/* <Card.Img variant="top" src="" className='w-100' /> */}
                                <video className="w-100" autoPlay muted loop>
                                    <source src={about2} type="video/mp4" />

                                </video>

                            </Card>

                        </Col>

                    </Row>

                </Container>
                <Dine featuredine={featuredine}/>
<News/>
                <Footer />
            </Col>

        </Row>

    </>
    )
}

export default AboutPage
