import React,{useEffect,useState,useContext} from "react";
import { useParams } from "react-router";
import Row from "react-bootstrap/Row";
import { Link,useLocation } from 'react-router-dom';
import Col from "react-bootstrap/Col";
import { Container,Button } from "react-bootstrap";
import pc1 from "./../images/pc1.png";
import pc2 from "./../images/pc2.png";
import pc3 from "./../images/pc3.png";
import pc4 from "./../images/pc4.png";
import pc5 from "./../images/pc5.png";
import pc6 from "./../images/pc6.png";
import headingbg from "./../images/heading-bg.png";
import {base_url,image_url} from '../config';
import { SettingData } from '../provider/StateProvider';
import Card from 'react-bootstrap/Card';
import axios from 'axios';

const SingleProduct = ({ setCartItems, cartItems }) => {
  const [quantity, setQuantity] = useState(0);
  const [product, setProduct] = useState({});
  const { slug } = useParams();

  useEffect(() => {
    fetchProduct();
  }, []);

  const fetchProduct = async () => {
    try {
      const response = await axios.get(base_url + 'api/singleproduct/' + slug);
      setProduct(response.data.singleproduct);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const addToCart = () => {
    const existingItemIndex = cartItems.findIndex(item => item.id === product.id);

    if (existingItemIndex !== -1) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingItemIndex].quantity += 1;
      setCartItems(updatedCartItems);
      console.log(cartItems)
    } else {
      setCartItems([...cartItems, { ...product, quantity: 1 }]);
    }
  };

  const handleIncrement = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
    addToCart();
  };

  const handleDecrement = () => {
    if (quantity > 0) {
      setQuantity(prevQuantity => prevQuantity - 1);
    }
  };

  return (
    <Container>
      <h5 className="mt-5">{product.name}</h5>
      <h6>{product.is_veg == 1 ? <span className=" fw-bold text-success">veg</span> : <span className=" fw-bold text-danger">non-veg</span>}</h6>
      <p className="my-5">{product.short_description}</p>
      {/* <h6>Price: ₹ {product.sale_price}/-</h6> */}
      {/* {quantity > 0 ? (
        <div className="my-5">
          <button className="btn btn-outline-danger me-2" onClick={handleDecrement}>-</button>
          <span>{quantity}</span>
          <button className="btn btn-outline-danger ms-2" onClick={handleIncrement}>+</button>
        </div>
      ) : (
        <button
          className="btn btn-outline-danger my-5"
          onClick={handleIncrement}
        >
          Add to Cart
        </button>
      )} */}
       <Link to={'/order-now'}
          className="btn btn-outline-danger my-5"
          
        >
          Order Now
        </Link>
    </Container>
  );
}

export default SingleProduct;