
import React,{useEffect,useState,useContext} from "react";
import { useParams } from "react-router";
import Row from "react-bootstrap/Row";
import { Link,useLocation } from 'react-router-dom';
import Col from "react-bootstrap/Col";
import { Container,Button } from "react-bootstrap";
import pc1 from "./../images/pc1.png";
import pc2 from "./../images/pc2.png";
import pc3 from "./../images/pc3.png";
import pc4 from "./../images/pc4.png";
import pc5 from "./../images/pc5.png";
import pc6 from "./../images/pc6.png";
import headingbg from "./../images/heading-bg.png";
import {base_url,image_url} from '../config';
import { SettingData } from '../provider/StateProvider';
import Card from 'react-bootstrap/Card';
import Footer from '../includes/Footer';
import FixedHeader from "../includes/FixedHeader";

import axios from 'axios';
import News from "./News";

const CategoryProducts = ({featuredmenu}) => {
    const [selectedItem, setSelectedItem] = useState({'name':''});
    const [product, setproduct] = useState([]);
   
    const { slug } = useParams();
    // const { state } = useLocation();


    useEffect(() => {
       
     
        singlecat();
      
        


      }, [slug]);


    //   const fetchmenu = async () => {
    //     try {
    //       const response = await axios.get(base_url + 'api/category-products/'+id);
       
          
    //       setproduct([...response.data.categoryproduct]);
         
    
         
    
    
    //     } catch (error) {
    //       console.error('Error fetching data:', error);
    
    //     }
    //   };
      const singlecat = async () => {
        try {
         
          const singleresponse = await axios.get(base_url + 'api/singlecategory/'+slug);
            setSelectedItem(singleresponse.data.singlecategory);
          // setfeaturedmenu([...response.data.category]);
          const response = await axios.get(base_url + 'api/category-products/'+singleresponse.data.singlecategory.id);
       
          
          setproduct([...response.data.categoryproduct]);
         
    
        } catch (error) {
          console.error('Error fetching data:', error);
    
        }
      };

    
  return (<>
  <FixedHeader featuredmenu={featuredmenu} banner={product}/>
   <Row className=" mx-0">
   <Col sm={12} lg={10} className="offset-lg-2 px-0">
   <Container>
    <h2 className="text-center my-5 fw-bold display-5">{selectedItem.name}</h2>

    <Row>

    {
                    product.map((data,index)=>{
                        return (<>
                          
                      <Col xs={6} sm={6} lg={4} className="mb-3">
                        <Link className="nav-link" to={'/product/'+data.slug}>

 



                          <Card className='border-0 hover-card'>
                          <Card.Img variant="top" src={image_url+data.image} className='w-100' />
                          <Card.Body className="p-1">
                            <Card.Title className='text-center fw-bold my-4'>
                                <h6>{data.name}</h6>
                            </Card.Title>
                            <Card.Title className='text-center fw-bold'>
                                <h6>{data.is_veg==1?<span className=" fw-bold text-success">veg</span>:<span className=" fw-bold text-danger">non-veg</span>}</h6>
                            </Card.Title>
                          
            
                          </Card.Body>
                        </Card>
                        </Link>
                        </Col>
                    
                          {/* //   <Col xs={6} sm={6} lg={4}>
                          //   <Link><img src={image_url+data.image} alt="" className="w-100" /></Link>
                          // </Col> */}
                          </> )
                    })
                }
    </Row>
   
  </Container>
  
  <Footer/>
   </Col>

   </Row>
 
  </> 
  )
}

export default CategoryProducts
