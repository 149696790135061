
import React,{useEffect,useState,useContext} from "react";
import { useParams } from "react-router";
import Row from "react-bootstrap/Row";
import { Link,useLocation } from 'react-router-dom';
import Col from "react-bootstrap/Col";
import { Container,Button } from "react-bootstrap";
import pc1 from "./../images/pc1.png";
import pc2 from "./../images/pc2.png";
import pc3 from "./../images/pc3.png";
import pc4 from "./../images/pc4.png";
import pc5 from "./../images/pc5.png";
import pc6 from "./../images/pc6.png";
import headingbg from "./../images/heading-bg.png";
import {base_url,image_url} from '../config';
import { SettingData } from '../provider/StateProvider';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import Footer from '../includes/Footer';
import FixedHeader from "../includes/FixedHeader";
const ShippingPolicy = ({ featuredmenu}) => {
 

  return (<>
   <FixedHeader featuredmenu={featuredmenu}/>
   <Row className=" mx-0">
   <Col sm={12} lg={10} className="offset-lg-2 px-0">
   <Container className='pt-4'>
      {/* <h5 className="mt-5">{product.name}</h5>
      <h6>{product.is_veg == 1 ? <span className=" fw-bold text-success">veg</span> : <span className=" fw-bold text-danger">non-veg</span>}</h6>
      <p className="my-5">{product.short_description}</p> */}
{/* <h2><strong>Terms and Conditions</strong></h2> */}
<div className='pt-4'>
      <div className="w-c mb-4">
     
      <h1 className='text-center display-5 fw-bold m-0'>Shipping Policy</h1>

      {/* <div class="eight">
       <h1 className="text-danger fw-bold">Timings 11am-10pm</h1>
      </div> */}
      </div>
      </div>

<p>Welcome to I love Sandwich!</p>
<p>This document was last updated on April 30, 2024</p>

    <p>This shipping policy explains how Dreamcorp QSR Pvt Ltd. operates its shipping procedures
        and how we strive to meet your expectations with every order. Whether you’re a first-time
        buyer or a returning customer, we want to ensure that your experience with us is smooth and
        satisfactory, right from placing your order to the moment it arrives at your doorstep. This
        policy has been created with the help of the shipping policy generator.</p>
        <div class="eight">
       <h3 className="text-danger fw-bold">Delivery Timings</h3>
      </div>
       
    <p>Delivery timings may vary based on the following factors:</p>
    <ul>
        <li><strong>Delivery Distance:</strong> Orders closer to our location may have shorter delivery times, while orders farther away may take longer to deliver.</li>
        <li><strong>Quantity of Food Ordered:</strong> Larger orders or orders with multiple items may require additional time for preparation and delivery.</li>
        <li><strong>Preparation Time:</strong> We strive to prepare and dispatch your order as quickly as possible. However, please allow sufficient time for our team to prepare your food with care and attention to detail.</li>
    </ul>

    
    <div class="eight">
       <h3 className="text-danger fw-bold">Delivery Areas</h3>
      </div>
    <p>We offer delivery services to select areas. The delivery areas may vary based on our delivery partners and their coverage zones. Please check our website or contact our customer service team to confirm if we deliver to your location.</p>

    {/* <h2>3. Shipping Charges</h2>
    <p>We offer free shipping on all orders above [insert amount]. For orders below this amount, a nominal shipping fee may apply, which will be displayed during the checkout process.</p> */}
  <div class="eight">
       <h3 className="text-danger fw-bold">Order Tracking</h3>
      </div>
    
    <p>Once your order is dispatched for delivery, you will receive a confirmation email or SMS with a tracking link. You can use this link to track the status of your order and get real-time updates on its delivery status.</p>
    <div class="eight">
       <h3 className="text-danger fw-bold">Delivery Partners</h3>
      </div>
  
    <p>We partner with trusted courier services to ensure the safe and timely delivery of your orders. Our delivery partners are committed to delivering your orders with care and efficiency.</p>
    <div class="eight">
       <h3 className="text-danger fw-bold">Contact Us</h3>
      </div>
    
    <p>If you have any questions or concerns regarding our shipping policy, please feel free to contact our customer service team. We are here to assist you and ensure that you have a seamless and enjoyable experience with us.</p>
    <p>Email: <a href="mailto:support@ilovesandwich.in">support@ilovesandwich.in</a></p>
    <p>Please note that our shipping policy is subject to change without prior notice. We recommend reviewing this policy regularly for any updates or changes.</p>

  

   
    </Container>
    <Footer/>
   </Col>

   </Row>
  </>
  
  );
}

export default ShippingPolicy;