import React,{useEffect,useState,useContext} from "react";
import Hero from "../includes/Hero";
import Testimonial from "./Testimonial";
import Menu from "./Menu";
import Dine from "./Dine";
import Row from "react-bootstrap/Row";
import { Link } from 'react-router-dom';
import Col from "react-bootstrap/Col";
import { Container,Button } from "react-bootstrap";
import pc1 from "./../images/pc1.png";
import pc2 from "./../images/pc2.png";
import pc3 from "./../images/pc3.png";
import pc4 from "./../images/pc4.png";
import pc5 from "./../images/pc5.png";
import pc6 from "./../images/pc6.png";
import headingbg from "./../images/heading-bg.png";
import {base_url,image_url} from '../config';
import { SettingData } from '../provider/StateProvider';
import axios from 'axios';
import Footer from '../includes/Footer';
import News from "./News";

import OurLocation from "./OurLocation";

const Home = () => {
  const [featuredmenu, setfeaturedmenu] = useState([]);
  const {featuredine,setting} = useContext(SettingData);
  useEffect(() => {
    fetchmenu();
  }, []);


  const fetchmenu = async () => {
    try {
      const response = await axios.get(base_url + 'api/category');
      const filteredData = response.data.category.filter(item => item.is_featured == 1);
      setfeaturedmenu([...filteredData]);
      // setfeaturedmenu([...response.data.category]);
     


    } catch (error) {
      console.error('Error fetching data:', error);

    }
  };
  
  return (
    <>
      <Hero />
       {/* <Container>
        <div className="position-relative round-h-m">
          <div className="special-div">
            <h2 className="text-center round-heading m-0">
             *Special Offer*
            </h2>
          </div>
          <div className=" round-heading-div">
            <h2 className="text-center fw-bold  round-heading m-0">
             
              Get 1 Drink & 1 Sandwich at just ₹299
            </h2>
            <p className="text-center p-0 m-0 condition"> offer valid at stores. Conditions Apply</p>
          </div>
        </div>
      </Container> */}
      {/* Menu Heading */}
      {/* <div className="container-strip">
      <div class="moving-text">Your Text Here</div>
      </div> */}
     
      <div class="">
    <div class="container-strip">
      <div class="moving-text">{setting.offer_strip}</div>
    </div>
  </div>
  
      <Menu featuredmenu={featuredmenu} />
      <Dine featuredine={featuredine} />
     
      {/* <OurLocation/> */}
      <Testimonial/>
      <News/>
      <Footer/>
    </>
  );
};

export default Home;
