import React,{useContext,useState,useEffect} from 'react'
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

import ilssidelogo from "./../images/ilssidelogo.png";
import user from "./../images/user.png";
import bread_cleanup from "./../images/bread_cleanup.png";
import banner from "./../images/banner.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Slider from "react-slick";
import {base_url,image_url} from '../config';
import axios from 'axios';
import { SettingData } from '../provider/StateProvider';

const Testimonial = () => {

 
  // const [testimonial, settestimonial] = useState([]);
  const [loader, setloader] = useState(false);
  const {testimonial,main_testimonial} = useContext(SettingData);
  console.log(testimonial);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    rows: 2,
    
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };


  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };

  // useEffect(() =>{
  //   setmain_testimonial(testimonial.find(item => item.is_main == 1)) ;
  //   },[])
  // console.log(main_testimonial)






  // useEffect(() => {
  //   fetchData();
  // }, []);


  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(base_url+'api/home');
  //     settestimonial([...response.data.testimonial]);
      
      
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
      
  //   }
  // };
 
  return (<>
  <div className='pt-4 pb-5 bg-danger'>
    <Container>
        <h2 className='text-center text-white fw-bold display-5'>HAPPY CUSTOMERS!</h2>
        {/* <Row>
          
      
         
            <Col sm={12} lg={6}>
              <div className=' text-center position-relative left-testimonial'>
                <img src={bread_cleanup} alt="" className='w-sm-100'/>


                <div className='left-testimonial-content'>
                <div className='mb-1'>
                  <img src={image_url+main_testimonial.profile_image} style={{width:'70px'}} alt="" />
                </div>
                <div className='p-1 mw-210'>
                  <div className='card content-fit m-auto bg-left-star mb-2'>
                      <div className="card-body py-1 px-2">
                      {Array.from({ length: main_testimonial.star }, (_, index) => (
                    
                      <i key={index} class="fa-solid fa-star star-color mx-1"></i>
                    ))}
                      {Array.from({ length: 5-main_testimonial.star }, (_, index) => (
                    
                      <i key={index} class="fa-solid fa-star text-secondary mx-1"></i>
                    ))}


                     
                     
                     
                      </div>
                  </div>
                <p className='m-0 p-0 text-black'>{main_testimonial.message}</p>
                </div>
                </div>
              </div>
            </Col >
            <Col sm={12} lg={6}>
            <Slider {...settings}>
            {testimonial.map(( data, index)=>{
            
            return(<>
            <div className={ index%2==0?'message-box mb-5 mx-1': 'message-box-2 mb-5 mx-1'}>
            <div className="message-content d-flex align-items-center ">
                <div>
                  <img src={image_url+data.profile_image} style={{width:'60px'}} alt="" />
                </div>
                <div className='p-1'>
                  <div className='card content-fit'>
                      <div className="card-body py-1 px-2">
                      {Array.from({ length: data.star }, (_, index) => (
                    
                    <i key={index} class="fa-solid fa-star star-color mx-1"></i>
                  ))}
                    {Array.from({ length: 5-data.star }, (_, index) => (
                  
                    <i key={index} class="fa-solid fa-star text-secondary mx-1"></i>
                  ))}
                     
                      </div>
                  </div>
                <p className='m-0 p-0 text-white'>{data.message}</p>
                </div>
            </div>
          </div>
         
          </>)

})}
       
              </Slider>
          
            </Col >
        </Row> */}
          <Row>
          
      
         
          {/* <Col sm={12} lg={6}>
            <div className=' text-center position-relative left-testimonial'>
              <img src={bread_cleanup} alt="" className='w-sm-100'/>


              <div className='left-testimonial-content'>
              <div className='mb-1'>
                <img src={image_url+main_testimonial.profile_image} style={{width:'70px'}} alt="" />
              </div>
              <div className='p-1 mw-210'>
                <div className='card content-fit m-auto bg-left-star mb-2'>
                    <div className="card-body py-1 px-2">
                    {Array.from({ length: main_testimonial.star }, (_, index) => (
                  
                    <i key={index} class="fa-solid fa-star star-color mx-1"></i>
                  ))}
                    {Array.from({ length: 5-main_testimonial.star }, (_, index) => (
                  
                    <i key={index} class="fa-solid fa-star text-secondary mx-1"></i>
                  ))}


                   
                   
                   
                    </div>
                </div>
              <p className='m-0 p-0 text-black'>{main_testimonial.message}</p>
              </div>
              </div>
            </div>
          </Col > */}
          <Col sm={12} lg={12}>
          <Slider {...settings}>
          {testimonial.map(( data, index)=>{
          
          return(<>
          <div className={ index%2==0?'message-box mb-5 mx-1': 'message-box-2 mb-5 mx-1'}>
          <div className="message-content d-flex align-items-center ">
              <div>
                <img src={image_url+data.profile_image} style={{width:'60px'}} alt="" />
              </div>
              <div className='p-1'>
                <div className='card content-fit'>
                    <div className="card-body py-1 px-2">
                    {Array.from({ length: data.star }, (_, index) => (
                  
                  <i key={index} class="fa-solid fa-star star-color mx-1"></i>
                ))}
                  {Array.from({ length: 5-data.star }, (_, index) => (
                
                  <i key={index} class="fa-solid fa-star text-secondary mx-1"></i>
                ))}
                   
                    </div>
                </div>
              <p className='m-0 p-0 text-white'>{data.message}</p>
              </div>
          </div>
        </div>
       
        </>)

})}
     
            </Slider>
        
          </Col >
      </Row>
    </Container>
  </div>
  
  </>
  )
}

export default Testimonial