// import React,{useEffect,useState} from "react";
// import Row from "react-bootstrap/Row";
// import { Link } from 'react-router-dom';
// import Col from "react-bootstrap/Col";
// import { Container,Button } from "react-bootstrap";
// import pc1 from "./../images/pc1.png";
// import pc2 from "./../images/pc2.png";
// import pc3 from "./../images/pc3.png";
// import pc4 from "./../images/pc4.png";
// import pc5 from "./../images/pc5.png";
// import pc6 from "./../images/pc6.png";
// import explore from "./../images/explore.png";
// import { useLocation } from 'react-router-dom';

// import Card from 'react-bootstrap/Card';
// import News from "./News";
// import {base_url,image_url} from '../config';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import Footer from '../includes/Footer';
// import FixedHeader from "../includes/FixedHeader";

// const QRMenuPage = ({featuredmenu}) => {
//   const [petpoojacat, setpetpoojacat] = useState([]);
//   const location = useLocation();
//   const [restaurant, setRestaurant] = useState({});
//   const { restaurantid } = useParams();
  
//   const [currentPath, setCurrentPath] = useState(location.pathname);
//   useEffect(() => {
//     // allcategory()
//   }, []);
//   const allcategory = async () => {
//     try {
     
//       const response = await axios.get(base_url + 'api/getAllPetPoojaCategoty');

//       setpetpoojacat([...response.data.category]);
      
//       setTimeout(()=>{
        
//       },3000)

//     } catch (error) {
//       console.error('Error fetching data:', error);

//     }
//   };
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         let storedRestaurant = localStorage.getItem('restaurant');
        
//         // if (storedRestaurant) {
//         //   // If restaurant exists in local storage, set it in the state
//         //   const response = await axios.get(base_url + `api/getrestroPetPoojaCategoty/${storedRestaurant.restaurantid}`);
           

//         //     setpetpoojacat([...response.data.category]);
//         // } else  
//         if (restaurantid) {
//           // Make API call to find restaurant
          
//             const response = await axios.get(base_url + `api/getrestroPetPoojaCategoty/${restaurantid}`);
//             const response1 = await axios.get(base_url + `api/findRestro/${restaurantid}`);

//             setpetpoojacat([...response.data.category]);
//           // Store restaurant in local storage
//           localStorage.setItem('restaurant', JSON.stringify(response1.data.restro));
//           // Set restaurant in state
//           setRestaurant(response1.data.restro);
//           console.log(restaurant)
//         } else {
//           // Make another API call if param is not received
//           const response = await axios.get(base_url + 'api/getAllPetPoojaCategoty');

//           setpetpoojacat([...response.data.category]);
//           // Handle response from the second API call as needed
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, [restaurantid]);

//   return (
//     <>
//      <FixedHeader featuredmenu={featuredmenu} banner={featuredmenu}/>
//    <Row className=" mx-0">
//    <Col sm={12} lg={10} className="offset-lg-2 px-0">
//     {/* Menu Heading */}
//     <div className="menu-main-div">
//         <Container>
//               <div className="text-center my-4">
//                 <img src={explore} alt="" className="explore"/>
//                 {/* <h2 className="curved-text">E X P L O R E   O U R</h2> */}

          

//                 <h2 className=" menu-heading">Menu</h2>
//               </div>
//               <Row className="">
//                 {
//                     // featuredmenu.map((data,index)=>{
//                       petpoojacat.map((data,index)=>{
//                         return (
//                       // <Col xs={6} sm={6} lg={4} className="p-0 border-black ">
//                       //   <Link className="nav-link" to={{ pathname: '/food/'+data.slug, state: { name: data.name }}}>
//                       //     <Card className='border-0 hover-card bg-transparent'>
//                       //     <Card.Img variant="top" src={image_url+data.image} className='w-100' />
                       
//                       //   </Card>
//                       //   </Link>
//                       //   </Col>
//                       <Col xs={6} sm={6} lg={4} className="g-3">
//                         <Link className="nav-link" to={{ pathname: '/order-now/product/'+data.categoryid}}>
//                           <Card className='border-0 hover-card bg-transparent'>
//                           <Card.Img variant="top" src={image_url+data.image} className='w-100' />
//                           <Card.Title className='text-center fw-bold fs-5 my-1'> <span></span> {data.name}</Card.Title>
//                           {/* <Card.Body className="p-1">
//                             <Card.Title className='text-center fw-bold fs-4 my-1'> <span dangerouslySetInnerHTML={{ __html: data.icon }}></span> {data.name}</Card.Title>
                          
            
//                           </Card.Body> */}
//                         </Card>
//                         </Link>
//                         </Col>
                      
//                         )
//                     })
//                 }
              
          
//               </Row>
//               <div className="text-center my-4">
//                 {location.pathname=="/"?
//                 <Link to={"/all-menu"}
//                 className=" fw-bold fs-4 btn more_menu_button btn-animate text-white"
//                 variant=""
//                 size="lg"
//               >
//                 View Full Menu
//               </Link>
//                 :""}
            
//           </div>
//         </Container>
//       </div>
//   <News/>
//   <Footer />
//    </Col>

//    </Row>
    
     
     
//     </>
//   );
// };

// export default QRMenuPage;

// old end
// new start 






import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import Row from "react-bootstrap/Row";
import { Link, useLocation } from 'react-router-dom';
import Col from "react-bootstrap/Col";
import { Container, Button } from "react-bootstrap";
import pc1 from "./../images/pc1.png";
import pc2 from "./../images/pc2.png";
import pc3 from "./../images/pc3.png";
import pc4 from "./../images/pc4.png";
import pc5 from "./../images/pc5.png";
import pc6 from "./../images/pc6.png";
import headingbg from "./../images/heading-bg.png";
import { base_url, image_url } from '../config';
import { SettingData } from '../provider/StateProvider';
import Card from 'react-bootstrap/Card';
import Footer from '../includes/Footer';
import FixedHeader from "../includes/FixedHeader";
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import News from "./News";
import Menu from "./Menu";
import explore from "./../images/explore.png";
const QRMenuPage = ({ featuredmenu, cartItems, setCartItems }) => {

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [petpoojacat, setpetpoojacat] = useState([]);
  const [error, setError] = useState(null);
  const [product, setproduct] = useState([]);
  const [location, setlocation] = useState(0);
  const [restaurant, setRestaurant] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { handleShowMainModel,setting } = useContext(SettingData);
  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            // setLatitude(position.coords.latitude);
            // setLongitude(position.coords.longitude);

            // getSingleRestroPetPoojaProduct()
          },
          error => {
            setError(error.message);
          }
        );
      } else {
        setError('Geolocation is not supported by this browser.');
      }
    };

    getLocation();
  }, []);


  const getSingleRestroPetPoojaProduct = async (restroid) => {
    try {

      // const response = await axios.get(base_url + 'api/getAllPetPoojaProduct');
      // const response = await axios.get(base_url +`api/getSingleRestroPetPoojaProduct/${restroid}`);
      // const response = await axios.get(base_url + `api/getrestroPetPoojaCategoty/${restroid}`);
      const response = await axios.get(base_url + `api/getrestroPetPoojaCategoty/${restroid}`);




      setproduct([...response.data.category]);
      setIsLoading(false);







    } catch (error) {
      console.error('Error fetching data:', error);

    }
  };


  const [autocomplete, setAutocomplete] = useState(null);
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [apiKey, setapiKey] = useState(setting.google_map_key);
  const [selectedLocation, setSelectedLocation] = useState({ lat: 0, lng: 0 });

  const [address, setAddress] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredProducts = product.filter(data => {
    // Check if any item within the category matches the search query
    return data.items.some(item => item.itemname.toLowerCase().includes(searchQuery.toLowerCase())) ||
           data.categoryname.toLowerCase().includes(searchQuery.toLowerCase());
  });
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = handleScriptLoad;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleScriptLoad = () => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('autocomplete')
    );
    autocomplete.addListener('place_changed', handlePlaceSelect);
  };

  const handlePlaceSelect = () => {
    const selectedPlace = document.getElementById('autocomplete').value;
    setAddress(selectedPlace);
    localStorage.setItem('currentAddress', selectedPlace);

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: selectedPlace }, (results, status) => {
      if (status === 'OK') {
        const location = results[0].geometry.location;
        setLatitude(location.lat());
        setLongitude(location.lng());
      } else {
        console.error('Geocode was not successful for the following reason:', status);
      }
    });
  };

  const fetchCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error('Error getting the current location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const handleGetCurrentLocation = async () => {
    fetchCurrentLocation();
    getCurrentLocationAddress();
    handleGetCurrentLocation1()

  };

  function getCurrentLocationAddress() {
    // Check if geolocation is supported by the browser
    if ("geolocation" in navigator) {
      // Get the current position
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;

        try {
          // Fetch the address using a reverse geocoding service
          const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`);
          const data = await response.json();

          // Check if the response is successful
          if (data.status === "OK" && data.results.length > 0) {
            // Get the formatted address from the first result
            const formattedAddress = data.results[0].formatted_address;
            console.log("Current Location Address:", formattedAddress);
            setAddress(formattedAddress);
            localStorage.setItem('currentAddress', formattedAddress);
          } else {
            console.error("Error fetching address:", data.status);
            return null;
          }
        } catch (error) {
          console.error("Error fetching address:", error);
          return null;
        }
      }, (error) => {
        console.error("Error getting current location:", error);
        return null;
      });
    } else {
      console.error("Geolocation is not supported by this browser.");
      return null;
    }
  }

  const handleButtonClick = async () => {
    if (!latitude) {
      alert('please select location');
    }
    console.log("Address:", address);
    console.log("Latitude:", latitude);
    console.log("Longitude:", longitude);


  };
  const handleGetCurrentLocation1 = async () => {


    if (latitude) {

      // getSingleRestroPetPoojaProduct(latitude,longitude)
      const response1 = await axios.get(`${base_url}api/get_restro?latitude=${latitude}&longitude=${longitude}`);

      if (response1.data.restro != null) {
        localStorage.setItem('restaurant', JSON.stringify(response1.data.restro));
        // Set restaurant in state
        setRestaurant(response1.data.restro);

        getSingleRestroPetPoojaProduct(response1.data.restro.restaurantid)
        setlocation(1)
        setTimeout(() => {
          console.log(product, 'products')
          setlocation(1)
        }, 2000);

      } else {

        setlocation(2)
      }



    }
    // else{
    //   alert('please fill address')
    // }
  };
  useEffect(() => {

    handleGetCurrentLocation1()
    // setTimeout(() => {
    //   console.log(address, 'address')
    // }, 2000);

  }, [latitude]);


  useEffect(() => {
    // Retrieve restaurant data from localStorage
    const storedRestaurant = JSON.parse(localStorage.getItem('restaurant'));

    // Set the restaurant data in the component state
    if (storedRestaurant) {
      setRestaurant(storedRestaurant);
      getSingleRestroPetPoojaProduct(storedRestaurant.restaurantid)
      setlocation(1)
    }
    const currentAddress = localStorage.getItem('currentAddress');

    // Set the restaurant data in the component state
    if (currentAddress) {
      setAddress(currentAddress);

    }
  }, []);
  const changeAddress = () => {
    setlocation(0);


  };
  const getColor = (attribute) => {
    switch (attribute) {
      case 'veg':
        return 'text-success'; // Green color for veg
      case 'non-veg':
        return 'text-danger'; // Red color for non-veg
      case 'egg':
        return 'orange'; // Orange color for egg
      default:
        return 'black'; // Default color
    }
  };
  return (<>
    {/* <FixedHeader featuredmenu={featuredmenu}
   
    // banner={featuredmenu}
    /> */}
    <FixedHeader featuredmenu={featuredmenu}
    banner={location === 1 ? featuredmenu : null} 
    />
    <Row className=" mx-0 mt-3">
      <Col sm={12} lg={10} className="offset-lg-2 px-0">
        {/* <Menu featuredmenu={featuredmenu}/> */}


        <div className="container mt-4" style={{
          display: location != 1 ? 'block' : 'none',

        }}>



          <div className="card mb-3">
            <div className="card-body">
              <h2 class="display-5 fw-bold color-gray text-center">Your location</h2>
              <div className="input-group mb-3">
                <input
                  id="autocomplete"
                  type="text"
                  className="form-control"
                  placeholder="Enter your address"
                />
                {/* <button className="btn btn-outline-secondary" type="button" onClick={handleButtonClick}>Get Address</button> */}
                {/* <button className="btn btn-outline-danger" type="button" onClick={handleGetCurrentLocation}><i class="fa-solid fa-location-crosshairs"></i> Use Current Location</button> */}
              </div>
              <button className="btn btn-outline-danger" type="button" onClick={handleGetCurrentLocation}><i class="fa-solid fa-location-crosshairs"></i> Use Current Location</button>
            </div>
          </div>
        </div>

        {location != 1 ? <>


          {location == 2 ?
            <div className="container mt-2">
              <p>Service Not Avaliable  on this address, Please Use another Address</p>
            </div>
            : ''}


          <div className="margin-bottom2"></div>
        </>
          : <>
                      {isLoading ? 
                           <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>:
       


                <div className="px-2">
                  <div className="card mb-3" >
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <p className="p-0 m-0">

                          <span className="h6 fw-bold">Delivery to: </span>
                          {address}</p>
                        
                          <button className="nav-link text-danger fw-bold" onClick={changeAddress}>Change Address</button>
                        <button className="nav-link text-danger fw-bold" onClick={handleShowMainModel}>Change Outlet</button>
                    
                 
                      </div>


                    </div>
                  </div>
                  {/* <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              className="form-control"
              placeholder="Search food..."
            /> */}
                
                  
                 
                        
                <div className="menu-main-div">
       <Container>
             <div className="text-center my-4">
               <img src={explore} alt="" className="explore"/>
               {/* <h2 className="curved-text">E X P L O R E   O U R</h2> */}

          

               <h2 className=" menu-heading">Menu</h2>
             </div>
             <Row className="">
               {
            // featuredmenu.map((data,index)=>{
              product.map((data,index)=>{
                return (
              // <Col xs={6} sm={6} lg={4} className="p-0 border-black ">
              //   <Link className="nav-link" to={{ pathname: '/food/'+data.slug, state: { name: data.name }}}>
              //     <Card className='border-0 hover-card bg-transparent'>
              //     <Card.Img variant="top" src={image_url+data.image} className='w-100' />
                       
              //   </Card>
              //   </Link>
              //   </Col>
              <Col xs={6} sm={6} lg={4} className="g-3">
                <Link className="nav-link" to={{ pathname: '/order-now/product/'+data.categoryid}}>
                  <Card className='border-0 hover-card bg-transparent'>
                 
                  <Card.Img variant="top" src={data.category_image_url} className='w-100 rounded-35' />
                    <Card.Title className='text-center fw-bold fs-5 my-1'> <span></span> {data.categoryname}</Card.Title>
                 
                  {/* <Card.Body className="p-1">
                    <Card.Title className='text-center fw-bold fs-4 my-1'> <span dangerouslySetInnerHTML={{ __html: data.icon }}></span> {data.name}</Card.Title>
                          
            
                  </Card.Body> */}
                </Card>
                </Link>
                </Col>
               
                      
                )
            })
        }
              
          
      </Row>
      <div className="text-center my-4">
        {location.pathname=="/"?
        <Link to={"/all-menu"}
        className=" fw-bold fs-4 btn more_menu_button btn-animate text-white"
        variant=""
        size="lg"
      >
        View Full Menu
      </Link>
        :""}
            
  </div>
</Container>
      </div>
                </div>
             
           }
          </>
        }



<News/>

        <Footer />
      </Col>

    </Row>

  </>
  )
}

export default QRMenuPage



