import React,{useEffect,useState,useContext} from "react";
import { useLocation } from 'react-router-dom';
import cm from "./../images/cm.png";
import { Link } from 'react-router-dom';
import done from "./../images/done.avif";
const OrderConfirmationPage = ({cartItems,setCartItems}) => {
  // Use useLocation hook to access location object
  const location = useLocation();
  const [orderDetails, setorderDetails] = useState(location.state && location.state.orderDetails);
  
  // localStorage.clear();
  // Access orderDetails from location state
setTimeout(() => {
    console.log(orderDetails)  
}, 5000);
useEffect(() => {
  localStorage.removeItem('cart');
  setCartItems([])
}, []);
  return (
    <div>
      {/* <h2>Order Confirmation</h2> */}
      {orderDetails? (
  <div className="container py-5">
  <div className="row">
    <div className="col-md-8 mx-auto">
      <div className="card shadow">
        <div className="card-body">
          <h2 className="card-title text-center mb-4">Order Confirmation</h2>
          {orderDetails? (
            <div>
              <div className="m-auto d-flex justify-content-center">
        <img src={done} className="card-img-top odr-img" alt="Order Confirmation" />
        </div>
              <p className="mb-2 text-center">Thank you for your order! Your order details are:</p>
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Order ID:
                  <span className="badge bg-secondary rounded-pill">{orderDetails.orderid}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Total Amount:
                  <span className="badge bg-danger rounded-pill">₹ {orderDetails.getorder.total}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Order Status:
                  <span className="badge bg-success rounded-pill"> pending approval</span>
                </li>
                {/* Add more order details */}
              </ul>
              <div className="text-center my-4">
              <Link to={'/'} className="fw-bold fs-4 btn more_menu_button btn-animate text-white">Back To Home</Link>
              </div>
            </div>
          ) : (
            <p>No order details found.</p>
          )}
        </div>
      </div>
    </div>
  </div>
</div>





      ) : (
        <p>No order details found.</p>
      )}
      
    </div>
  );
};

export default OrderConfirmationPage;