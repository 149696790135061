import React, { createContext, useState, useEffect } from 'react'

import Axios from 'axios';
import { base_url,image_url } from '../config';
import { Modal, Card, Col, Row, Button } from "react-bootstrap";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
export const SettingData = createContext()



const StateProvider = ({ children , cartItems, setCartItems,Loader }) => {
    const [setting, setsetting] = useState([]);
    const [fatchsetting, setfatchsetting] = useState(false);
    const [testimonial, settestimonial] = useState([]);
    const [main_testimonial, setmain_testimonial] = useState({});
    const [featuredine, setfeaturedine] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [restaurants, setRestaurants] = useState([]);
    const [restaurant, setRestaurant] = useState(null);
    const [show, setShow] = useState(false);
    const [selectedRestaurant, setSelectedRestaurant] = useState('');
    const [loading, setLoading] = useState(true);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        setLoading(true);
        Axios.get(base_url + 'api/home').then((response) => {
            setsetting(response.data.setting);
            setfatchsetting(true)
            settestimonial([...response.data.testimonial]);
            setmain_testimonial(response.data.main_testimonial);
            setfeaturedine([...response.data.dine]);
            setLoading(false);

        });
    }, [])
    useEffect(() => {
        setLoading(true);
        const timer = setTimeout(() => setLoading(false), 1000);
        return () => clearTimeout(timer);
      }, [location]);
    useEffect(() => {
        if (setting.order_type_home_delivery == 0 && setting.order_type_take_away == 0 && setting.order_type_dine == 0) {
            setIsButtonDisabled(true);
        }
      }, [setting]);









      
    // new model
    const handleClose = () => setShow(false);
    const handleShowMainModel = () => setShow(true);
    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    useEffect(() => {
        // Retrieve restaurant data from localStorage
        const storedRestaurant = JSON.parse(localStorage.getItem('restaurant'));
        if (storedRestaurant) {
           
            setRestaurant(storedRestaurant);
        } else {

            navigate('/');
            handleShowMainModel()
        }

    }, []);

 
    // useEffect(() => {
    //   if(restaurant==null){
    //     handleShowMainModel()
    //   }
    // }, []);
    useEffect(() => {
        // Fetch cities when component mounts
        fetchCities();
    }, []);

    const fetchCities = () => {
        Axios.get(base_url + 'api/get_active_cities')
            .then(response => {
                setCities(response.data.cities);
            })
            .catch(error => {
                console.error('Error fetching cities:', error);
            });
    };

    const fetchRestaurants = (city) => {
        Axios.get(base_url + `api/get_restro_based_on_city/${city}`)
            .then(response => {
                localStorage.removeItem('cart');
                setCartItems([]);
                setRestaurants(response.data.restros);
            })
            .catch(error => {
                console.error('Error fetching restaurants:', error);
            });
    };
    const handleCityChange = (event) => {
        const city = event.target.value;
        setSelectedCity(city);
        // Fetch restaurants for the selected city
        fetchRestaurants(city);
    };

    const handleRestaurantChange = (restaurant) => {
        setSelectedRestaurant(restaurant);
      };

      const handleSubmit = (event) => {
        event.preventDefault();
        if (selectedCity && selectedRestaurant && selectedOption) {
          console.log('Selected city:', selectedCity);
          console.log('Selected restaurant:', selectedRestaurant);
          console.log('Selected order type:', selectedOption);
          // You can set the selected restaurant and order type here
          // Example:
          // setOrderDetails({ city: selectedCity, restaurant: selectedRestaurant, orderType: selectedOrderType });
        if(selectedOption=='H'){
            localStorage.removeItem('restaurant');
                localStorage.setItem('order_type', 'H');
                navigate('/home-delivery');
        }else{
            setRestaurant(selectedRestaurant);
            localStorage.setItem('restaurant', JSON.stringify(selectedRestaurant));
            localStorage.setItem('order_type', selectedOption);
            navigate('/menu');
        }

          handleClose();
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'Please select a city, restaurant, and order type.',
                icon: 'error',
                confirmButtonText: 'OK'
              });
        }
      };

    return (<>

        <div>
            {/* model start */}
            {/* <Button variant="primary" onClick={handleShowMainModel}>
                Launch modal
            </Button> */}
            {/* {fatchsetting==true?   */}
            <Modal show={show} onHide={handleClose} centered className="custom-modal" backdrop="static">
                {/* <Modal.Header closeButton>
                    <Modal.Title>Modal Title</Modal.Title>
                </Modal.Header> */}
                <Modal.Body className='border-black-1 pb-5'>
               <div className='text-center'> 
               <img src={image_url+setting.menu_logo} alt="" style={{ width: "100px", marginTop:'50px' }} />
               </div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <div className='text-center my-2'>
                            <label >Select order type</label>
                            </div>
                            <div className='row'>


                                {setting.order_type_dine == 1 && (
                                    <div className='col'>
                                        <button
                                            className={` single-line-text btn btn-light  w-100 popup-button ${selectedOption === 'D' ? 'active1' : ''}`}
                                            onClick={() => handleOptionChange('D')}
                                            type="button"
                                        >
                                            Dine-In
                                        </button>
                                    </div>
                                )}
                           
                                {setting.order_type_take_away == 1 && (
                                    <div className='col'>
                                        <button
                                            className={` single-line-text btn btn-light  w-100 popup-button ${selectedOption === 'P' ? 'active1' : ''}`}
                                            onClick={() => handleOptionChange('P')}
                                            type="button"
                                        >
                                            Pickup
                                        </button>
                                    </div>
                                )}
                                     {setting.order_type_home_delivery == 1 && (
                                    <div className='col'>
                                        <button
                                            className={` single-line-text btn btn-light  w-100 popup-button ${selectedOption === 'H' ? 'active1' : ''}`}
                                            onClick={() => handleOptionChange('H')}
                                            type="button"
                                        >
                                            Home Delivery
                                        </button>
                                    </div>
                                )}
                        {setting.order_type_home_delivery == 0 &&
                                    setting.order_type_take_away == 0 &&
                                    setting.order_type_dine == 0
                                    ?
                                    <p className="text-danger fw-bold text-center">We are not accepting orders at the moment.</p> :
                                    ''}

                            </div>
                        </div>
                        <div controlId="citySelect" className='form-group'>
                        <div className='text-center my-2'>
                        <label>Select the city</label>
                            </div>
                            
                            <select className='form-control select-opecity' onChange={handleCityChange} value={selectedCity}>
                                <option value="">Select City</option>
                                {cities.map(city => (
                                    <option key={city.id} value={city.id}>{city.name}</option>
                                ))}
                            </select>
                        </div>
                        {selectedCity && (
                            <div className='form-group' controlId="restaurantSelect">
                                 <div className='text-center my-2'>
                                <label>Select the outlet</label>
                                </div>
                                <div className='row'>
                                    {restaurants.map(restaurant => (
                                        <div className='col'>
                                            <button
                                                key={restaurant.restaurantid}
                                                className={`btn btn-light  w-100 popup-button ${selectedRestaurant.restaurantid == restaurant.restaurantid ? 'active1' : ''}`}
                                                type="button"
                                                onClick={() => handleRestaurantChange(restaurant)}
                                               
                                                
                                            >
                                                {restaurant.restaurantname}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className='text-center'>
                        <button type="submit" className="fw-bold  big-button text-white mt-4 col-4" disabled={isButtonDisabled}>Order Now</button>
                        </div>
                     
                    </form>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
            </Modal>
            {/*  :''} */}
            {/* model end*/}
            <SettingData.Provider value={{ setting, testimonial, main_testimonial, featuredine,handleShowMainModel,restaurant, setRestaurant }}>
            {loading && <Loader />}
                {children}

            </SettingData.Provider>


        </div>

    </>)
}

export default StateProvider