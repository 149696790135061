import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from "./../images/logo.png";
import ilssidelogo from "./../images/ilssidelogo.png";
import bannerimg from "./../images/banner.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeaderMenu from "../component/HeaderMenu";
import { base_url, image_url } from '../config';
import { Link } from 'react-router-dom';
import { SettingData } from '../provider/StateProvider';
import axios from 'axios';

import Carousel from 'react-bootstrap/Carousel';

function FoodHeader({featuredmenu}) {
  const { setting } = useContext(SettingData);
  const [banner, setbanner] = useState([]);
  useEffect(() => {
    fetchBanner();
  }, []);


  const fetchBanner = async () => {
    try {
      const response = await axios.get(base_url + 'api/banner');
      setbanner([...response.data.banner]);


    } catch (error) {
      console.error('Error fetching data:', error);

    }
  };


  return (
    <>
    

      {/* marge hero and side menu for desktop */}
      <Row className="mx-0">
        <Col sm={12} lg={3} className="bg-danger pb-4 d-none d-lg-block">
          <HeaderMenu />
        </Col>


        <Col sm={12} lg={9} className="px-0 position-relative">
          <Navbar bg="dark" data-bs-theme="dark" expand="lg" className='py-0'>
            <Container fluid>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Brand href="#home" className="d-lg-none m-0">
                <img
                  src={image_url + setting.main_logo}
                  alt="Logo"
                  style={{ width: "100px" }}
                />
              </Navbar.Brand>
              <Navbar.Brand href="#home" className="d-lg-none m-0">
                <img
                  src={image_url + setting.menu_logo}
                  alt="Logo"
                  style={{ width: "50px" }}
                />
              </Navbar.Brand>
            </Container>
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-left"
            >
              <Nav className="me-auto d-lg-none">
                {/* <Nav.Link href="#home">Home</Nav.Link> */}
                <HeaderMenu />
                
              </Nav>
              {/* <Nav className="me-auto ">
               
                
                <Nav.Link href="#action1">Home</Nav.Link>
              </Nav> */}
              {/* <Nav className='d-none d-lg-block'>
            <img src={logo} alt="Logo" style={{ marginRight: '15px', width:'400px' }} />
          </Nav> */}
            </Navbar.Collapse>
            <Navbar.Brand href="#home" className="d-none d-lg-block">
              <img src={image_url + setting.main_logo} alt="Logo" style={{ width: "450px" }} />
            </Navbar.Brand>
            {/* </Container> */}
          </Navbar>

            {/* <div className="food-menu">
                <table>
                    <tbody>
<tr>


                   
               {
                 featuredmenu.map((data,index)=>{
                    return (
                       
                        <td><Link className="nav-link py-2 px-3" style={{whiteSpace:'nowrap'}} to={{ pathname: '/order-now/'}}> <span dangerouslySetInnerHTML={{ __html: data.icon }}></span> {data.name}</Link></td>
                
                 )})
               }
               </tr>
                 </tbody>
                </table>
              </div> */}
          {/* <Slider {...settings}>
          <img src={bannerimg} alt="" className="w-100 banner" />
          
          </Slider> */}
          {/* <img src={bannerimg} alt="" className="w-100 banner" /> */}

          <div className="top-social d-none d-lg-block">
          {setting.facebook_link!=null? 
            <a className="px-2" href={setting.facebook_link} target='blank'>
                    <i class="fa-brands fa-facebook text-danger"></i>
                    </a>:''}
                   
          
                   {setting.instagram_link!=null? 
            <a className="px-2" href={setting.instagram_link} target='blank'>
                    <i class="fa-brands fa-square-instagram text-danger"></i>
                    </a>:''}
                   
           
          </div>
          <Carousel indicators={false}>
            {banner.map((data, index) => {
              return (

                <Carousel.Item interval={1000} className="banner">
                  <img src={image_url + data.banner} alt="" className="w-100 banner" />


                </Carousel.Item>

              )
            })}

          </Carousel>

        </Col>
      </Row>
    </>
  );
}

export default FoodHeader;
