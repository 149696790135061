
import React,{useEffect,useState,useContext} from "react";
import { useParams } from "react-router";
import Row from "react-bootstrap/Row";
import { Link,useLocation } from 'react-router-dom';
import Col from "react-bootstrap/Col";
import { Container,Button } from "react-bootstrap";
import pc1 from "./../images/pc1.png";
import pc2 from "./../images/pc2.png";
import pc3 from "./../images/pc3.png";
import pc4 from "./../images/pc4.png";
import pc5 from "./../images/pc5.png";
import pc6 from "./../images/pc6.png";
import directions from "./../images/directions.png";
import headingbg from "./../images/heading-bg.png";
import {base_url,image_url} from '../config';
import { SettingData } from '../provider/StateProvider';
import Card from 'react-bootstrap/Card';
import Footer from '../includes/Footer';
import FixedHeader from "../includes/FixedHeader";
import send from "./../images/send.png";

import axios from 'axios';
import News from "./News";

const OurLocation = ({featuredmenu}) => {
     const location = useLocation(); 
    const [allrestro, setallrestro] = useState([]);
    const [banner, setbanner] = useState([]);
   
  
    // const { state } = useLocation();


    useEffect(() => {
       
     
        allrestrofatch();
      
        


      }, []);


      const allrestrofatch = async () => {
        try {
         
         
           
        
          const response = await axios.get(base_url + 'api/allrestro');
          const response1 = await axios.get(base_url + 'api/allrestrob');
       
          
          setallrestro([...response.data.allrestro]);
          
        //  console.log(response.data.allrestro)
    
         const arrayOfObjects = response1.data.allrestrob.map((value, index) => ({
            banner: value
          }));
          
          setbanner(arrayOfObjects)
          setTimeout(()=>{
            console.log(arrayOfObjects)
          },3000)
    
        } catch (error) {
          console.error('Error fetching data:', error);
    
        }
      };

    
  return (<>
  <FixedHeader featuredmenu={featuredmenu} banner={banner}/>
   <Row className=" mx-0">
   <Col sm={12} lg={10} className="offset-lg-2 px-0">
   <Container>
   <div className='pt-4'>
      <div className="w-c mb-4">
      {location.pathname=="/order-now"?
      <h2 className='text-center display-5 fw-bold m-0'>Choose Outlet</h2>
      :
      <h2 className='text-center display-5 fw-bold m-0'>DINE WITH US</h2>
      }
      <div class="eight">
       <h1 className="text-danger fw-bold">Timings 11am-10pm</h1>
      </div>
      </div>
        {/* <p className='text-center fw-bold timing text-danger'>OPENING HOURS : 11AM TO 10PM</p> */}
      
      {/* <DineSlider featuredine={featuredine}/> */}
      <Container>
      <Row>
      {
                  allrestro.map((data,index)=>{
                      return (<>
                  <Col xs={12} sm={12} md={6} lg={3}>
                      <Card className='border-0'>
                          <Link to={'/restaurents/'+data.slug}>
                          
                        <Card.Img variant="top" src={image_url+data.thumbnail} className='rounded-4 card-img-restro' />
                        </Link>
                        <Card.Body>
                        <Link to={'/restaurents/'+data.slug} className="nav-link">
                          <Card.Title className='fw-bold  mb-0'>
                            {/* <i class="fa-solid fa-location-dot text-danger"></i> */}
                             {data.name}</Card.Title>
                          </Link>
                          <Card.Text className='p-0 m-0 height-97'>
                          {data.address}
                          </Card.Text>
                          {data.is_comming_soon==0?
                          <Card.Title className=' fw-bold'>
                              <h6><a className="nav-link text-danger" target="_blank" href={data.map_link}><img src={directions} style={{width:'20px'}}/> Get Directions</a></h6>
                          </Card.Title>
                          :<Card.Title className='fw-bold'>
                          <h6>Celebrating Our Restaurant Soon!</h6>
                          </Card.Title>}
          
                        </Card.Body>
                      </Card>
                    </Col >
                      </>
                      )
                  })
              }
       
    

      </Row>
    </Container>
  </div>
   
  </Container>
  
  <Footer/>
   </Col>

   </Row>
 
  </> 
  )
}

export default OurLocation
