
import React,{useEffect,useState,useContext} from "react";
import { useParams } from "react-router";
import Row from "react-bootstrap/Row";
import { Link,useLocation } from 'react-router-dom';
import Col from "react-bootstrap/Col";
import { Container,Button } from "react-bootstrap";
import bread_cleanup from "./../images/bread_cleanup.png";
import headingbg from "./../images/heading-bg.png";
import reviewbanner from "./../images/reviewbanner.png";
import {base_url,image_url} from '../config';
import { SettingData } from '../provider/StateProvider';
import Card from 'react-bootstrap/Card';
import Footer from '../includes/Footer';
import FixedHeader from "../includes/FixedHeader";
import Slider from "react-slick";
import axios from 'axios';
import News from "./News";
import Testimonial from "./Testimonial";
import directions from "./../images/directions.png";
const Review = ({featuredmenu}) => {
    const {testimonial,main_testimonial} = useContext(SettingData);
    const [product, setproduct] = useState('');



    const [allrestro, setallrestro] = useState([]);
    const [banner, setbanner] = useState([]);
   
  
    // const { state } = useLocation();


    useEffect(() => {
       
     
        allrestrofatch();
      
        


      }, []);


      const allrestrofatch = async () => {
        try {
         
         
           
        
          const response = await axios.get(base_url + 'api/allrestro');
          const response1 = await axios.get(base_url + 'api/allrestrob');
       
          
          setallrestro([...response.data.allrestro]);
          
        //  console.log(response.data.allrestro)
    
         const arrayOfObjects = response1.data.allrestrob.map((value, index) => ({
            banner: value
          }));
          
          setbanner(arrayOfObjects)
          setTimeout(()=>{
            console.log(arrayOfObjects)
          },3000)
    
        } catch (error) {
          console.error('Error fetching data:', error);
    
        }
      };
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        rows: 2,
        
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            },
          },
        ],
      };
   
    // const { state } = useLocation();


    // useEffect(() => {
       
     
    //     singlecat();
      
        


    //   }, []);



    //   const singlecat = async () => {
    //     try {
         
         
        
    //       const response = await axios.get(base_url + 'api/category-products/'+singleresponse.data.singlecategory.id);
       
          
    //       setproduct([...response.data.categoryproduct]);
         
    
    //     } catch (error) {
    //       console.error('Error fetching data:', error);
    
    //     }
    //   };

    
  return (<>
  <FixedHeader featuredmenu={featuredmenu} banner={product}/>
   <Row className="mx-0">
   <Col sm={12} lg={10} className="offset-lg-2 px-0">
    {/* <img src={reviewbanner} alt="" /> */}
    <img src={reviewbanner} alt="" className="w-100 banner" />
 
  <div className='pt-4 pb-5'>
    <Container>
        <h2 className='text-center fw-bold display-5'>HAPPY CUSTOMERS!</h2>
        <Row className="mx-0">
          
      
         
            {/* <Col sm={12} lg={6}>
              <div className=' text-center position-relative left-testimonial'>
                <img src={bread_cleanup} alt="" className='w-sm-100'/>


                <div className='left-testimonial-content'>
                <div className='mb-1'>
                  <img src={image_url+main_testimonial.profile_image} style={{width:'70px'}} alt="" />
                </div>
                <div className='p-1 mw-210'>
                  <div className='card content-fit m-auto bg-left-star mb-2'>
                      <div className="card-body py-1 px-2">
                      {Array.from({ length: main_testimonial.star }, (_, index) => (
                    
                      <i key={index} class="fa-solid fa-star star-color mx-1"></i>
                    ))}
                      {Array.from({ length: 5-main_testimonial.star }, (_, index) => (
                    
                      <i key={index} class="fa-solid fa-star text-secondary mx-1"></i>
                    ))}


                     
                     
                     
                      </div>
                  </div>
                <p className='m-0 p-0 text-black'>{main_testimonial.message}</p>
                </div>
                </div>
              </div>
            </Col > */}
            <Col sm={12} lg={12}>
            <Slider {...settings}>
            {testimonial.map(( data, index)=>{
            
            return(<>
            <div className={ index%2==0?'message-box mb-5 mx-1': 'message-box-2 mb-5 mx-1'}>
            <div className="message-content d-flex align-items-center ">
                <div>
                  <img src={image_url+data.profile_image} style={{width:'60px'}} alt="" />
                </div>
                <div className='p-1'>
                  <div className='card content-fit'>
                      <div className="card-body py-1 px-2">
                      {Array.from({ length: data.star }, (_, index) => (
                    
                    <i key={index} class="fa-solid fa-star star-color mx-1"></i>
                  ))}
                    {Array.from({ length: 5-data.star }, (_, index) => (
                  
                    <i key={index} class="fa-solid fa-star text-secondary mx-1"></i>
                  ))}
                     
                      </div>
                  </div>
                <p className='m-0 p-0 text-white'>{data.message}</p>
                </div>
            </div>
          </div>
         
          </>)

})}
       
              </Slider>
      
       
            </Col >
        </Row>
    </Container>
  </div>

  <Container>
   <div className='pt-4'>
      <div className="w-c mb-4">
      <h2 className='text-center display-5 fw-bold m-0'>DINE WITH US</h2>
      
      <div class="eight">
       <h1 className="text-danger fw-bold">Timings 11am-10pm</h1>
      </div>
      </div>
        {/* <p className='text-center fw-bold timing text-danger'>OPENING HOURS : 11AM TO 10PM</p> */}
      
      {/* <DineSlider featuredine={featuredine}/> */}
      
      <Row className=" mx-0">
      {
                  allrestro.map((data,index)=>{
                      return (
                        <Col xs={12} sm={12} md={6} lg={3}>
                        <Card className='border-0'>
                            <Link to={'/restaurents/'+data.slug}>
                            
                          <Card.Img variant="top" src={image_url+data.thumbnail} className={`rounded-4 ${data.is_comming_soon == 1 ? 'bw-image' : ''}`} />
                          </Link>
                          <Card.Body>
                          <Link to={'/restaurents/'+data.slug} className="nav-link">
                            <Card.Title className='fw-bold  mb-0'>
                              {/* <i class="fa-solid fa-location-dot text-danger"></i> */}
                               {data.name}</Card.Title>
                            </Link>
                            <Card.Text className='p-0 m-0 height-97'>
                            {data.address}
                            </Card.Text>
                            {data.is_comming_soon==0?
                            <Card.Title className='fw-bold'>
                                <h6><a className="nav-link text-danger" target="_blank" href={data.map_link}><img src={directions} style={{width:'20px'}}/> Get Directions</a></h6>
                            </Card.Title>
                          :<Card.Title className='fw-bold'>
                          <h6>Celebrating Our Restaurant Soon!</h6>
                          </Card.Title>}
            
                          </Card.Body>
                        </Card>
                      </Col >
                         
                      )
                  })
              }
       
    

      </Row>

  </div>
   
  </Container>
  <Footer/>
   </Col>

   </Row>
 
  </> 
  )
}

export default Review
