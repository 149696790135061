import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import { Link } from 'react-router-dom';
import Col from "react-bootstrap/Col";
import { Container, Button } from "react-bootstrap";
import pc1 from "./../images/pc1.png";
import pc2 from "./../images/pc2.png";
import pc3 from "./../images/pc3.png";
import pc4 from "./../images/pc4.png";
import pc5 from "./../images/pc5.png";
import pc6 from "./../images/pc6.png";
import khabar from "./../images/khabar.mp4";
// import round_logo11 from "./../images/round-logo11.png";
import round_logo11 from "./../images/logo-round.png";
import { useLocation } from 'react-router-dom';
import { base_url, image_url } from "../config";
import Card from 'react-bootstrap/Card';
import axios from 'axios';

const News = () => {

  const [news, setnews] = useState([]);
  const [loader, setloader] = useState(false);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scroll behavior
    });
  };


  useEffect(() => {


    newsfatch();




  }, []);



  const newsfatch = async () => {
    try {

      const response = await axios.get(base_url + 'api/news');




      setnews([...response.data.allnews]);
      setloader(true)
      setTimeout(() => {
        console.log(news)
      }, 1000)



    } catch (error) {
      console.error('Error fetching data:', error);

    }
  };
  return (<>


    <div className="text-center my-5">

      {/* <h2 className="curved-text">O U R  N E W S</h2> */}


      <h2 className="display-5 fw-bold color-gray">ILS NEWS</h2>
    </div>

    <Container>
      {loader ? <>
        <Row className="align-items-center">
        
          <Col md={6} className="text-center">
            {/* <h6>{new Date(news[0].created_at).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })}</h6> */}
            <img src={round_logo11} alt="" className="news-card-main mb-5 d-none d-md-inline" style={{maxWidth:'200px'}}/>
            <h4 className="l-1 fs-1 text-center">
            KHABAR PAKKI HAI!
            </h4>
            <p className="color-gray fs-5 text-center">ILS brings the most innovative range of sandwiches and beverages from Indori Chatka to International flavours, enjoy every flavour with ILS-I love Sandwich.</p>
            {/* <Link onClick={handleScrollToTop} to={'/news/' + news[0].slug} className="nav-link text-danger fw-medium btn_read_more">Read More</Link> */}
          </Col>
          <Col md={6} className="text-center">
          
          <video className="w-100 banner-2" autoPlay muted loop>
              <source src={khabar} type="video/mp4"/>
      
                </video>
        
        </Col>
        </Row>

        {/* news */}
        {news.length > 0 && (<>
          <Row>
          <Col md={6} className="">
            <img src={image_url + news[0].thumbnail} alt="" className="w-100 news-card-main" />
          
          </Col>
          <Col md={6}>
            <h6>{new Date(news[0].created_at).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })}</h6>
            <h4 className="l-1">
              {news[0].title}
            </h4>
            <p className="color-gray">{news[0].short_description}</p>
            <Link onClick={handleScrollToTop} to={'/news/' + news[0].slug} className="nav-link text-danger fw-medium btn_read_more">Read More</Link>
          </Col>
        </Row>

        <Row className="mt-4 mb-5 gy-3">

          {
            news.map((data, index) => {
              return (<>
                <Col sm={6} md={6} lg={4} className="relative">

                  <Card className='border-0 hover-card'>


                    <Card.Img variant="top" src={image_url + data.thumbnail} className='w-100 news-card' />

                    <Card.Body className="">
                      <h6>{new Date(data.created_at).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })}</h6>
                      <Card.Title className=' my-2'>
                        <h5 className="color-gray">{data.title}</h5>
                      </Card.Title>
                      <Link onClick={handleScrollToTop} to={'/news/' + data.slug} className="nav-link text-danger fw-medium btn_read_more">Read More</Link>


                    </Card.Body>
                  </Card>


              
                </Col>
              </>)
            }
            )}
        </Row>
        </>
 
        )}
      </> : ''}

    </Container>





  </>

  )
}

export default News
