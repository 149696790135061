
import React,{useEffect,useState,useContext} from "react";
import { useParams } from "react-router";
import Row from "react-bootstrap/Row";
import { Link,useLocation } from 'react-router-dom';
import Col from "react-bootstrap/Col";
import { Container,Button } from "react-bootstrap";
import pc1 from "./../images/pc1.png";
import pc2 from "./../images/pc2.png";
import pc3 from "./../images/pc3.png";
import pc4 from "./../images/pc4.png";
import pc5 from "./../images/pc5.png";
import pc6 from "./../images/pc6.png";
import headingbg from "./../images/heading-bg.png";
import {base_url,image_url} from '../config';
import { SettingData } from '../provider/StateProvider';
import Card from 'react-bootstrap/Card';
import Footer from '../includes/Footer';
import FixedHeader from "../includes/FixedHeader";

import axios from 'axios';
import News from "./News";

const WhatsNew = ({featuredmenu}) => {
    const [news, setnews] = useState([]);
    const [loader, setloader] = useState(false);
  
   


    useEffect(() => {
       
        
        newsfatch();
       
        


      }, []);



      const newsfatch = async () => {
        try {
         
          const response = await axios.get(base_url + 'api/news');
           
       
       
          
          setnews([...response.data.allnews]);
          setloader(true)
        
          
    
    
        } catch (error) {
          console.error('Error fetching data:', error);
    
        }
      };

    
  return (<>
  <FixedHeader featuredmenu={featuredmenu} banner={news.length > 0 ? news : null}/>
   <Row className=" mx-0">
   <Col sm={12} lg={10} className="offset-lg-2 px-0">
   <News/>
  
  <Footer/>
   </Col>

   </Row>
 
  </> 
  )
}

export default WhatsNew
