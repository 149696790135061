
import React, { useEffect, useState, useContext, useCallback } from "react";
import { useParams } from "react-router";
import Row from "react-bootstrap/Row";
import { Link, useLocation } from 'react-router-dom';
import Col from "react-bootstrap/Col";
import { Container, Button } from "react-bootstrap";
import pc1 from "./../images/pc1.png";
import pc2 from "./../images/pc2.png";
import pc3 from "./../images/pc3.png";
import pc4 from "./../images/pc4.png";
import pc5 from "./../images/pc5.png";
import pc6 from "./../images/pc6.png";
import headingbg from "./../images/heading-bg.png";
import { base_url, image_url } from '../config';
import { SettingData } from '../provider/StateProvider';
import Card from 'react-bootstrap/Card';
import Footer from '../includes/Footer';
import FixedHeader from "../includes/FixedHeader";
import { Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useRazorpay from "react-razorpay";

const CheckoutPage = ({ featuredmenu, cartItems, setCartItems }) => {
    const { setting } = useContext(SettingData);
    const [isValid, setIsValid] = useState(true);
    const [map, setMap] = useState(null);
    const [marker, setMarker] = useState(null);
    const [apiKey, setapiKey] = useState(setting.google_map_key);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState({ lat: 0, lng: 0 });
    const navigate = useNavigate();
    const [orderDetails, setOrderDetails] = useState(null);
    const [autocomplete, setAutocomplete] = useState(null);
    const [restaurant, setRestaurant] = useState(null);
    const [tax, setTax] = useState(0);
    const [restaurantStatus, setRestaurantStatus] = useState(true);
    const [total, setTotal] = useState(0);
    const [orderType, setorderType] = useState(null);
    const [display, setDisplay] = useState('none');
    const [paymentID, setpaymentID] = useState('');

    const [Razorpay, isLoaded] = useRazorpay();
    const [formData, setFormData] = useState({
        fullName: '',
        address: '',
        order_type: 'none',
        city: '',
        phone: '',
        email: '',
        state: '',
        zipCode: '',
        country: '',
        paymentOption: '',
        paymentId: '',

    });


    



    // Load restaurant data from localStorage on component mount
    useEffect(() => {
        // Retrieve restaurant data from localStorage
        const storedRestaurant = JSON.parse(localStorage.getItem('restaurant'));

        // Set the restaurant data in the component state

        if (storedRestaurant) {
            setRestaurant(storedRestaurant);
            //    setTimeout(()=>{
            //     console.log(restaurant,'sssssssss')
            //    },3000)
            fetchRestaurantStatus(storedRestaurant);
        } else {
            navigate('/order-now');
        }

    }, []);
    useEffect(() => {

        const order_type = localStorage.getItem('order_type');
        // Set the restaurant data in the component state

        if (order_type == null) {
            // If order type doesn't exist, navigate to the order-now page
            setTimeout(() => {
                navigate('/order-now');
            }, 1000);
        } else {
            setorderType(order_type)
            setFormData({ ...formData, order_type: order_type });
            if (order_type == 'H') {
                // Update another state based on the value of 'order_type'
                setDisplay('block');
            } else {
                setDisplay('none');
            }

        }
    }, []);

    async function fetchRestaurantStatus(storedRestaurant) {
        try {
            const response = await axios.get(`${base_url}api/get_restro_status/${storedRestaurant.restaurantid}`);
            // Response data mil gaya
            if (response.data.restroStatus == 1) {

                setRestaurantStatus(true)
            } else {
                setRestaurantStatus(false)
                navigate('/cart');
            }
            console.log('Restaurant status:', response.data.restroStatus);
        } catch (error) {
            // Error handling
            console.error('Error fetching restaurant status:', error);
        }
    }

    useEffect(() => {
        const storedCartItems = localStorage.getItem('cart');
        if (storedCartItems) {
            setCartItems(JSON.parse(storedCartItems));
            // const totalPrice = cartItems.reduce((acc, item) => acc + (item.price * item.quantity), 0);

            // // Calculate tax amount (2.5%)
            // const taxRate = 0.050;
            // const ttax = parseFloat((totalPrice * taxRate).toFixed(2));
            // setTax(ttax);

            // // Calculate total price with tax
            // setTotal((totalPrice+ttax));
        }
        // if(setting.enable_order_type=='off'){
        if (setting.order_type_home_delivery == 0 && setting.order_type_take_away == 0 && setting.order_type_dine == 0) {
            setIsButtonDisabled(true);
        }
        if (setting.Payment_method_cod == 0 && setting.Payment_method_online == 0) {
            setIsButtonDisabled(true);
        }

    }, []);

    useEffect(() => {

        if (cartItems.length == 0) {
            // Redirect back
            navigate('/');


        }
        const totalPrice = cartItems.reduce((acc, item) => acc + (item.price * item.quantity), 0);

        // Calculate tax amount (2.5%)
        const taxRate = 0.050;
        const ttax = parseFloat((totalPrice * taxRate).toFixed(2));
        setTax(ttax);

        // Calculate total price with tax
        setTotal((totalPrice + ttax));
        // console.log('tottal price', total)
    }, [cartItems]);
    useEffect(() => {
        // Load Google Maps API script dynamically
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
        script.onload = initMap;
        document.body.appendChild(script);
        // navigate('/order-confirmation', { state: { orderDetails } });
        return () => {
            // Cleanup script
            document.body.removeChild(script);
        };
    }, []);


    // const initMap = () => {
    //     const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
    //         center: { lat: 0, lng: 0 },
    //         zoom: 8,
    //     });
    //     setMap(mapInstance);

    //     const markerInstance = new window.google.maps.Marker({
    //         position: { lat: 0, lng: 0 },
    //         map: mapInstance,
    //         draggable: true,
    //     });
    //     setMarker(markerInstance);

    //     const autocompleteInstance = new window.google.maps.places.Autocomplete(
    //         document.getElementById('autocomplete')
    //     );
    //     autocompleteInstance.bindTo('bounds', mapInstance);
    //     autocompleteInstance.setFields(['formatted_address', 'geometry']);

    //     autocompleteInstance.addListener('place_changed', () => {
    //         const place = autocompleteInstance.getPlace();
    //         if (!place.geometry || !place.geometry.location) {
    //             console.log('No location found for input:', place.name);
    //             return;
    //         }
    //         const location = place.geometry.location;
    //         mapInstance.setCenter(location);
    //         markerInstance.setPosition(location);
    //         setSelectedLocation({ lat: location.lat(), lng: location.lng() });
    //     });

    //     setAutocomplete(autocompleteInstance);
    //     // setFormData({ ...formData, address: autocompleteInstance });

    //     // Add event listener for marker dragend event
    //     markerInstance.addListener('dragend', () => {
    //         const newPosition = markerInstance.getPosition();
    //         setSelectedLocation({ lat: newPosition.lat(), lng: newPosition.lng() });
    //     });
    // };
    // setInterval(() => {
    //     console.log(selectedLocation);
    // }, 3000);

    const initMap = () => {
        if (navigator.geolocation && cartItems.length > 0) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const currentLocation = { lat: latitude, lng: longitude };
                    setSelectedLocation({ lat: latitude, lng: longitude });
                    const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
                        center: currentLocation,
                        zoom: 14,
                    });
                    setMap(mapInstance);

                    const markerInstance = new window.google.maps.Marker({
                        position: currentLocation,
                        map: mapInstance,
                        draggable: true,
                    });
                    setMarker(markerInstance);

                    const autocompleteInstance = new window.google.maps.places.Autocomplete(
                        document.getElementById('autocomplete')
                    );
                    autocompleteInstance.bindTo('bounds', mapInstance);
                    autocompleteInstance.setFields(['formatted_address', 'geometry']);

                    autocompleteInstance.addListener('place_changed', () => {
                        const place = autocompleteInstance.getPlace();
                        if (!place.geometry || !place.geometry.location) {
                            console.log('No location found for input:', place.name);
                            return;
                        }
                        const location = place.geometry.location;
                        mapInstance.setCenter(location);
                        markerInstance.setPosition(location);
                        setSelectedLocation({ lat: location.lat(), lng: location.lng() });
                    });

                    setAutocomplete(autocompleteInstance);

                    markerInstance.addListener('dragend', () => {
                        const newPosition = markerInstance.getPosition();
                        setSelectedLocation({ lat: newPosition.lat(), lng: newPosition.lng() });
                    });
                },
                (error) => {
                    console.error('Error getting current location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        // if(name=='phone'){
        //     isNumber(e)
        // }
        if (name == 'order_type' && value == 'H') {
            // Update another state based on the value of 'order_type'
            setDisplay('block');
        }
        if (name == 'order_type' && value == 'D') {
            // Update another state based on the value of 'order_type'
            setDisplay('none');
        }
    };
    // old submit form

    // const handleSubmit = async (event) => {
    //     event.preventDefault();
    //     const isValidNumber = /^[6-9]\d{9}$/.test(formData.phone);
    //     setIsValid(isValidNumber);
    //     if (isValidNumber) {
    //         console.log('Mobile number is valid:', formData.phone);
    //     } else {
    //         alert('Mobile number is invalid:', formData.phone);
    //     }
    //     if (selectedLocation.lat == 0) {
    //         alert('Please add address');
    //     }



    //     if (selectedLocation.lat != 0 && isValidNumber) {
    //         try {
    //             setIsButtonDisabled(true);

    //             const responserestro = await axios.get(`${base_url}api/get_restro_status/${restaurant.restaurantid}`);
    //             // Response data mil gaya
    //             if(responserestro.data.restroStatus==1){





    //             const response = await axios.post(base_url + 'api/get_order_from_website', {
    //                 formData,
    //                 selectedLocation,
    //                 cartItems,
    //                 restaurant,
    //                 total: total,
    //                 tax: tax
    //                 // total: cartItems.reduce((acc, item) => acc + (item.price * item.quantity), 0)
    //             });
    //             console.log('Order placed successfully:', response.data);

    //             const orderDetails = response.data;

    //             // Set order details to state
    //             setOrderDetails(orderDetails);

    //             // Redirect to the order confirmation page with order details in state
    //             if (orderDetails.success == true) {
    //                 navigate('/order-confirmation', { state: { orderDetails } });
    //             } else {
    //                 setIsButtonDisabled(false);
    //             }

    //         }else{
    //             navigate('/cart');
    //         }

    //             // Redirect to the order confirmation page with order details in state
    //             // Optionally, you can redirect the user to a success page
    //             // history.push('/success');
    //         } catch (error) {
    //             console.error('Error placing order:', error);
    //             setIsButtonDisabled(false);
    //             // Handle error
    //         }
    //     }
    // };

    // new form submit 
    // Initialize Razorpay instance

    const handlePayment = async (t) => {

        console.log(formData, 'online')

        const options: RazorpayOptions = {
            key: setting.razorpay_key,
            amount: Math.ceil(t) * 100,
            currency: "INR",
            name: "ILS",
            description: "Order Transaction",
            image: image_url + setting.menu_logo,

            handler: async  (res) => {
                if (res.razorpay_payment_id) {
                    // setFormData({ ...formData, paymentId: res.razorpay_payment_id });
                    // setFormData(prevFormData => ({
                    //     ...prevFormData,
                    //     paymentId: res.razorpay_payment_id
                    // }));
                   
                    console.log(formData, 'online'); // `formData` ab updated hoga
           
                // submitOrder(); 
                try {
                    setIsButtonDisabled(true);
                    // Submit form data with order details to backend
                    //   const response='';
                    const response = await axios.post(base_url + 'api/get_order_from_website', {
                        formData,
                        selectedLocation,
                        cartItems,
                        restaurant,
                        total: total,
                        tax: tax,
                        paymentID:res.razorpay_payment_id
                    });
                    console.log('Order placed successfully:', response.data);
                    const orderDetails = response.data;
                    // Set order details to state
                    setOrderDetails(orderDetails);
                    // Redirect to the order confirmation page with order details in state
                    if (orderDetails.success) {
                        navigate('/order-confirmation', { state: { orderDetails } });
                    } else {
                        setIsButtonDisabled(false);
                    }
                } catch (error) {
                    console.error('Error placing order:', error);
                    setIsButtonDisabled(false);
                    // Handle error
                }
            
                   
                
                    console.log(res, 'success');
                }

            },
            prefill: {
                name: formData.name,
                email: formData.email,
                contact: formData.phone
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#3399cc",
            },
        };

        const rzpay = new Razorpay(options);
        rzpay.on("payment.failed", function (response) {
            // alert(response.error.code);
            alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
        });

        rzpay.open();
        //   }, [Razorpay]);
    };
    // // Handle Razorpay payment

    // // Submit order function
    const submitOrder = async () => {
        try {
            setIsButtonDisabled(true);
            // Submit form data with order details to backend
            //   const response='';
            const response = await axios.post(base_url + 'api/get_order_from_website', {
                formData,
                selectedLocation,
                cartItems,
                restaurant,
                total: total,
                tax: tax,
                paymentID:paymentID
            });
            console.log('Order placed successfully:', response.data);
            const orderDetails = response.data;
            // Set order details to state
            setOrderDetails(orderDetails);
            // Redirect to the order confirmation page with order details in state
            if (orderDetails.success) {
                navigate('/order-confirmation', { state: { orderDetails } });
            } else {
                setIsButtonDisabled(false);
            }
        } catch (error) {
            console.error('Error placing order:', error);
            setIsButtonDisabled(false);
            // Handle error
        }
    };

    // // // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        const totalPrice = cartItems.reduce((acc, item) => acc + (item.price * item.quantity), 0);

        // Calculate tax amount (2.5%)
        const taxRate = 0.050;
        const ttax = parseFloat((totalPrice * taxRate).toFixed(2));
        setTax(ttax);

        // Calculate total price with tax
        setTotal((totalPrice + ttax));

        const isValidNumber = /^[6-9]\d{9}$/.test(formData.phone);
        setIsValid(isValidNumber);
        if (!isValidNumber) {
            // alert('Mobile number is invalid:', formData.phone);
            return;
        }
        if (selectedLocation.lat == 0 && orderType=='H') {
            alert('Please add address');
            return;
        }

        const responserestro = await axios.get(`${base_url}api/get_restro_status/${restaurant.restaurantid}`);
        // Response data mil gaya
        if (responserestro.data.restroStatus == 1) {


        if (formData.paymentOption === 'ONLINE') {
            // Payment mode is online, proceed with Razorpay payment
                handlePayment(total);
        } else {
            // Payment mode is not online, submit order directly
            console.log(formData, 'cod')
            submitOrder();
        }
    }else{
        navigate('/cart'); 
    }
    };
    // not uncoment this use effect
    //   useEffect(() => {
    //     if (isLoaded) {
    //       handlePayment(total);
    //     }
    //   }, [handlePayment]) //isLoaded, 
    // end new form

    //   function isNumber(evt) {
    //     evt = (evt) ? evt : window.event;
    //     var charCode = (evt.which) ? evt.which : evt.keyCode;
    //     if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    //         return false;
    //     }
    //     return true;
    // }
    // setTimeout(() => {
    //     console.log(cartItems.reduce((acc, item) => acc + (item.price * item.quantity), 0))
    // }, 4000);
    return (<>




        <FixedHeader featuredmenu={featuredmenu} />
        <Row className=" mx-0">
            <Col sm={12} lg={10} className="offset-lg-2 px-0">
                {/* <Menu featuredmenu={featuredmenu}/> */}
                {cartItems.length == 0 ? '' :
                    <Row className=" m-0 p-0">
                        {/* menu-order-col */}

                        <Col sm={12} md={12} lg={10} className="m-auto my-2">
                            <Card>
                                <Card.Body>
                                    <h2 class="display-5 fw-bold color-gray text-center my-2">Checkout</h2>
                                    {/* <input id="autocomplete" type="text" placeholder="Enter an address" style={{ width: '100%', marginBottom: '10px' }} /> */}
                                    <div id="map" className="mt-5 mb-2" style={{ width: '100%', height: '400px', display: display }}></div>
                                    {/* <p>Latitude: {selectedLocation.lat}</p>
                        <p>Longitude: {selectedLocation.lng}</p> */}
                                    <form onSubmit={handleSubmit}>
                                        {/* <div className="mb-3">
                                <label htmlFor="address1" className="form-label">Address Line 1</label>
                                <input id="autocomplete" type="text" className="form-control" name="address"  placeholder="Enter an address" />
                            </div> */}
                                        {/* start row of 2 division */}
                                        <Row>
                                            <Col xs={12} lg={7}>
                                                {/* <div id="map" className="mt-5" style={{ width: '100%', height: '400px', display: display }}></div> */}

                                                {/* <Form.Group>
                                                <Form.Label>Order Type</Form.Label>
                                                <div className="order-type-options">
                                                    <InputGroup>
                                                    <InputGroup.Radio
                                                        name="order_type"
                                                        value="H"
                                                        checked={formData.order_type === 'H'}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <Form.Label>Home Delivery</Form.Label>
                                                    </InputGroup>
                                                    <InputGroup>
                                                    <InputGroup.Radio
                                                        name="order_type"
                                                        value="D"
                                                        checked={formData.order_type === 'D'}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <Form.Label>Dine In</Form.Label>
                                                    </InputGroup>
                                                </div>


                                                
                                                </Form.Group> */}
                                                <div>
                                                    <label className="form-label">Order Type</label>
                                                </div>
                                                {/* {setting.enable_order_type=="H" || setting.enable_order_type === "B"?
                                                <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio"
                                                name="order_type"
                                                value="H"
                                                checked={formData.order_type === 'H'}
                                                onChange={handleChange}
                                                required
                                                 />
                                                <label class="form-check-label" for="inlineRadio1">Home Delivery</label>
                                                </div>
                                                :""}
                                                {setting.enable_order_type=="D" || setting.enable_order_type === "B"?
                                                <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio"
                                                 name="order_type"
                                                 value="D"
                                                 checked={formData.order_type === 'D'}
                                                 onChange={handleChange}
                                                 required
                                                 />
                                                <label class="form-check-label" for="inlineRadio2">Dine In</label>
                                                </div>
                                                :""}
                                                {setting.enable_order_type=="off"?
                                                <p className="text-danger">We are not accepting orders at the moment.</p>:''} */}

                                                {setting.order_type_home_delivery == 0 && setting.order_type_take_away == 0 && setting.order_type_dine == 0 ?
                                                    <p className="text-danger">We are not accepting orders at the moment.</p> :
                                                    <>
                                                        <input class="form-check-input" type="hidden"
                                                            name="order_type"
                                                            value={orderType}
                                                        />

                                                        <div className="bg-light p-3 rounded">
                                                            {orderType === 'H' ? <span className="badge bg-success p-2 me-2">Home Delivery</span> :
                                                                orderType === 'P' ? <span className="badge bg-primary p-2 me-2">Take Away</span> :
                                                                    orderType === 'D' ? <span className="badge bg-warning p-2 me-2">Dine-In</span> : ''}
                                                        </div>
                                                    </>


                                                }


                                                <div className="mb-3" style={{ display: display }}>
                                                    <label htmlFor="address" className="form-label">Address</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id=""
                                                        name="address"
                                                        value={formData.address}
                                                        onChange={handleChange}
                                                        required={display == 'block' ? true : false}

                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="fullName" className="form-label">Full Name:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="fullName"
                                                        name="fullName"
                                                        value={formData.fullName}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="email" className="form-label">Email:</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="phone" className="form-label">Mobile No.:</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${!isValid && 'is-invalid'}`}
                                                        id="phone"
                                                        name="phone"
                                                        value={formData.phone}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    {!isValid && <div className="invalid-feedback">Please enter a valid 10-digit mobile number.</div>}
                                                </div>
                                            </Col>
                                            <Col xs={12} lg={5}>

                                                <Card>

                                                    <Card.Body>
                                                        <h3>Order Summary</h3>
                                                        {cartItems.map(item => (



                                                            <Card key={item.id} className="mb-2">
                                                                <Row className="g-0 m-0 p-0">
                                                                    <Col xs={4} md={2} className="m-auto">

                                                                        <Card.Img src={item.item_image_url} alt="Card image" />
                                                                    </Col>

                                                                    <Col xs={8} md={10} className="m-auto">
                                                                        <Card.Body>
                                                                            <Card.Title>{item.name} ,{item.variation_name}</Card.Title>
                                                                            <Card.Text className="p-0 m-0">
                                                                                Qty: {item.quantity}
                                                                            </Card.Text>
                                                                            <p className="p-0 m-0">
                                                                                ₹{item.price * item.quantity}
                                                                            </p>
                                                                            {item.addons.length != 0 ? (
                                                                                <p className="p-0 m-0">  Addons: {item.addons.map(addon => addon.addonitem_name).join(', ')} </p>
                                                                            ) : ''}

                                                                        </Card.Body>
                                                                    </Col>
                                                                </Row>
                                                            </Card>

                                                        ))}

                                                        <div className="card mt-2">

                                                            <div className="card-body">
                                                                <Row>

                                                                    <Col xs={6}>
                                                                        <h5 className="text-danger h6">Total Price: </h5>
                                                                    </Col>
                                                                    <Col xs={6}>
                                                                        <h5 className="text-danger h6">₹{cartItems.reduce((acc, item) => acc + (item.price * item.quantity), 0).toFixed(2)}</h5>
                                                                    </Col>
                                                                    <Col xs={6}>
                                                                        <h5 className="text-danger h6">GST (5%):</h5>
                                                                    </Col>
                                                                    <Col xs={6}>
                                                                        <h5 className="text-danger h6">₹{tax.toFixed(2)}</h5>
                                                                    </Col>
                                                                    <Col xs={6}>
                                                                        <h5 className="text-danger h6">Grand Total:</h5>
                                                                    </Col>
                                                                    <Col xs={6}>
                                                                        <h5 className="text-danger h6">₹{total.toFixed(2)}</h5>
                                                                    </Col>

                                                                </Row>
                                                            </div>
                                                        </div>
                                                        {/* <h4 className="text-danger h6 my-1">Sub Total: ₹ {cartItems.reduce((acc, item) => acc + (item.price * item.quantity), 0)}</h4>
                                                                <h4 className="text-danger h6 my-1">Gst 5 % : ₹ {tax}</h4>
                                                                <h6 className="text-danger h5 my-1">Total: ₹ {total}</h6> */}
                                                    </Card.Body>

                                                </Card>
                                                <div>
                                                    <div>Payment Options</div>
                                                   { setting.Payment_method_cod == 1?
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio"
                                                            name="paymentOption"
                                                            value="COD"
                                                            checked={formData.paymentOption === 'COD'}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                        <label class="form-check-label" for="inlineRadio2">COD</label>
                                                    </div>:''}
                                                    { setting.Payment_method_online == 1?
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio"
                                                            name="paymentOption"
                                                            value="ONLINE"
                                                            checked={formData.paymentOption === 'ONLINE'}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                        <label class="form-check-label" for="inlineRadio2">Pay Now</label>
                                                    </div>
                                                    :''}
                                                       { setting.Payment_method_cod == 0 && setting.Payment_method_online == 0?
                                                        <p className="text-danger">No payment method is available at the moment.</p> :''}
                                                </div>

                                                <button type="submit" className="btn btn-danger my-3" disabled={isButtonDisabled}>Place Order</button>
                                            </Col>
                                        </Row>




                                        {/* <Form.Group>
                                            <Form.Label>Payment Options</Form.Label>
                                            <div>
                                                <InputGroup>
                                                    <InputGroup.Radio
                                                        name="paymentOption"
                                                        value="COD"
                                                        checked={formData.paymentOption === 'COD'}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <Form.Label>COD</Form.Label>
                                                </InputGroup>
                                            </div>




                                            <div>
                                            <InputGroup>
                                                <InputGroup.Radio
                                                    name="paymentOption"
                                                    value="ONLINE"
                                                    checked={formData.paymentOption === 'ONLINE'}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <Form.Label>Pay Now</Form.Label>
                                            </InputGroup>
                                        </div>


                                        </Form.Group> */}



                                    </form>


                                    {/* <Card>

        <Card.Body>
        <h2>Order Summary</h2>
            {cartItems.map(item => (



<Card key={item.id}>
<Row className="g-0 m-0 p-0">
<Col xs={4} md={2} className="m-auto">
 
    <Card.Img src="https://via.placeholder.com/150"  alt="Card image" />
    </Col>

  <Col xs={8} md={10} className="m-auto">
    <Card.Body>
      <Card.Title>{item.name} ,{item.variation_name}</Card.Title>
      <Card.Text className="p-0 m-0">
      Qty: {item.quantity}
      </Card.Text>
      <p>
      ₹{item.price*item.quantity}
      </p>
     
    </Card.Body>
    </Col>
</Row>
</Card>

            ))}
            <h6 className="text-danger">Total Price: ₹ {cartItems.reduce((acc, item) => acc + (item.price * item.quantity), 0)}</h6>
          </Card.Body>
        </Card> */}
                                </Card.Body>
                            </Card>
                        </Col>




                    </Row>
                }



                {/* <button onClick={handlePayment}>Click</button> */}



                {/* <News/> */}
                <div className="margin-bottom"></div>
                <Footer />
            </Col>

        </Row>



    </>

    );
};

export default CheckoutPage;
