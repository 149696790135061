import React,{useContext,useState,useEffect} from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from "./../images/logo.png";
import ilssidelogo from "./../images/ilssidelogo.png";
import banner from "./../images/banner.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {base_url,image_url} from '../config';
import { Link } from 'react-router-dom';
import { SettingData } from '../provider/StateProvider';

function HeaderMenu() {

  const {setting} = useContext(SettingData);
  return (<>
  <Row className="bg-danger mx-0 pb-2">
    <Col className="d-none d-lg-block">
    <div className="text-center my-3 d-none d-xxl-block">
      <Link to={'/'}>
      <img src={image_url+setting.menu_logo} alt="" style={{ width: "180px",background: 'white',
    borderRadius: '50%'}} />
      </Link>
            
          </div>
    </Col>
  <Col xs={6} sm={6} lg={12}>
          
          <div className="text-center my-3 my-xxl-3">
            {/* <Link to={'/order-now'} */}
            <Link to={'/menu'}
              className=" fw-bold  big-button text-white"
              variant=""
              size="lg"
            >
              Order Now
            </Link>
          </div>
          </Col>
          <Col xs={6} sm={6} lg={12}>
          <div className="text-center my-3 my-xxl-3">
            {/* <Link to={'/all-menu'} */}
            <Link to={'/menu'}
              className=" fw-bold big-button text-white"
              variant=""
              size="lg"
            >
              Our Menu
            </Link>
          </div>
          </Col>
          <Col xs={6} sm={6} lg={12}>
          <div className="text-center my-3 my-xxl-3">
            <Link to={'/restaurents'}
              className=" fw-bold big-button text-white "
              variant=""
              size="lg"
            >

              Our Outlets
            </Link>
          </div>
          </Col>
          <Col xs={6} sm={6} lg={12}>
          <div className="text-center my-3 my-xxl-3">
            <Link to={'/whats-new'}
              className=" fw-bold big-button text-white"
              variant=""
              size="lg"
            >
              What’s New
            </Link>
          </div>
          </Col>
          <Col xs={6} sm={6} lg={12}>
          <div className="text-center my-3 my-xxl-3">
            <Link to={'/about-us'}
              className=" fw-bold big-button  text-white "
              variant=""
              size="lg"
            >
              About Us
            </Link>
          </div>
          </Col>
       
        
       
          <Col xs={6} sm={6} lg={12}>
          <div className="text-center my-3 my-xxl-3">
            <Link
              className=" fw-bold big-button text-white"
              variant=""
              size="lg"
              to={'/reviews'}
            >

              Reviews
            </Link>
          </div>
          </Col>
         
  </Row>
  

        </>
  )
};

export default HeaderMenu;
