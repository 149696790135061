import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from "./../images/logo.png";
import ilssidelogo from "./../images/ilssidelogo.png";
import bannerimg from "./../images/banner.png";
import facebook from "./../images/facebook.png";
import instagram from "./../images/instagram.png";
import video from "./../images/home.mp4";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeaderMenu from "../component/HeaderMenu";
import { base_url, image_url } from '../config';
import { Link } from 'react-router-dom';
import { SettingData } from '../provider/StateProvider';
import axios from 'axios';

import Carousel from 'react-bootstrap/Carousel';

function Hero() {
  const { setting } = useContext(SettingData);
  const [banner, setbanner] = useState([]);
  const [bannertype, setbannertype] = useState('');
  useEffect(() => {
    fetchBanner();
  }, []);


  const fetchBanner = async () => {
    try {
      const response = await axios.get(base_url + 'api/banner');
      setbanner([...response.data.banner]);
      setbannertype(response.data.type);


    } catch (error) {
      console.error('Error fetching data:', error);

    }
  };


  return (
    <>
      {/* <Navbar bg="dark" data-bs-theme="dark" expand="lg" >
      <Container>
    
      <Navbar.Toggle />
 
        <Navbar.Collapse className="justify-content-left">
        <Nav className="me-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#link">Link</Nav.Link>
          
          </Nav>
        </Navbar.Collapse>
       
        <Navbar.Brand href="#home">
        <img src={logo} alt="Logo" style={{ marginRight: '15px',width:'200px '}} />
        </Navbar.Brand>
      </Container>
    </Navbar> */}

      {/* <Navbar bg="dark" data-bs-theme="dark" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#home" className="d-lg-none">
            <img
              src={logo}
              alt="Logo"
              style={{ marginRight: "15px", width: "200px" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-left"
          >
            <Nav className="me-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#link">Link</Nav.Link>
            </Nav>
         
          </Navbar.Collapse>
          <Navbar.Brand href="#home" className="d-none d-lg-block">
            <img src={logo} alt="Logo" style={{ width: "450px" }} />
          </Navbar.Brand>
        </Container>
      </Navbar> */}

      {/* marge hero and side menu for desktop */}
      <Row className="mx-0">
        <Col sm={12} lg={2} className="bg-danger pb-4 d-none d-lg-block">
          <HeaderMenu />
        </Col>


        <Col sm={12} lg={10} className="px-0 position-relative banner-col">
          <Navbar bg="dark" data-bs-theme="dark" expand="lg" className='py-0'>
            <Container fluid className="py-mobile-2 bg-black">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              {/* <Navbar.Brand href="/" className="d-lg-none m-0">
              <Link to={'/'}>   <img
                  src={image_url + setting.main_logo}
                  alt="Logo"
                  style={{ width: "100px" }}
                /></Link>
              </Navbar.Brand>
              <Navbar.Brand href="/" className="d-lg-none m-0">
              <Link to={'/'}> <img
                  src={image_url + setting.menu_logo}
                  alt="Logo"
                  style={{ width: "50px" }}
                />
                </Link>
              </Navbar.Brand> */}
             <Navbar.Brand href="/" className="d-lg-none m-0 position-relative">
              <Link to={'/'}> 
              <img className="position-absolute translate-39"
                  src={image_url + setting.menu_logo}
                  alt="Logo"
                  style={{ width: "70px"}}
                />
                </Link>
              </Navbar.Brand>
              <Navbar.Brand className="d-lg-none m-0">
              {setting.facebook_link!=null? 
            <a className="me-2" href={setting.facebook_link} target='blank'>
                    {/* <i class="fa-brands fa-facebook fb text-danger bg-white"></i> */}
                    <img className="fb" style={{width:'1.5rem'}} src={facebook}  alt="" />
                    
                    </a>:''}

          
                   {setting.instagram_link!=null? 
            <a className="" href={setting.instagram_link} target='blank'>
                    {/* <i class="fa-brands fa-square-instagram text-danger"></i> */}
                    <img className="fb" style={{width:'1.5rem'}} src={instagram}  alt="" />
                    </a>:''}
              </Navbar.Brand>
            </Container>
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-left"
            >
              <Nav className="me-auto d-lg-none">
                {/* <Nav.Link href="#home">Home</Nav.Link> */}
                <HeaderMenu />

              </Nav>
              {/* <Nav className='d-none d-lg-block'>
            <img src={logo} alt="Logo" style={{ marginRight: '15px', width:'400px' }} />
          </Nav> */}
            </Navbar.Collapse>
            <Navbar.Brand href="#home" className="d-none d-lg-block">
            <Link to={'/'}>
              <img src={image_url + setting.main_logo} alt="Logo" style={{ width: "450px" }} />
              </Link>
            </Navbar.Brand>
            {/* </Container> */}
          </Navbar>
          {/* <Slider {...settings}>
          <img src={bannerimg} alt="" className="w-100 banner" />
          
          </Slider> */}
          {/* <img src={bannerimg} alt="" className="w-100 banner" /> */}

          <div className="top-social d-none d-lg-block">
          {setting.facebook_link!=null? 
            <a className="" href={setting.facebook_link} target='blank'>
                    {/* <i class="fa-brands fa-facebook fb text-danger bg-white"></i> */}
                    <img className="fb" style={{width:'2rem'}} src={facebook}  alt="" />
                    
                    </a>:''}

          
                   {setting.instagram_link!=null? 
            <a className="" href={setting.instagram_link} target='blank'>
                    {/* <i class="fa-brands fa-square-instagram text-danger"></i> */}
                    <img className="fb" style={{width:'2rem'}} src={instagram}  alt="" />
                    </a>:''}
                   
           
          </div>

          {bannertype=='banner'?
         
         <Carousel indicators={false}>
            {
            banner.map((data, index) => {
              return (

                <Carousel.Item interval={1000}>
                  <img src={image_url + data.banner} alt="" className="w-100 banner-2" />
                </Carousel.Item>

              )
            })}

          </Carousel>
          
          :
           
            banner.map((data, index) => {
              return (


                <video className="w-100 banner-2" autoPlay muted loop>
                <source src={image_url + data.banner} type="video/mp4"/>
        
                  </video>


              )
            })
        
}




{/* <video className="w-100 banner" autoPlay muted loop>
          <source src={video} type="video/mp4"/>
        
          </video> */}
        </Col>
      </Row>
    </>
  );
}

export default Hero;
