import React, { useContext } from 'react'
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from "./../images/logo.png";
import ilssidelogo from "./../images/ilssidelogo.png";
import { Link } from 'react-router-dom';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import facebook from "./../images/facebook.png";
import instagram from "./../images/instagram.png";
import call from "./../images/call.png";
import { base_url, image_url } from '../config';
import { SettingData } from '../provider/StateProvider';
const Footer = () => {
    const { setting } = useContext(SettingData);
    console.log(setting);
    return (<>
        <div className="footer-div py-3 d-xxl-none bg-black">
            {/* <Container > */}
            <div className="container-fluid container-lg">

                {/* <ul className='d-flex footer-li justify-content-center'>
                    <li className='mx-3'>
                        <Link className="nav-link text-white ">ORDER NOW </Link>
                    </li>
                    <li className='mx-3'>
                        <Link className="nav-link text-white ">ABOUT US</Link>
                    </li>

                    <li className='mx-3'><Link className="nav-link text-white ">WHAT'S NEW </Link></li>

                    <li className='mx-3'><Link className="nav-link text-white ">OUR FOOD</Link></li>
                    <li className='mx-3'><Link className="nav-link text-white ">OUR LOCATIONS</Link></li>
                    <li className='mx-3'><Link className="nav-link text-white ">REVIEWS</Link></li>
                </ul> */}
{/* footer-row */}
                <Row className="">
                <Col xs={12} md={4} className="text-center d-md-none">
                        {/* <Link to={'/'}>
                            <img src={image_url + setting.menu_logo} alt="" style={{ width: "120px" }} />
                        </Link> */}
                    </Col>
                    <Col sm={12} className='row m-auto m-link'>
                        <Col>
                        <ul className='d-md-flex footer-li justify-content-center'>
                            <li className='mx-3'>
                                <Link className="nav-link text-white" to={'/menu'}>ORDER NOW </Link>
                            </li>
                            <li className='mx-3'>
                                <Link to={'/about-us'} className="nav-link text-white ">ABOUT US</Link>
                            </li>

                            <li className='mx-3'><Link to={'/whats-new'} className="nav-link text-white ">WHAT'S NEW </Link></li>

                            <li className='mx-3'><Link to={'/menu'} className="nav-link text-white ">OUR FOOD</Link></li>
                     
                        </ul>

                        </Col>
                        <Col className=' d-flex' >
                        <ul className='d-md-flex footer-li justify-content-center'>
                         
                            <li className='mx-3'><Link to={'/restaurents'} className="nav-link text-white ">OUR OUTLETS</Link></li>
                            <li className='mx-3'><Link className="nav-link text-white " to={'/reviews'}>REVIEWS</Link></li>
                            <li className='mx-3'><Link className="nav-link text-white " to={'/track-order'}>TRACK ORDER</Link></li>
                            <li className='mx-3'><Link to={'/contact-us'} className="nav-link text-white">CONTACT US</Link></li>
                        </ul>

                        </Col>
                      
                        {/* <ul className='d-md-flex footer-li justify-content-center'>
                            <li className='mx-3'>
                                <Link className="nav-link text-white ">ORDER NOW </Link>
                            </li>
                            <li className='mx-3'>
                                <Link to={'/about-us'} className="nav-link text-white ">ABOUT US</Link>
                            </li>

                            <li className='mx-3'><Link to={'/whats-new'} className="nav-link text-white ">WHAT'S NEW </Link></li>

                            <li className='mx-3'><Link to={'/all-menu'} className="nav-link text-white ">OUR FOOD</Link></li>
                            <li className='mx-3'><Link to={'/restaurents'} className="nav-link text-white ">OUR LOCATIONS</Link></li>
                            <li className='mx-3'><Link className="nav-link text-white " to={'/reviews'}>REVIEWS</Link></li>
                            <li className='mx-3'><Link className="nav-link text-white " to={'/track-order'}>TRACK ORDER</Link></li>
                        </ul> */}
                    </Col>
                    <Col sm={12} className='row m-auto m-link'>
                        <Col >
                        <ul className='d-md-flex footer-li justify-content-center'>
                            <li className='mx-3'>
                                <Link className="nav-link text-white " to={'/terms-condition'}>TERMS & CONDITIONS</Link>
                            </li>
                            <li className='mx-3'>
                                <Link to={'/privacy-policy'} className="nav-link text-white ">PRIVACY POLICY</Link>
                            </li>
                            <li className='mx-3'><Link to={'/contact-us'} className="nav-link text-white">CONTACT US</Link></li>
                          
                            
                        </ul>

                        </Col>
                        <Col className='d-flex'>
                        <ul className='d-md-flex footer-li justify-content-center'>
                           

                            <li className='mx-3'><Link to={'/return-and-refund-policy'} className="nav-link text-white ">RETURN & REFUND POLICY</Link></li>

                            <li className='mx-3'><Link to={'/shipping-policy'} className="nav-link text-white ">SHIPPING POLICY</Link></li>
                            
                        </ul>

                        </Col>
                    </Col>
                    <Col xs={12} md={4} className="d-flex align-items-center">
                        <div>
                            <p className='text-center'><i className="fa-solid fa-location-dot text-danger"></i> <span className='text-white'>{setting.address}</span> </p>
                            {/* <p className='text-center'> <span className='text-white'><a className='nav-link' href={'tel:' + setting.mobile_no}>
                            
                                <img className="" style={{ width: '2rem' }} src={call} alt="" />
                                {setting.mobile_no}</a></span> </p> */}



                        </div>

                    </Col>
                    <Col xs={12} md={4} className="text-center d-none d-md-block">
                        <Link to={'/'}>
                            <img src={image_url + setting.menu_logo} alt="" style={{ width: "120px" }} />
                        </Link>
                    </Col>
                    <Col xs={12} md={4} className="d-md-flex flex-column align-items-center">
                    <div>
                                 <p className='text-center'> <span className='text-white'><a className='nav-link' href={'tel:' + setting.mobile_no}>
                            
                                <img className="" style={{ width: '2rem' }} src={call} alt="" />
                                {setting.mobile_no}</a></span> </p>
                            </div>
                            <div className='text-center'>
                        {setting.facebook_link != null ?
                            <a className="px-2" href={setting.facebook_link} target='blank'>
                                {/* <i class="fa-brands fa-2x fa-facebook fb text-danger text-danger"></i> */}
                                <img className="fb" style={{ width: '2rem' }} src={facebook} alt="" />

                            </a> : ''}
                        {setting.instagram_link != null ?
                            <a className="px-2" href={setting.instagram_link} target='blank'>
                                {/* <i class="fa-brands fa-2x fa-square-instagram text-danger"></i> */}
                                <img className="fb" style={{ width: '2rem' }} src={instagram} alt="" />
                            </a> : ''}
                        {setting.x_link != null ?
                            <a className="px-2" href={setting.x_link} target='blank'>
                                <i class="fa-brands fa-2x fa-x-twitter text-danger"></i>
                            </a> : ''}
                        {setting.youtube_link != null ?
                            <a className="px-2" href={setting.youtube_link} target='blank'>
                                <i class="fa-brands fa-2x fa-youtube text-danger"></i>
                            </a> : ''}

                            
                            </div>


                    </Col>
                    
                </Row>
            </div>
            {/* </Container> */}
        </div>

        {/* xxl footer  */}
        <div className="footer-div py-3 d-none d-xxl-block bg-black">
            {/* <Container > */}
            <div className="container-fluid container-lg">

                {/* <ul className='d-flex footer-li justify-content-center'>
                    <li className='mx-3'>
                        <Link className="nav-link text-white ">ORDER NOW </Link>
                    </li>
                    <li className='mx-3'>
                        <Link className="nav-link text-white ">ABOUT US</Link>
                    </li>

                    <li className='mx-3'><Link className="nav-link text-white ">WHAT'S NEW </Link></li>

                    <li className='mx-3'><Link className="nav-link text-white ">OUR FOOD</Link></li>
                    <li className='mx-3'><Link className="nav-link text-white ">OUR LOCATIONS</Link></li>
                    <li className='mx-3'><Link className="nav-link text-white ">REVIEWS</Link></li>
                </ul> */}

                <Row className="footer-row">
                    <Col sm={12}>
                        <ul className='d-md-flex footer-li justify-content-center'>
                            <li className='mx-3'>
                                <Link className="nav-link text-white " to={'/menu'}>ORDER NOW </Link>
                            </li>
                            <li className='mx-3'>
                                <Link to={'/about-us'} className="nav-link text-white ">ABOUT US</Link>
                            </li>

                            <li className='mx-3'><Link to={'/whats-new'} className="nav-link text-white ">WHAT'S NEW </Link></li>

                            <li className='mx-3'><Link to={'/menu'} className="nav-link text-white ">OUR FOOD</Link></li>
                            <li className='mx-3'><Link to={'/restaurents'} className="nav-link text-white ">OUR OUTLETS</Link></li>
                            <li className='mx-3'><Link className="nav-link text-white" to={'/reviews'}>REVIEWS</Link></li>
                            <li className='mx-3'><Link className="nav-link text-white" to={'/track-order'}>TRACK ORDER</Link></li>
                        </ul>
                        <ul className='d-md-flex footer-li justify-content-center'>
                            <li className='mx-3'>
                                <Link className="nav-link text-white " to={'/terms-condition'}>TERMS & CONDITIONS</Link>
                            </li>
                            <li className='mx-3'>
                                <Link to={'/privacy-policy'} className="nav-link text-white ">PRIVACY POLICY</Link>
                            </li>

                            <li className='mx-3'><Link to={'/return-and-refund-policy'} className="nav-link text-white">RETURN & REFUND POLICY</Link></li>

                            <li className='mx-3'><Link to={'/shipping-policy'} className="nav-link text-white">SHIPPING POLICY</Link></li>
                            <li className='mx-3'><Link to={'/contact-us'} className="nav-link text-white">CONTACT US</Link></li>
                            
                        </ul>
                    </Col>

                 
                    <Col xs={12} md={4} className="text-center ">
                        <p><i className="fa-solid fa-location-dot text-danger"></i> <span className='text-white'>{setting.address}</span> </p>
                    </Col>
                    <Col xs={12} md={4} className="d-md-flex align-items-center justify-content-center">
                        {setting.facebook_link != null ?
                            <a className="px-2" href={setting.facebook_link} target='blank'>
                                {/* <i class="fa-brands fa-2x fa-facebook fb text-danger text-danger"></i> */}
                                <img className="fb" style={{ width: '2rem' }} src={facebook} alt="" />
                            </a> : ''}
                        {setting.instagram_link != null ?
                            <a className="px-2" href={setting.instagram_link} target='blank'>
                                {/* <i class="fa-brands fa-2x fa-square-instagram text-danger"></i> */}
                                <img className="fb" style={{ width: '2rem' }} src={instagram} alt="" />
                            </a> : ''}
                        {setting.x_link != null ?
                            <a className="px-2" href={setting.x_link} target='blank'>
                                <i class="fa-brands fa-2x fa-x-twitter text-danger"></i>
                            </a> : ''}
                        {setting.youtube_link != null ?
                            <a className="px-2" href={setting.youtube_link} target='blank'>
                                <i class="fa-brands fa-2x fa-youtube text-danger"></i>
                            </a> : ''}





                    </Col>
                    <Col xs={12} md={4} className="d-flex align-items-center justify-content-end">
                        <div>

                            <p> <span className='text-white'><a className='nav-link' href={'tel:' + setting.mobile_no}>
                                {/* <i className="fa-solid fa-phone text-danger"></i> */}
                                <img className="" style={{ width: '2rem' }} src={call} alt="" />
                                {setting.mobile_no}</a></span> </p>
                        </div>

                    </Col>
                </Row>
            </div>
            {/* </Container> */}
        </div>
    </>

    )
}

export default Footer
