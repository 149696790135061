import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from "./../images/logo.png";
import ilssidelogo from "./../images/ilssidelogo.png";
import bannerimg from "./../images/banner.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeaderMenu from "../component/HeaderMenu";
import { base_url, image_url } from '../config';
import { SettingData } from '../provider/StateProvider';
import axios from 'axios';

function Header() {
  const { setting } = useContext(SettingData);
  return (
    <>
      {/* <Navbar bg="dark" data-bs-theme="dark" expand="lg" >
      <Container>
    
      <Navbar.Toggle />
 
        <Navbar.Collapse className="justify-content-left">
        <Nav className="me-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#link">Link</Nav.Link>
          
          </Nav>
        </Navbar.Collapse>
       
        <Navbar.Brand href="#home">
        <img src={logo} alt="Logo" style={{ marginRight: '15px',width:'200px '}} />
        </Navbar.Brand>
      </Container>
    </Navbar> */}

      {/* <Navbar bg="dark" data-bs-theme="dark" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#home" className="d-lg-none">
            <img
              src={logo}
              alt="Logo"
              style={{ marginRight: "15px", width: "200px" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-left"
          >
            <Nav className="me-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#link">Link</Nav.Link>
            </Nav>
         
          </Navbar.Collapse>
          <Navbar.Brand href="#home" className="d-none d-lg-block">
            <img src={logo} alt="Logo" style={{ width: "450px" }} />
          </Navbar.Brand>
        </Container>
      </Navbar> */}

      {/* marge header and side menu for desktop */}
      {/* <Row className="mx-0">
        <Col sm={3} className="bg-danger">
          <div className="text-center my-4">
            <img src={ilssidelogo} alt="" style={{ width: "194px" }} />
          </div>
          <div className="text-center my-4">
            <Button
              className=" fw-bold fs-4 hero_menu_button text-white"
              variant=""
              size="lg"
            >
              Order Now
            </Button>
          </div>
          <div className="text-center my-4">
            <Button
              className=" fw-bold fs-4 hero_menu_button text-white"
              variant=""
              size="lg"
            >
              About Us
            </Button>
          </div>
          <div className="text-center my-4">
            <Button
              className=" fw-bold fs-4 hero_menu_button text-white"
              variant=""
              size="lg"
            >
              What’s New
            </Button>
          </div>
          <div className="text-center my-4">
            <Button
              className=" fw-bold fs-4 hero_menu_button text-white"
              variant=""
              size="lg"
            >
              Our Food
            </Button>
          </div>
          <div className="text-center my-4">
            <Button
              className=" fw-bold fs-4 hero_menu_button text-white"
              variant=""
              size="lg"
            >
              
              Our Locations
            </Button>
          </div>
          <div className="text-center my-4">
            <Button
              className=" fw-bold fs-4 hero_menu_button text-white"
              variant=""
              size="lg"
            >
              
              Reviews
            </Button>
          </div>
        </Col>
        <Col sm={9} className="px-0">
          <Navbar bg="dark" data-bs-theme="dark" expand="lg">
            <Container fluid>
              <Navbar.Brand href="#home" className="d-lg-none">
                <img
                  src={logo}
                  alt="Logo"
                  style={{ marginRight: "15px", width: "200px" }}
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-left"
              >
        
              </Navbar.Collapse>
              <Navbar.Brand href="#home" className="d-none d-lg-block">
                <img src={logo} alt="Logo" style={{ width: "450px" }} />
              </Navbar.Brand>
            </Container>
          </Navbar>
          <img src={banner} alt="" className="w-100" />
        </Col>
      </Row> */}


<Navbar bg="dark" data-bs-theme="dark" expand="lg" className='py-0'>
            <Container fluid>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Brand href="#home" className="d-lg-none m-0">
                <img
                  src={image_url + setting.main_logo}
                  alt="Logo"
                  style={{ width: "100px" }}
                />
              </Navbar.Brand>
              <Navbar.Brand href="#home" className="d-lg-none m-0">
                <img
                  src={image_url + setting.menu_logo}
                  alt="Logo"
                  style={{ width: "50px" }}
                />
              </Navbar.Brand>
            </Container>
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-left"
            >
              <Nav className="me-auto d-lg-none">
                {/* <Nav.Link href="#home">Home</Nav.Link> */}
                <HeaderMenu />

              </Nav>
              {/* <Nav className='d-none d-lg-block'>
            <img src={logo} alt="Logo" style={{ marginRight: '15px', width:'400px' }} />
          </Nav> */}
            </Navbar.Collapse>
            <Navbar.Brand href="#home" className="d-none d-lg-block">
              <img src={image_url + setting.main_logo} alt="Logo" style={{ width: "450px" }} />
            </Navbar.Brand>
            {/* </Container> */}
          </Navbar>
    </>
  );
}

export default Header;
