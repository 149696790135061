

import React,{useEffect,useState,useContext} from "react";
import { useParams } from "react-router";
import Row from "react-bootstrap/Row";
import { Link,useLocation } from 'react-router-dom';
import Col from "react-bootstrap/Col";
import { Container,Button } from "react-bootstrap";
import pc1 from "./../images/pc1.png";
import pc2 from "./../images/pc2.png";
import pc3 from "./../images/pc3.png";
import pc4 from "./../images/pc4.png";
import pc5 from "./../images/pc5.png";
import pc6 from "./../images/pc6.png";
import headingbg from "./../images/heading-bg.png";
import {base_url,image_url} from '../config';
import { SettingData } from '../provider/StateProvider';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import Footer from '../includes/Footer';
import FixedHeader from "../includes/FixedHeader";
const ReturnRefundPolicy = ({ featuredmenu}) => {
 

  return (<>
   <FixedHeader featuredmenu={featuredmenu}/>
   <Row className=" mx-0">
   <Col sm={12} lg={10} className="offset-lg-2 px-0">
   <Container className='pt-4'>
      {/* <h5 className="mt-5">{product.name}</h5>
      <h6>{product.is_veg == 1 ? <span className=" fw-bold text-success">veg</span> : <span className=" fw-bold text-danger">non-veg</span>}</h6>
      <p className="my-5">{product.short_description}</p> */}
{/* <h2><strong>Terms and Conditions</strong></h2> */}
<div className='pt-4'>
      <div className="w-c mb-4">
     
      <h1 className='text-center display-5 fw-bold m-0'>Return and Refund Policy</h1>

      {/* <div class="eight">
       <h1 className="text-danger fw-bold">Timings 11am-10pm</h1>
      </div> */}
      </div>
      </div>
     
     
<p>Last updated: April 30, 2024</p>
<p>Thank you for shopping at I love Sandwich.</p>

<p>The following terms are applicable for any products that You purchased with Us.</p>
{/* <h2 className="text-center">Interpretation and Definitions</h2> */}
<div class="eight">
       <h3 className="text-danger fw-bold">Interpretation</h3>
      </div> 
<p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
<div class="eight">
       <h3 className="text-danger fw-bold">Definitions</h3>
      </div> 
<p>For the purposes of this Return and Refund Policy:</p>
<ul>
<li>
<p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Dreamcorp QSR Pvt Ltd., Office Address: 203, Silver Arch Plaza, New Palasia, Janjeerwala Square, Indore - 452002.</p>
</li>
<li>
<p><strong>Foods</strong> refer to the items offered for sale on the Service.</p>
</li>
<li>
<p><strong>Orders</strong> mean a request by You to purchase Foods from Us.</p>
</li>
<li>
<p><strong>Service</strong> refers to the Website.</p>
</li>
<li>
<p><strong>Website</strong> refers to I love Sandwich, accessible from <a href="https://ilovesandwich.in/" rel="external nofollow noopener" target="_blank">https://ilovesandwich.in/</a></p>
</li>
<li>
<p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
</li>
</ul>


{/* new */}

<div class="eight">
       <h3 className="text-danger fw-bold">Return Policy</h3>
      </div> 
      <p>If your food doesn't meet your expectations, let us know! We'll gladly replace or refund your order if reported within 1 hour of delivery or pickup. Please contact us with your order details and reason for return. We'll work with you to make it right.</p>
<div class="eight">
       <h3 className="text-danger fw-bold">Refund Policy</h3>
      </div> 
    
    <p>We at I love Sandwich are committed to providing you with the best dining experience possible. Due to the nature of our instant delivery service, once an order is prepared and dispatched, we are unable to accept returns or offer refunds unless there is a quality issue or mistake on our part.</p>
    <div class="eight">
       <h3 className="text-danger fw-bold">Quality Issues</h3>
      </div> 
   
    <p>If you encounter any quality issues with your order, such as incorrect items, missing items, or food that does not meet our quality standards, please contact us immediately upon receiving your order. We will investigate the issue and take appropriate action, which may include offering a refund or replacement for the affected items.</p>
    <div class="eight">
       <h3 className="text-danger fw-bold">Order Cancellation</h3>
      </div> 
    
    
    <p>You have the right to cancel your order until the restaurant approves it, provided that the order has not yet been prepared or dispatched. Once an order is prepared or dispatched, it cannot be cancelled, and refunds will not be issued.</p>
   
    <div class="eight">
       <h3 className="text-danger fw-bold">Contact Us</h3>
      </div> 
    <p>If you have any questions or concerns about our return and refund policy, please don't hesitate to contact us at email: support@ilovesandwich.in. Our customer service team is here to assist you and ensure that you have a positive dining experience with us.</p>

    <p><em>Note: Our instant delivery service aims to provide you with fresh and delicious food as quickly as possible. We appreciate your understanding that once an order is prepared and dispatched, we are unable to accept returns or offer refunds except in cases of quality issues or errors on our part.</em></p>
<div className="my-3"></div>
    </Container>
    <Footer/>
   </Col>

   </Row>
  </>
  
  );
}

export default ReturnRefundPolicy;